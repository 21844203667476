import * as Yup from 'yup';

const informationStepSchema = ({ isReseller }: { isReseller: boolean }) => {
  const subscriptionTypeText = isReseller ? 'reseller' : 'customer';

  return Yup.object().shape({
    name: Yup.string().required(
      `Please fill this field with the ${subscriptionTypeText} name`,
    ),
    industry: Yup.string().required(
      `Please fill in this field with the industry in which the ${subscriptionTypeText} will operate`,
    ),
    phone: Yup.string().required(
      `Please fill in this field with ${subscriptionTypeText} phone number`,
    ),
    contractNumber: Yup.string().required('Please enter contract number'),
    accountNumber: Yup.string().required('Please enter account number'),
  });
};

export { informationStepSchema };
