import {
  ComponentProps,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useClickOutside } from 'shared/hooks';
import { cn } from 'shared/lib';
import { Button, CustomTooltip } from 'shared/ui';

import { DropdownMenu } from './ui';

interface ToolTipProps {
  id: string;
  content: string;
}
interface DropdownProps {
  tooltip?: ToolTipProps;
  children: ReactNode;
  buttonContent: ReactNode;
  closeOnOptionClick?: boolean;
  buttonProps?: Omit<ComponentProps<typeof Button>, 'children'>;
  positionX?: 'right' | 'left' | 'center';
  positionY?: 'top' | 'bottom';
  menuClassName?: string;
  isOpen?: boolean;
  onOpen?: (open: boolean) => void;
  alwaysMountedMenu?: boolean;
}

const Dropdown: FC<DropdownProps> = ({
  children,
  closeOnOptionClick,
  buttonContent,
  menuClassName,
  positionX = 'left',
  positionY = 'bottom',
  isOpen: isOpenProp = false,
  onOpen,
  buttonProps = {},
  alwaysMountedMenu,
  tooltip,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenProp);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleIsOpen = () => {
    setIsOpen(open => !open);

    if (onOpen) {
      onOpen(!isOpen);
    }
  };

  const handleClickOutside = () => {
    setIsOpen(false);

    if (onOpen) {
      onOpen(false);
    }
  };

  const handleWrapperClick = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  useClickOutside(dropdownRef, handleClickOutside);

  return (
    <div
      className="relative inline-flex"
      ref={dropdownRef}
      onClick={() => closeOnOptionClick && handleWrapperClick()}
    >
      <Button
        color="secondary"
        {...buttonProps}
        className={cn(
          'font-normal',
          { '[&>svg]:!fill-main !text-main border-main': isOpen },
          buttonProps.className,
        )}
        onClick={toggleIsOpen}
        data-tooltip-id={tooltip?.id}
      >
        {buttonContent}
      </Button>
      {tooltip?.id && (
        <CustomTooltip
          id={tooltip.id}
          content={tooltip.content}
          place="bottom-start"
        />
      )}
      <DropdownMenu
        positionX={positionX}
        positionY={positionY}
        alwaysMountedMenu={alwaysMountedMenu}
        isOpen={isOpen}
        className={menuClassName}
      >
        {children}
      </DropdownMenu>
    </div>
  );
};

export { Dropdown };
