import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../baseQuey';
import { RolesResponse } from './models';
import { OrganizationParams } from '../subscriptions/models';

export const rolesApi = createApi({
  baseQuery,
  reducerPath: 'rolesApi',
  tagTypes: ['roles'],
  endpoints: builder => ({
    getRoles: builder.query<RolesResponse, OrganizationParams>({
      query: ({ id }) => ({
        url: `/api/roles/${id}`,
        method: 'GET',
      }),
      providesTags: ['roles'],
    }),
  }),
});

export const { useGetRolesQuery } = rolesApi;
