import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { RequestsTable, RequestsTableControlsValue } from 'features';
import { AccessRequestType, SortOrder, SortParamsType } from 'shared/ts';

import { getErrorMessage } from 'shared/lib';
import {
  useApproveOrganizationAccessReqMutation,
  useDenyOrganizationAccessReqMutation,
  useGetOrganizationAccessReqQuery,
} from 'shared/api/myOrganization';

const PAGE_SIZE = 15;

const defaultSortParams = {
  sortBy: 'createdAt',
  sortOrder: SortOrder.DESC,
};

interface SubscriptionRequestsProps {
  controlsValue: RequestsTableControlsValue;
}

const MyOrganizationRequests: FC<SubscriptionRequestsProps> = ({
  controlsValue,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [sortParams, setSortParams] =
    useState<SortParamsType>(defaultSortParams);

  // Cache controlsValue to avoid refetching after prop changes
  const [cachedControlsValue, setCachedControlsValue] =
    useState<RequestsTableControlsValue>(controlsValue);

  const { search } = cachedControlsValue;

  const [approveAccessReq] = useApproveOrganizationAccessReqMutation();

  const [denyAccessReq] = useDenyOrganizationAccessReqMutation();

  const { data, isFetching, isLoading } = useGetOrganizationAccessReqQuery({
    ...sortParams,
    pageNumber,
    pageSize: PAGE_SIZE,
    search,
  });

  // Reset the page number and update cachedControlsValue to trigger a refetch with updated page
  useEffect(() => {
    setPageNumber(1);
    setCachedControlsValue(controlsValue);
  }, [search, controlsValue]);

  const requests = data?.data;
  const totalRequestsRecord = data?.totalRecords ?? 0;

  const currentPage = data?.currentPage ?? pageNumber;

  const isLoaderVisible = isFetching || isLoading;

  const handleApproveAccessReq = async (
    accessReqActionParams: any,
    request: AccessRequestType,
  ) => {
    try {
      await approveAccessReq(accessReqActionParams);
      toast.success(`Request for ${request.givenName} has been approved`);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  const handleDenyAccessReq = async (
    accessReqActionParams: any,
    request: AccessRequestType,
  ) => {
    try {
      await denyAccessReq(accessReqActionParams);
      toast.success(`Request for ${request.givenName} has been denied`);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  return (
    <RequestsTable
      requests={requests}
      isLoading={isLoaderVisible}
      onAccessReqApprove={handleApproveAccessReq}
      onAccessReqDeny={handleDenyAccessReq}
      onSort={setSortParams}
      defaultSortParams={defaultSortParams}
      pagination={{
        currentPage,
        pageSize: PAGE_SIZE,
        total: totalRequestsRecord,
        onChange: setPageNumber,
      }}
    />
  );
};

export { MyOrganizationRequests };
