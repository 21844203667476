import { useNavigate } from 'react-router';

import { Button } from 'shared/ui';
import { ReactComponent as CheckIcon } from 'shared/icons/check_icon.svg';
import { routesMap } from 'shared/constants';

const IdpSuccessBlock = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-y-8 text-center max-w-[472px]">
      <p className="text-40 font-semibold">
        IDP Configuration
        <br /> Sent Successfully
      </p>
      <p className="font-inter text-20 leading-[32px]">
        Once your account is ready, your superuser will receive an email
        invitation to join.
      </p>

      <Button
        fullWidth
        color="primary"
        size="lg"
        endIcon={<CheckIcon />}
        onClick={() => navigate(routesMap.home.path)}
      >
        Done
      </Button>
    </div>
  );
};

export { IdpSuccessBlock };
