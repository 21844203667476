import * as Yup from 'yup';

import { regExp } from 'shared/constants';

import { amountOfUsersFormBlockSchema } from '../AmountOfUsersFormBlock/amountOfUsersFormBlockSchema';

const usersStepSchema = Yup.object()
  .shape({
    superUser: Yup.object({
      name: Yup.string().required('First Name is required'),
      surname: Yup.string().required('Second Name is required'),
      email: Yup.string()
        .matches(regExp.email, 'Enter a valid email')
        .required('Email is required'),
    }),
  })
  .concat(amountOfUsersFormBlockSchema);

export { usersStepSchema };
