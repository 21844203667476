import { AccountInfo } from '@azure/msal-common';
import { PermissionType } from 'shared/ts';

const getUserPermissions = (user: AccountInfo): PermissionType[] => {
  const { extension_permissions } = user?.idTokenClaims ?? {};
  const permissions = (extension_permissions as string) || '';
  return (permissions.split(',') as PermissionType[]) ?? [];
};

const hasUserPermissions = (
  user: AccountInfo,
  permission: PermissionType,
): boolean => {
  const userPermissions = getUserPermissions(user);
  return userPermissions.includes(permission);
};

export { hasUserPermissions };
