import { createApi } from '@reduxjs/toolkit/query/react';

import {
  AccessReqActionParams,
  AccessRequestsResponse,
  DeleteInvitationParams,
  DeleteUserParams,
  EditInvitationParams,
  EditUserParams,
  InvitationsResponse,
  InviteUserParams,
  UsersParams,
  UsersResponse,
} from 'shared/ts';

import { baseQuery } from '../baseQuey';

import {
  CustomersParams,
  CustomersResponse,
  CreateSubscriptionParams,
  SubscriptionResponse,
  OrganizationParams,
  CustomerSummaryResponse,
  EditSubscriptionParams,
  SubscriptionBasicParams,
  ResellersResponse,
  ResellersParams,
  ResellerSummaryResponse,
  UpdateSubscriptionNotesParams,
} from './models';

export const subscriptionsApi = createApi({
  baseQuery,
  reducerPath: 'subscriptionsApi',
  tagTypes: [
    'customers',
    'customer',
    'subscriptionUsers',
    'subscriptionInvitations',
    'subscriptionAccessReq',
    'resellers',
    'reseller',
  ],
  endpoints: builder => ({
    getCustomers: builder.query<CustomersResponse, CustomersParams>({
      query: params => ({
        url: '/api/customers',
        method: 'GET',
        params,
      }),
      providesTags: ['customers'],
    }),
    createSubscription: builder.mutation<void, CreateSubscriptionParams>({
      query: body => ({
        url: '/api/customers',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['customers', 'resellers'],
    }),
    getSubscription: builder.query<SubscriptionResponse, OrganizationParams>({
      query: ({ id }) => ({
        url: `/api/customers/${id}`,
        method: 'GET',
      }),
      providesTags: ['customer'],
    }),
    editSubscription: builder.mutation<void, EditSubscriptionParams>({
      query: ({ subscriptionId, ...body }) => ({
        url: `/api/customers/${subscriptionId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['customers', 'customer', 'reseller', 'resellers'],
    }),
    activateSubscription: builder.mutation<void, OrganizationParams>({
      query: ({ id }) => ({
        url: `/api/customers/${id}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: ['customers', 'customer', 'reseller', 'resellers'],
    }),
    inactivateSubscription: builder.mutation<void, OrganizationParams>({
      query: ({ id }) => ({
        url: `/api/customers/${id}/inactivate`,
        method: 'PUT',
      }),
      invalidatesTags: ['customers', 'customer', 'reseller', 'resellers'],
    }),
    deleteSubscription: builder.mutation<void, OrganizationParams>({
      query: ({ id }) => ({
        url: `/api/customers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['customers', 'resellers'],
    }),
    getCustomerSummary: builder.query<
      CustomerSummaryResponse,
      OrganizationParams
    >({
      query: ({ id }) => ({
        url: `/api/customers/${id}/summary`,
        method: 'GET',
      }),
      providesTags: ['customer'],
    }),
    getSubscriptionUsers: builder.query<UsersResponse, UsersParams>({
      query: params => ({
        url: `/api/customers/${params.subscriptionId}/users`,
        method: 'GET',
        params,
      }),
      providesTags: ['subscriptionUsers'],
    }),
    editSubscriptionUser: builder.mutation<void, EditUserParams>({
      query: ({ subscriptionId, userId, ...body }) => ({
        url: `/api/customers/${subscriptionId}/users/${userId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['subscriptionUsers'],
    }),
    deleteSubscriptionUser: builder.mutation<void, DeleteUserParams>({
      query: ({ subscriptionId, userId }) => ({
        url: `/api/customers/${subscriptionId}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['subscriptionUsers', 'customer', 'reseller'],
    }),
    getSubscriptionInvitations: builder.query<
      InvitationsResponse,
      SubscriptionBasicParams
    >({
      query: params => ({
        url: `/api/customers/${params.subscriptionId}/invitations`,
        method: 'GET',
        params,
      }),
      providesTags: ['subscriptionInvitations'],
    }),
    inviteSubscriptionUser: builder.mutation<void, InviteUserParams>({
      query: ({ subscriptionId, ...body }) => ({
        url: `/api/customers/${subscriptionId}/user-invitations`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['subscriptionInvitations', 'customer', 'reseller'],
    }),
    editSubscriptionInvitation: builder.mutation<void, EditInvitationParams>({
      query: ({ subscriptionId, userInvitationId, ...body }) => ({
        url: `/api/customers/${subscriptionId}/user-invitations/${userInvitationId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['subscriptionInvitations'],
    }),
    deleteSubscriptionInvitation: builder.mutation<
      void,
      DeleteInvitationParams
    >({
      query: ({ subscriptionId, userInvitationId, ...body }) => ({
        url: `/api/customers/${subscriptionId}/user-invitations/${userInvitationId}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['subscriptionInvitations', 'customer', 'reseller'],
    }),
    getSubscriptionAccessReq: builder.query<
      AccessRequestsResponse,
      SubscriptionBasicParams
    >({
      query: params => ({
        url: `/api/customers/${params.subscriptionId}/user-access-requests`,
        method: 'GET',
        params,
      }),
      providesTags: ['subscriptionAccessReq'],
    }),
    approveSubscriptionAccessReq: builder.mutation<void, AccessReqActionParams>(
      {
        query: ({ subscriptionId, userId }) => ({
          url: `/api/customers/${subscriptionId}/user-access-requests/${userId}/approve`,
          method: 'PUT',
        }),
        invalidatesTags: [
          'customer',
          'reseller',
          'subscriptionUsers',
          'subscriptionAccessReq',
        ],
      },
    ),
    denySubscriptionAccessReq: builder.mutation<void, AccessReqActionParams>({
      query: ({ subscriptionId, userId }) => ({
        url: `/api/customers/${subscriptionId}/user-access-requests/${userId}/deny`,
        method: 'PUT',
      }),
      invalidatesTags: [
        'customer',
        'reseller',
        'subscriptionUsers',
        'subscriptionAccessReq',
      ],
    }),
    updateSubscriptionNotes: builder.mutation<
      void,
      UpdateSubscriptionNotesParams
    >({
      query: ({ subscriptionId, ...body }) => ({
        url: `/api/customers/${subscriptionId}/note`,
        method: 'PUT',
        body,
      }),
    }),
    getResellers: builder.query<ResellersResponse, ResellersParams>({
      query: params => ({
        url: '/api/resellers',
        method: 'GET',
        params,
      }),
      providesTags: ['resellers'],
    }),
    getResellerSummary: builder.query<
      ResellerSummaryResponse,
      OrganizationParams
    >({
      query: ({ id }) => ({
        url: `/api/resellers/${id}/summary`,
        method: 'GET',
      }),
      providesTags: ['reseller'],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useActivateSubscriptionMutation,
  useCreateSubscriptionMutation,
  useDeleteSubscriptionInvitationMutation,
  useDeleteSubscriptionMutation,
  useDeleteSubscriptionUserMutation,
  useEditSubscriptionInvitationMutation,
  useEditSubscriptionMutation,
  useEditSubscriptionUserMutation,
  useGetCustomerSummaryQuery,
  useGetSubscriptionInvitationsQuery,
  useGetSubscriptionQuery,
  useGetSubscriptionUsersQuery,
  useInactivateSubscriptionMutation,
  useInviteSubscriptionUserMutation,
  useApproveSubscriptionAccessReqMutation,
  useDenySubscriptionAccessReqMutation,
  useGetSubscriptionAccessReqQuery,
  useGetResellersQuery,
  useGetResellerSummaryQuery,
  useUpdateSubscriptionNotesMutation,
} = subscriptionsApi;
