const IdpInfoBlock = () => {
  return (
    <div className="max-w-[542px]">
      <div className="mb-6">
        <p className="text-grey-40 text-12 font-semibold mb-1">
          Configure OpenID Connect IDP
        </p>
        <h1 className="text-black-20 mb-4 font-semibold text-40">OIDC data</h1>

        <div className="flex flex-col gap-y-2 text-grey-30 text-16">
          <p>
            Your organization has requested access to Keesing&apos;s products
            using the Single-Sign-On (SSO) authentication method.
            <br />
            Users belonging to approved domains will be able to sign-in to our
            products.
          </p>

          <p>
            To use the SSO authentication method, you need to create an
            OpenldConnect application{' '}
            <span className="font-semibold">client</span> and{' '}
            <span className="font-semibold">secret</span>, used in the{' '}
            <span className="font-semibold">authorization code flow</span>
          </p>
        </div>
      </div>

      <div className="border-grey-80 border-[1px] rounded-[15px] p-4">
        <p className="text-16 font-semibold text-grey-20 mb-2">
          Application Client Requirements
        </p>

        <ul className="list-inside list-disc text-16 text-grey-20 flex flex-col gap-y-2">
          <li>ID Token Claims: given_name, family name, email</li>
          <li>
            Scopes: <span className="text-grey-50">openid, profile</span>
          </li>
          <li>
            Reply URL:
            <span className="block text-grey-50 break-words pl-[22.4px]">
              https://keesingb2c.b2clogin.com/
              <br />
              keesingb2c.onmicrosoft.com/oauth2/authresp
            </span>
          </li>
        </ul>

        <span className="block mt-[14px] text-grey-40 text-14">
          If you have technical questions please contact: <br />
          development@keesingtechnologies.com
        </span>
      </div>
    </div>
  );
};

export { IdpInfoBlock };
