import { combineReducers } from '@reduxjs/toolkit';

import {
  subscriptionsApi,
  productsApi,
  rolesApi,
  myOrganizationApi,
  idpApi,
} from 'shared/api';

export const rootReducer = combineReducers({
  [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [myOrganizationApi.reducerPath]: myOrganizationApi.reducer,
  [idpApi.reducerPath]: idpApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
