import * as Yup from 'yup';

const servicesStepSchema = ({ isReseller }: { isReseller: boolean }) =>
  Yup.object().shape({
    demo: Yup.boolean(),
    dataStorageRegion: Yup.string().required(
      `Please select a region before finishing the creation`,
    ),
    industry: Yup.string().when([], {
      is: () => isReseller,
      then: schema =>
        schema.required('Please fill in this field with the industry'),
      otherwise: schema => schema.notRequired(),
    }),
    products: Yup.array(),
    features: Yup.array(),
  });

export { servicesStepSchema };
