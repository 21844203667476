import { FC, ReactNode } from 'react';

import { useMatches, useNavigate } from 'react-router';

import { IconButton } from 'shared/ui';
import { ReactComponent as ChevronLeftIcon } from 'shared/icons/chevron_left_icon.svg';
import { cn } from 'shared/lib';

import { HeaderSkeleton } from './HeaderSkeleton';
import { Breadcrumbs } from './Breadcrumbs';

type TRouteHandle = {
  crumb: ReactNode;
};

interface HeaderProps {
  headerActions?: ReactNode;
  isChildRoute?: boolean;
  isHeaderLoading?: boolean;
  isError?: boolean;
}

const Header: FC<HeaderProps> = ({
  headerActions,
  isChildRoute,
  isError,
  isHeaderLoading,
}) => {
  const matches = useMatches();
  const navigate = useNavigate();

  const formattedMatches = matches
    .filter(match => Boolean((match.handle as TRouteHandle | undefined)?.crumb))
    .map(match => ({
      crumb: (match.handle as TRouteHandle).crumb,
      pathname: match.pathname,
    }))
    .reverse();

  const prevRoutePathname = formattedMatches[1]?.pathname ?? '';

  const currentRouteCrumb = formattedMatches[0].crumb;

  return (
    <header
      className={cn('bg-white border-b-[1px] border-grey-80 min-h-[96px]', {
        'min-h-[112px]': isChildRoute,
      })}
    >
      <div className="flex justify-between items-center px-[24px] pt-[32px] pb-[16px]">
        {isHeaderLoading || isError ? (
          <HeaderSkeleton
            matchesLength={formattedMatches.length}
            isChildRoute={isChildRoute}
          />
        ) : (
          <div className="flex gap-x-[4px] items-center flex-1">
            {isChildRoute && (
              <IconButton
                color="secondary"
                className="rounded-full mr-[16px]"
                icon={<ChevronLeftIcon />}
                onClick={() => navigate(prevRoutePathname)}
              />
            )}
            <div className="flex flex-col">
              {isChildRoute && <Breadcrumbs matches={formattedMatches} />}

              <h1 className="text-black text-32 font-semibold h-10">
                {currentRouteCrumb}
              </h1>
            </div>
          </div>
        )}

        {headerActions && headerActions}
      </div>
    </header>
  );
};

export { Header };
