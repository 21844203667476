import { useNavigate } from 'react-router';
import { FormikValues } from 'formik';
import { toast } from 'react-toastify';

import { FormikStep, FormikStepper, PageLayout } from 'features';
import {
  AuthStepForm,
  ServicesStepForm,
  UsersStepForm,
  authStepSchema,
  authInitialValues,
  usersInitialValues,
  usersStepSchema,
  servicesStepSchema,
  servicesInitialValues,
  InformationStepForm,
  informationStepSchema,
  informationInitialValues,
} from 'features/Forms';

import { useCreateSubscriptionMutation } from 'shared/api/subscriptions';

import { getErrorMessage } from 'shared/lib';
import { routesMap } from 'shared/constants';

const initialValues = {
  ...informationInitialValues,
  ...usersInitialValues,
  ...authInitialValues,
  ...servicesInitialValues,
};

const AddNewCustomer = () => {
  const navigate = useNavigate();
  const [createCustomer, result] = useCreateSubscriptionMutation();

  const { isLoading } = result;

  const customerStepSchemas = informationStepSchema({ isReseller: false });

  const handleSubmit = async (values: FormikValues) => {
    try {
      await createCustomer({
        type: 1,
        ...(values as typeof initialValues),
      }).unwrap();

      toast.success('Customer has been created');
      navigate(routesMap.customers.path);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  return (
    <PageLayout className="pt-[24px] flex justify-center">
      <FormikStepper
        className="w-full"
        lastStepNextButtonText="Create Customer"
        initialValues={initialValues}
        isSubmitting={isLoading}
        onSubmit={handleSubmit}
      >
        <FormikStep label="Customer" validationSchema={customerStepSchemas}>
          <InformationStepForm isReseller={false} />
        </FormikStep>
        <FormikStep label="Users" validationSchema={usersStepSchema}>
          <UsersStepForm />
        </FormikStep>
        <FormikStep label="Auth" validationSchema={authStepSchema}>
          <AuthStepForm />
        </FormikStep>
        <FormikStep
          label="Services"
          validationSchema={servicesStepSchema({ isReseller: false })}
        >
          <ServicesStepForm />
        </FormikStep>
      </FormikStepper>
    </PageLayout>
  );
};

export { AddNewCustomer };
