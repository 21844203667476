import { FC, ReactNode } from 'react';
import { useMount } from 'shared/hooks';
import { cn } from 'shared/lib';

interface DropdownMenuProps {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
  positionX: 'right' | 'left' | 'center';
  positionY: 'top' | 'bottom';
  alwaysMountedMenu?: boolean;
}

const DropdownMenu: FC<DropdownMenuProps> = ({
  children,
  isOpen,
  className,
  positionX,
  positionY,
  alwaysMountedMenu,
}) => {
  const mounted = useMount(isOpen);

  const dropdownMenuClass = cn(
    'absolute opacity-0 z-[5] invisible duration-300 p-[12px] shadow-menu rounded-[10px] bg-white',
    {
      'visible opacity-100': isOpen,
    },
    {
      'left-0': positionX === 'right',
      'right-0': positionX === 'left',
      'right-[-100%]': positionX === 'center',
    },
    {
      'top-[100%]': positionY === 'bottom',
      'translate-y-[6px]': isOpen && positionY === 'bottom',
    },
    {
      'bottom-[100%]': positionY === 'top',
      'translate-y-[-6px]': isOpen && positionY === 'top',
    },
    className,
  );

  return (
    <div className={dropdownMenuClass}>
      {(mounted || alwaysMountedMenu) && children}
    </div>
  );
};

export { DropdownMenu };
