import { FC } from 'react';

import { ListItem } from 'features';

import { ReactComponent as UnlockIcon } from 'shared/icons/unlock_icon.svg';
import { ReactComponent as UserRemoveIcon } from 'shared/icons/user_remove_icon.svg';
import { Divider } from 'shared/ui';
import { InvitationType } from 'shared/ts';

interface InvitationsTableActionsProps {
  invitation: InvitationType;
  onDeleteClick: (invitation: InvitationType) => void;
  onEditClick: (invitation: InvitationType) => void;
}

const InvitationsTableActions: FC<InvitationsTableActionsProps> = ({
  invitation,
  onDeleteClick,
  onEditClick,
}) => {
  const handleUserDelete = () => {
    onDeleteClick(invitation);
  };

  const handleInvitationEdit = () => {
    onEditClick(invitation);
  };

  return (
    <div>
      <p className="px-[8px] py-[12px] font-poppins text-13 text-black-40">
        Invitation settings
      </p>
      <div className="w-[248px] flex flex-col">
        <ListItem
          onClick={handleInvitationEdit}
          color="default"
          text="Edit invitation"
          icon={<UnlockIcon />}
        />

        <Divider className="bg-grey-80 mr-[-1px] mb-[8px]" />
        <ListItem
          onClick={handleUserDelete}
          color="destructive"
          text="Delete the invitation"
          icon={<UserRemoveIcon />}
        />
      </div>
    </div>
  );
};

export { InvitationsTableActions };
