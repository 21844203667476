import { FieldSkeleton } from 'shared/ui';

const EditSubscriptionModalSkeleton = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-y-[24px]">
        <FieldSkeleton withHelperText />

        <FieldSkeleton withHelperText />

        <FieldSkeleton withHelperText />

        <FieldSkeleton withHelperText />

        <div className="flex w-full justify-between gap-x-[16px] gap-y-[24px]">
          <FieldSkeleton withHelperText />
          <FieldSkeleton withHelperText />
        </div>
      </div>
    </div>
  );
};

export { EditSubscriptionModalSkeleton };
