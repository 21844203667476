import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from 'shared/ui';
import { cn } from 'shared/lib';

interface SidebarLinkProps {
  to: string;
  className: string;
  icon: ReactNode;
  children: ReactNode;
  isCollapsed: boolean;
  linkClasses?: string;
}

const SidebarLink: FC<SidebarLinkProps> = ({
  to,
  icon,
  children,
  className,
  isCollapsed,
  ...props
}) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => cn(className, { 'bg-main-20': isActive })}
      {...props}
    >
      {icon && <Icon icon={icon} className="flex-shrink-0" size="lg" />}
      {!isCollapsed && <span>{children}</span>}
    </NavLink>
  );
};

export { SidebarLink };
