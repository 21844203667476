import { FC } from 'react';

import { cn } from 'shared/lib';

import { Divider } from 'shared/ui';

interface StepperItemProps {
  step: number;
  index: number;
  initialArray: any[];
  label: string;
}

const StepperItem: FC<StepperItemProps> = ({
  step,
  index,
  initialArray,
  label,
}) => {
  return (
    <>
      <div className="flex items-center">
        <div
          className={cn(
            'flex shrink-0 text-28 font-semibold leading-[40px] items-center justify-center w-[48px] h-[48px] rounded-full bg-main-99 text-black-40 border-[1px] border-main-80',
            {
              'bg-[#ECF9F3] text-success-40 border-success-60': step > index,
            },
            { 'bg-main text-white border-main': step === index },
          )}
        >
          <span>{index + 1}</span>
        </div>
        <p
          className={cn('ml-[8px] text-20 text-black', {
            'text-black-40': step < index,
          })}
        >
          {label}
        </p>
      </div>
      {index + 1 < initialArray.length && (
        <Divider className="bg-main-80 mx-[8px] max-w-[46px] rounded-full" />
      )}
    </>
  );
};

export { StepperItem };
