import { FC } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import {
  ServicesStepForm,
  servicesInitialValues,
  servicesStepSchema,
} from 'features/Forms';

import {
  SubscriptionResponse,
  useEditSubscriptionMutation,
} from 'shared/api/subscriptions';
import { ReactComponent as RefreshIcon } from 'shared/icons/refresh_icon.svg';
import { ReactComponent as CloseIcon } from 'shared/icons/close_icon.svg';
import { ReactComponent as CheckIcon } from 'shared/icons/check_icon.svg';
import { Button, Icon, Modal, Scrollbar } from 'shared/ui';
import { getErrorMessage } from 'shared/lib';

import { EditServicesModalSkeleton } from './ui';

interface EditServicesModalProps {
  isOpen: boolean;
  subscriptionId: string;
  subscription?: SubscriptionResponse;
  onClose: () => void;
  onConfirm: () => void;
  isReseller: boolean;
}

const EditServicesModal: FC<EditServicesModalProps> = ({
  isOpen,
  subscriptionId,
  subscription,
  onClose,
  onConfirm,
  isReseller,
}) => {
  const subscriptionTypeTextCapitalize = isReseller ? 'Reseller' : 'Customer';

  const [editSubscription, result] = useEditSubscriptionMutation();

  const { isLoading } = result;

  const preparedInitialValues = subscription
    ? {
        dataStorageRegion: subscription.dataStorageRegion,
        demo: subscription.demo,
        features: subscription.products.flatMap(product =>
          product.features.map(feature => ({
            productId: product.id,
            featureId: feature,
          })),
        ),
        products: subscription.products.map(product => product.id),
      }
    : servicesInitialValues;

  const handleSubmit = async (values: typeof servicesInitialValues) => {
    try {
      await editSubscription({
        subscriptionId,
        service: values,
      }).unwrap();

      onConfirm();
      toast.success(
        `${subscriptionTypeTextCapitalize} has been successfully updated`,
      );
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  const formik = useFormik<typeof servicesInitialValues>({
    validationSchema: servicesStepSchema({ isReseller: false }),
    initialValues: preparedInitialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={onClose}
      customClassName="h-[90vh] overflow-y-hidden"
      contentClassName="p-0"
    >
      <FormikProvider value={formik}>
        <form
          className="relative h-full w-[614px]"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <div className="flex justify-between p-[24px] pb-[16px] items-center border-b-[1px] border-grey-80">
            <div>
              <p className="text-22 leading-[32px] font-semibold">
                Edit the services
              </p>
              <p className="text-14 leading-[20px] text-black-40">
                Select the services that will be available to this{' '}
                {subscriptionTypeTextCapitalize}
              </p>
            </div>

            <button type="button" onClick={onClose} className="self-start">
              <Icon icon={<CloseIcon />} size="lg" className=" fill-black" />
            </button>
          </div>

          <div className="overflow-hidden h-full">
            <Scrollbar
              height="100%"
              maxHeight="100%"
              viewClassName="pb-[226px] px-[24px] pt-[24px]"
              trackVerticalStyle={{ right: '3px' }}
              trackVerticalClassName="pb-[198px]"
            >
              {subscription ? (
                <ServicesStepForm isModalView />
              ) : (
                <EditServicesModalSkeleton />
              )}
            </Scrollbar>
          </div>

          <div className="sticky border-t-[1px] border-grey-80  p-[24px] bottom-0  bg-white flex items-center gap-x-[16px]">
            <Button
              disabled={isLoading}
              color="tonal"
              fullWidth
              size="lg"
              startIcon={<RefreshIcon />}
              onClick={() => formik.resetForm()}
            >
              Reset changes
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              startIcon={<CheckIcon />}
              color="primary"
              fullWidth
              size="lg"
            >
              Save
            </Button>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export { EditServicesModal };
