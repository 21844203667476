const Permissions = {
  ManageUsers: 'sm:users:manage',
  ManageSubscriptions: 'sm:subscriptions:manage',
  ManageIdpSettings: 'sm:subscriptions:manage_idp_settings',
} as const;

type PermissionType = (typeof Permissions)[keyof typeof Permissions];

export { Permissions };
export type { PermissionType };
