import { FC } from 'react';

import { cn } from 'shared/lib';
import { Button } from 'shared/ui';

import { ReactComponent as ChevronLeftIcon } from 'shared/icons/chevron_left_icon.svg';
import { ReactComponent as ChevronRightIcon } from 'shared/icons/chevron_right_icon.svg';

import { TablePaginationType } from '../table.types';
import { usePagination } from '../hooks';

import { PageDropdown } from './PageDropdown';

const DOTS = '...';

interface PaginationProps {
  pagination: TablePaginationType;
}

const Pagination: FC<PaginationProps> = ({ pagination }) => {
  const { total, currentPage, onChange, pageSize } = pagination;

  const { totalPageCount, paginationRange } = usePagination({
    currentPage,
    pageSize,
    totalCount: total,
  });

  const lastPage = paginationRange[paginationRange.length - 1];

  const handleNext = () => {
    onChange(currentPage + 1);
  };

  const handlePrevious = () => {
    onChange(currentPage - 1);
  };

  if (total <= pageSize) {
    return null;
  }

  return (
    <div className="flex justify-between w-full bg-white px-[16px] py-[12px] border-t-[1px] border-grey-80">
      <ul className="flex items-center gap-x-[12px]">
        {paginationRange?.map(pageNumber => {
          if (pageNumber === DOTS) {
            return (
              <li key={pageNumber} className="pagination-item dots">
                <PageDropdown
                  currentPage={currentPage}
                  onSelect={onChange}
                  totalPages={totalPageCount}
                />
              </li>
            );
          }

          const isCurrentPage = pageNumber === currentPage;

          return (
            <li key={pageNumber}>
              <Button
                className={cn(
                  'text-main-50 hover:bg-main-90 rounded-full p-[10px] w-[44px] h-[44px] text-15 font-semibold',
                  {
                    'bg-main-90 text-main-keesing hover:bg-main-90':
                      isCurrentPage,
                  },
                )}
                onClick={() => onChange(pageNumber)}
              >
                {pageNumber}
              </Button>
            </li>
          );
        })}
      </ul>

      <div className="flex gap-x-[16px]">
        <Button
          color="secondary"
          disabled={currentPage === 1}
          onClick={handlePrevious}
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          color="secondary"
          disabled={currentPage === lastPage}
          onClick={handleNext}
        >
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};

export { Pagination };
