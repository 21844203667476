import { FC, useState } from 'react';

import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import {
  ConfirmModal,
  EditSubscriptionModal,
  EditServicesModal,
  EditAuthAndUsersModal,
  ListItem,
} from 'features';
import {
  ConfirmModalOptionsType,
  Statuses,
  initialConfirmModalOptions,
} from 'shared/ts';
import {
  useActivateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetSubscriptionQuery,
  useInactivateSubscriptionMutation,
} from 'shared/api/subscriptions';
import { Divider, Dropdown } from 'shared/ui';

import { ReactComponent as MoreHorisontalIcon } from 'shared/icons/more_horisontal_icon.svg';
import { ReactComponent as LockIcon } from 'shared/icons/lock_icon.svg';
import { ReactComponent as UnlockIcon } from 'shared/icons/unlock_icon.svg';
import { ReactComponent as EditIcon } from 'shared/icons/edit_icon.svg';
import { ReactComponent as UsersIcon } from 'shared/icons/users_icon.svg';
import { ReactComponent as ToggleIcon } from 'shared/icons/toggle_icon.svg';
import { ReactComponent as UserRemoveIcon } from 'shared/icons/user_remove_icon.svg';
import { getErrorMessage } from 'shared/lib';
import { routesMap } from 'shared/constants';

import {
  EditModalKey,
  EditModalsOpenType,
  SubscriptionType,
} from './subscriptionOptionsDropdown.types';

interface SubscriptionOptionsDropdownProps {
  id: string;
  subscription: SubscriptionType;
  isReseller?: boolean;
}

const SubscriptionOptionsDropdown: FC<SubscriptionOptionsDropdownProps> = ({
  id,
  subscription,
  isReseller = false,
}) => {
  const navigate = useNavigate();

  const [confirmModalOptions, setConfirmModalOptions] =
    useState<ConfirmModalOptionsType>(initialConfirmModalOptions);
  const [editModalsOpen, setEditModalsOpen] = useState<EditModalsOpenType>({
    subscription: false,
    servicesAccess: false,
    authenticationAndUsers: false,
  });

  const [activateSubscription] = useActivateSubscriptionMutation();
  const [inactivateSubscription] = useInactivateSubscriptionMutation();
  const [deleteSubscription] = useDeleteSubscriptionMutation();

  const isSomeEditModalsOpen = Object.values(editModalsOpen).some(
    isModalOpen => isModalOpen,
  );

  const { data: fullSubscriptionData } = useGetSubscriptionQuery(
    { id },
    { skip: !isSomeEditModalsOpen },
  );

  const subscriptionTypeText = isReseller ? 'reseller' : 'customer';
  const subscriptionTypeTextCapitalize = isReseller ? 'Reseller' : 'Customer';

  const isShowDeleteButton =
    subscription.status === Statuses.INACTIVE && !isReseller;

  const toggleEditModal = (key: EditModalKey, isOpen: boolean) => {
    setEditModalsOpen(prevEditModalsOpen => ({
      ...prevEditModalsOpen,
      [key]: isOpen,
    }));
  };

  const handleConfirmModalClose = () => {
    if (confirmModalOptions.onClose) {
      confirmModalOptions.onClose();
    }

    setConfirmModalOptions(prevModalOptions => ({
      ...prevModalOptions,
      isOpen: false,
    }));
  };

  const handleConfirmModalConfirm = () => {
    if (confirmModalOptions.onConfirm) {
      confirmModalOptions.onConfirm();
    }

    setConfirmModalOptions(prevModalOptions => ({
      ...prevModalOptions,
      isOpen: !prevModalOptions.isOpen,
    }));
  };

  const handleSubscriptionInactivate = () => {
    setConfirmModalOptions({
      isOpen: true,
      title: `Are you sure you want to inactivate ${subscriptionTypeText}: ${subscription.name}`,
      subtitle: `After inactivation ${subscriptionTypeText} will lost access to all his available services until you reactivate him`,
      confirmButtonText: 'Inactivate',
      confirmIcon: <LockIcon />,
      onConfirm: async () => {
        try {
          await inactivateSubscription({ id }).unwrap();
          toast.success(
            `${subscriptionTypeTextCapitalize} ${subscription.name} has been inactivated`,
          );
        } catch (error) {
          const errorMessage = getErrorMessage(error);

          toast.error(errorMessage);
        }
      },
    });
  };

  const handleSubscriptionActivate = () => {
    setConfirmModalOptions({
      isOpen: true,
      title: `Activate the ${subscriptionTypeText} ${subscription.name}`,
      subtitle: `Are you sure you want to activate this ${subscriptionTypeText}?`,
      confirmButtonText: 'Activate',
      confirmIcon: <UnlockIcon />,
      confirmButtonProps: { color: 'primary' },
      onConfirm: async () => {
        try {
          await activateSubscription({ id }).unwrap();
          toast.success(
            `${subscriptionTypeTextCapitalize} ${subscription.name} has been activated`,
          );
        } catch (error) {
          const errorMessage = getErrorMessage(error);

          toast.error(errorMessage);
        }
      },
    });
  };

  const handleSubscriptionDelete = () => {
    setConfirmModalOptions({
      isOpen: true,
      title: `Are you sure you want to delete this ${subscriptionTypeTextCapitalize}`,
      subtitle: 'Once deleted, you can’t reactivate this account again?',
      confirmButtonText: `Delete ${subscriptionTypeTextCapitalize}`,
      confirmButtonProps: { className: 'w-fit flex-shrink-0' },
      onConfirm: async () => {
        try {
          await deleteSubscription({ id }).unwrap();
          toast.success(
            `${subscriptionTypeTextCapitalize} ${subscription.name} has been successfully deleted`,
          );
          navigate(routesMap[`${subscriptionTypeText}s`].path);
        } catch (error) {
          const errorMessage = getErrorMessage(error);

          toast.error(errorMessage);
        }
      },
    });
  };

  return (
    <>
      <Dropdown
        buttonContent={<MoreHorisontalIcon />}
        closeOnOptionClick
        tooltip={{
          id: 'subscription-actions',
          content: 'Actions',
        }}
      >
        <div className="w-[248px] flex gap-y-[8px] flex-col">
          {subscription.status === Statuses.ACTIVE && (
            <ListItem
              onClick={handleSubscriptionInactivate}
              color="default"
              text="Inactivate"
              icon={<LockIcon />}
            />
          )}

          {subscription.status === Statuses.INACTIVE && (
            <ListItem
              onClick={handleSubscriptionActivate}
              color="default"
              text="Activate"
              icon={<LockIcon />}
            />
          )}

          <ListItem
            color="default"
            text={`Edit ${subscriptionTypeTextCapitalize}`}
            icon={<EditIcon />}
            onClick={() => toggleEditModal('subscription', true)}
          />
          <ListItem
            color="default"
            text="Services access"
            icon={<ToggleIcon />}
            onClick={() => toggleEditModal('servicesAccess', true)}
          />
          <ListItem
            color="default"
            text="Authentication & Users"
            icon={<UsersIcon />}
            onClick={() => toggleEditModal('authenticationAndUsers', true)}
          />

          {isShowDeleteButton && (
            <>
              <Divider className="bg-grey-80" />
              <ListItem
                color="destructive"
                text={`Delete ${subscriptionTypeTextCapitalize}`}
                icon={<UserRemoveIcon />}
                onClick={handleSubscriptionDelete}
              />
            </>
          )}
        </div>
      </Dropdown>
      <ConfirmModal
        {...confirmModalOptions}
        onClose={handleConfirmModalClose}
        onConfirm={handleConfirmModalConfirm}
      />
      <EditSubscriptionModal
        isOpen={editModalsOpen.subscription}
        subscriptionId={id}
        subscription={fullSubscriptionData}
        onClose={() => toggleEditModal('subscription', false)}
        onConfirm={() => toggleEditModal('subscription', false)}
        isReseller={isReseller}
      />
      <EditServicesModal
        isOpen={editModalsOpen.servicesAccess}
        subscriptionId={id}
        subscription={fullSubscriptionData}
        onClose={() => toggleEditModal('servicesAccess', false)}
        onConfirm={() => toggleEditModal('servicesAccess', false)}
        isReseller={isReseller}
      />
      <EditAuthAndUsersModal
        isOpen={editModalsOpen.authenticationAndUsers}
        subscriptionId={id}
        subscription={fullSubscriptionData}
        onClose={() => toggleEditModal('authenticationAndUsers', false)}
        onConfirm={() => toggleEditModal('authenticationAndUsers', false)}
        isReseller={isReseller}
      />
    </>
  );
};

export { SubscriptionOptionsDropdown };
