import { FC } from 'react';
import { cn } from 'shared/lib';

interface DividerProps {
  className: string;
}

const Divider: FC<DividerProps> = ({ className }) => {
  return <div className={cn('w-full h-[1px]', className)} />;
};

export { Divider };
