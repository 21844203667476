import { FC, ChangeEvent, ComponentProps } from 'react';
import { cn } from 'shared/lib';

import { ReactComponent as RadioInnerCircleIcon } from './ui/radio-inner-icon.svg';
import { ReactComponent as RadioOuterCircleIcon } from './ui/radio-outer-icon.svg';
import { RadioHelperText } from './ui/RadioHelperText';

import './style.css';

interface RadioProps extends ComponentProps<'input'> {
  startLabel?: string;
  endLabel?: string;
  labelClassName?: string;
  fullWidth?: boolean;
  checked?: boolean;
  helperText?: string;
  helperTextClassName?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Radio: FC<RadioProps> = ({
  startLabel,
  endLabel,
  fullWidth,
  helperText,
  helperTextClassName,
  labelClassName,
  checked,
  onChange,
  disabled,
  ...props
}) => {
  return (
    <label
      className={cn(
        'radio-button-container cursor-pointer w-fit text-16',
        { 'w-full': fullWidth, 'cursor-default': disabled },
        labelClassName,
      )}
    >
      {startLabel && (
        <p>
          {startLabel}
          {helperText && (
            <RadioHelperText
              helperText={helperText}
              className={helperTextClassName}
            />
          )}
        </p>
      )}
      <input
        type="radio"
        checked={checked}
        onClick={event => event.stopPropagation()}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <span
        className={cn('relative circle-container', {
          'ml-[15px]': startLabel,
          'mr-[15px]': endLabel,
          'self-start': helperText,
        })}
      >
        <RadioOuterCircleIcon className="outer-circle" />
        <RadioInnerCircleIcon className="inner-circle  absolute top-0 checkmark-checked" />
      </span>
      {endLabel && (
        <p>
          {endLabel}

          {helperText && (
            <RadioHelperText
              helperText={helperText}
              className={helperTextClassName}
            />
          )}
        </p>
      )}
    </label>
  );
};

export { Radio };
