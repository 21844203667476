import { ComponentProps, FC } from 'react';
import { cn } from 'shared/lib';

interface SkeletonProps extends ComponentProps<'div'> {}

const Skeleton: FC<SkeletonProps> = ({ className, ...props }) => {
  return (
    <div
      className={cn('animate-pulse rounded-[100px] bg-grey-80', className)}
      {...props}
    />
  );
};

export { Skeleton };
