type ErrorResponse = {
  data?: {
    message: string;
    statusCode: number;
    errors?: {
      generalErrors?: string[];
    };
  };
  status: number;
};

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

const getErrorMessage = (
  error: any,
  defaultMessage = DEFAULT_ERROR_MESSAGE,
) => {
  const typedError = error as ErrorResponse;

  if (typedError.status === 500) {
    return DEFAULT_ERROR_MESSAGE;
  }

  if (typedError.data) {
    const errorValues = Object.values(typedError.data?.errors ?? []);

    const firstError = Array.isArray(errorValues[0])
      ? errorValues[0][0]
      : errorValues[0];

    return firstError || defaultMessage;
  }

  return defaultMessage;
};

export { getErrorMessage };
