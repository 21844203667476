import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { InviteUserModal } from 'features';
import { Chip, IconButton, Skeleton, CustomTooltip } from 'shared/ui';
import { getErrorMessage } from 'shared/lib';
import { ReactComponent as PlusIcon } from 'shared/icons/plus_icon.svg';
import { OrganizationSummaryResponse } from 'shared/api/myOrganization/models';
import { useInviteOrganizationUserMutation } from 'shared/api/myOrganization';
import { InviteUserType, Statuses } from 'shared/ts';

interface ResellerActionsProps {
  organizationId: string;
  organization?: OrganizationSummaryResponse;
  isLoading: boolean;
}

const MyOrganizationActions: FC<ResellerActionsProps> = ({
  organizationId,
  organization,
  isLoading,
}) => {
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [inviteOrganizationUser] = useInviteOrganizationUserMutation();

  const canInviteUsers =
    organization?.status === Statuses.ACTIVE && !organization?.reachedUserLimit;

  const handleInviteUserModalConfirm = async (invitation: InviteUserType) => {
    setIsInviteUserModalOpen(false);
    try {
      await inviteOrganizationUser({
        ...invitation,
      }).unwrap();

      toast.success(`${invitation.givenName} has been invited`);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  if (isLoading || !organization) {
    return (
      <div className="flex items-center gap-x-[12px]">
        <Skeleton className="w-[96px] h-[44px]" />
        <Skeleton className="w-[48px] h-[48px] rounded-[10px]" />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-[12px]">
      <Chip status={organization.status} isDemo={organization.demo} />

      <IconButton
        icon={<PlusIcon />}
        color="primary"
        disabled={!canInviteUsers}
        data-tooltip-id="inviteUser"
        onClick={() => setIsInviteUserModalOpen(true)}
      />
      <CustomTooltip
        id="inviteUser"
        content="Invite user"
        place="bottom-start"
      />
      <InviteUserModal
        organizationId={organizationId}
        isOpen={isInviteUserModalOpen}
        onClose={() => setIsInviteUserModalOpen(false)}
        onConfirm={handleInviteUserModalConfirm}
      />
    </div>
  );
};

export { MyOrganizationActions };
