import { Skeleton } from 'shared/ui';

const FeaturesBlockSkeleton = () => {
  return (
    <div className="flex flex-col gap-y-[24px]">
      <Skeleton className="h-[276px] w-full rounded-[10px]" />
      <Skeleton className="h-[276px] w-full rounded-[10px]" />
    </div>
  );
};

export { FeaturesBlockSkeleton };
