import { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import {
  Tabs,
  InfoBlock,
  Alert,
  PageLayout,
  InvitationsTableControls,
  InvitationsTableControlsValue,
  UsersTableControls,
  UsersTableControlsValue,
  RequestsTableControls,
  RequestsTableControlsValue,
  SubscriptionStatusInfoBlock,
} from 'features';
import {
  SubscriptionInvitations,
  SubscriptionUsers,
  SubscriptionRequests,
} from 'widgets';

import { useGetCustomerSummaryQuery } from 'shared/api/subscriptions';
import { formatStringToDate, getErrorMessage } from 'shared/lib';
import { Statuses } from 'shared/ts';

import { CustomerActions } from './CustomerActions';

const Customer = () => {
  const params = useParams();
  const customerId = params.customerId ?? '';

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [usersControlsValue, setUsersControlsValue] =
    useState<UsersTableControlsValue>({
      search: '',
      filters: { status: [] },
    });

  const [invitationsControlsValue, setInvitationsControlsValue] =
    useState<InvitationsTableControlsValue>({
      search: '',
    });

  const [requestsControlsValue, setRequestsControlsValue] =
    useState<RequestsTableControlsValue>({
      search: '',
    });

  const {
    data: customer,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetCustomerSummaryQuery(
    {
      id: customerId,
    },
    { refetchOnMountOrArgChange: true },
  );

  const formattedCreatedAt = formatStringToDate(customer?.createdAt);
  const formattedExpiriesAt = formatStringToDate(customer?.expiresAt);
  const isLoadingData = isLoading || isFetching || isError;

  const customerTabContent = [
    {
      title: 'Users',
      component: (
        <SubscriptionUsers id={customerId} controlsValue={usersControlsValue} />
      ),
      amount: customer?.totalOfUsers,
      controls: (
        <UsersTableControls
          controlValues={usersControlsValue}
          onChange={setUsersControlsValue}
          isLoading={isLoadingData}
        />
      ),
    },
    {
      title: 'Invitations',
      component: (
        <SubscriptionInvitations
          id={customerId}
          controlsValue={invitationsControlsValue}
        />
      ),
      amount: customer?.totalOfUserInvitations,
      controls: (
        <InvitationsTableControls
          controlsValue={invitationsControlsValue}
          onChange={setInvitationsControlsValue}
          isLoading={isLoadingData}
        />
      ),
    },
    {
      title: 'Access Request',
      component: (
        <SubscriptionRequests
          id={customerId}
          controlsValue={requestsControlsValue}
        />
      ),
      controls: (
        <RequestsTableControls
          controlValues={requestsControlsValue}
          onChange={setRequestsControlsValue}
          isLoading={isLoadingData}
        />
      ),
      amount: customer?.totalOfUserAccessRequests,
    },
  ];

  const documentCheckerOptionsAmount =
    customer?.products.find(product => product.name === 'DocumentChecker')
      ?.features.length ?? 0;

  const authentiscanOptionsAmount =
    customer?.products.find(product => product.name === 'AuthentiScan')
      ?.features.length ?? 0;

  const customerInfo = [
    { title: 'Contract number', value: customer?.contractNumber },
    { title: 'Industry', value: customer?.industry },
    { title: 'Phone', value: customer?.phone },
    {
      title: `From ${formattedCreatedAt}`,
      value: `To ${formattedExpiriesAt}`,
    },
    {
      title: 'Document checker',
      value: `${documentCheckerOptionsAmount} option (s) included`,
    },
    {
      title: 'Authentiscan',
      value: `${authentiscanOptionsAmount} option (s) included`,
    },
    {
      title: 'Limit of users',
      value:
        customer?.limitOfUsers === -1
          ? 'Unlimited'
          : `Up to ${customer?.limitOfUsers}`,
    },
  ];

  const isShowTabContent =
    customer?.status === Statuses.ACTIVE ||
    customer?.status === Statuses.INACTIVE;

  useEffect(() => {
    if (isError) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage, { autoClose: 5000 });
    }
  }, [isError]);

  return (
    <PageLayout
      className="pt-[24px]"
      isHeaderLoading={isLoadingData}
      isError={isError}
      headerActions={
        <CustomerActions
          customerId={customerId}
          customer={customer}
          isLoading={isLoadingData}
        />
      }
      isChildRoute
    >
      <InfoBlock
        infoItems={customerInfo}
        isLoading={isLoadingData}
        subscriptionId={customerId}
        subscriptionNotes={customer?.note}
        subscriptionType="customer"
      />
      {customer?.reachedUserLimit && (
        <Alert
          message="This customer has reached the maximum number of users subscribed."
          className="mt-[24px]"
        />
      )}
      {isShowTabContent ? (
        <>
          <div className="flex h-[48px] items-center justify-between mt-[46px] mb-[24px]">
            <Tabs
              items={customerTabContent}
              isLoading={isLoadingData}
              currentTabIndex={currentTabIndex}
              setCurrentTabIndex={setCurrentTabIndex}
            />

            {customerTabContent[currentTabIndex].controls}
          </div>

          {customerTabContent[currentTabIndex].component}
        </>
      ) : (
        <SubscriptionStatusInfoBlock
          id={customerId}
          name={customer?.name}
          isLoading={isLoadingData}
          status={customer?.status}
        />
      )}
    </PageLayout>
  );
};

export { Customer };
