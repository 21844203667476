import { AccountInfo } from '@azure/msal-common';
import { UserRole, OrganizationType, Permissions } from 'shared/ts';
import { getOrganizationType, hasUserPermissions } from '.';

const getUserRole = (user: AccountInfo): UserRole => {
  const organizationType = getOrganizationType(user);
  const manageSubscriptions = hasUserPermissions(
    user,
    Permissions.ManageSubscriptions,
  );
  const manageUsers = hasUserPermissions(user, Permissions.ManageUsers);

  if (organizationType === OrganizationType.Reseller && manageSubscriptions)
    return 'reseller';

  if (organizationType === OrganizationType.Keesing && manageSubscriptions)
    return 'keesingAdministrator';

  if (manageUsers) return 'adminUser';

  return 'regularUser';
};

export { getUserRole };
