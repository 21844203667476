import { FC } from 'react';

import { cn } from 'shared/lib';

import { TOption } from '../select.types';

interface SelectOptionProps {
  option: TOption;
  index: number;
  highlightedOptionIndex: number;
  selectedOption?: TOption;
  onChange: (option: TOption) => void;
}

const SelectOption: FC<SelectOptionProps> = ({
  option,
  index,
  highlightedOptionIndex,
  selectedOption,
  onChange,
}) => {
  return (
    <div
      tabIndex={highlightedOptionIndex === index ? 0 : -1}
      onClick={() => onChange(option)}
      className={cn('text-16 text-black-40 hover:text-black', {
        'bg-main-80': highlightedOptionIndex === index,
        'text-main hover:text-main': selectedOption?.value === option.value,
      })}
    >
      <p className="select-none py-[12px] px-[16px]">{option.label}</p>
    </div>
  );
};

export { SelectOption };
