import { FC, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { UsersTable, UsersTableControlsValue } from 'features';
import {
  useDeleteSubscriptionUserMutation,
  useEditSubscriptionUserMutation,
  useGetSubscriptionUsersQuery,
} from 'shared/api/subscriptions';
import { getErrorMessage } from 'shared/lib';
import {
  DeleteUserParams,
  EditUserParams,
  SortOrder,
  SortParamsType,
  UserType,
} from 'shared/ts';

const PAGE_SIZE = 15;

const defaultSortParams = {
  sortBy: 'createdAt',
  sortOrder: SortOrder.DESC,
};

interface SubscriptionUsersProps {
  id: string;
  controlsValue: UsersTableControlsValue;
}

const SubscriptionUsers: FC<SubscriptionUsersProps> = ({
  id,
  controlsValue,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [sortParams, setSortParams] =
    useState<SortParamsType>(defaultSortParams);

  // Cache controlsValue to avoid refetching after prop changes
  const [cachedControlsValue, setCachedControlsValue] =
    useState<UsersTableControlsValue>(controlsValue);

  const { filters, search } = cachedControlsValue;

  const { data, isFetching, isLoading } = useGetSubscriptionUsersQuery({
    ...sortParams,
    type: filters.type,
    status: filters.status.length === 1 ? filters.status : undefined,
    subscriptionId: id,
    pageNumber,
    pageSize: PAGE_SIZE,
    search,
  });

  const [editUser] = useEditSubscriptionUserMutation();
  const [deleteUser] = useDeleteSubscriptionUserMutation();

  // Reset the page number and update cachedControlsValue to trigger a refetch with updated page
  useEffect(() => {
    setPageNumber(1);
    setCachedControlsValue(controlsValue);
  }, [search, controlsValue]);

  const users = data?.data;
  const totalSubscriptionUsersRecord = data?.totalRecords ?? 0;

  const currentPage = data?.currentPage ?? pageNumber;

  const isLoaderVisible = isFetching || isLoading;

  const handleUserDelete = async (
    deleteUserParams: Omit<DeleteUserParams, 'subscriptionId'>,
    user: UserType,
  ) => {
    try {
      await deleteUser({ ...deleteUserParams, subscriptionId: id }).unwrap();
      toast.success(`User ${user.givenName} has been deleted`);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  const handleUserEdit = async (
    editUserParams: Omit<EditUserParams, 'subscriptionId'>,
  ) => {
    try {
      await editUser({ ...editUserParams, subscriptionId: id }).unwrap();
      toast.success(`User changes have been successfully saved`);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  return (
    <UsersTable
      organizationId={id}
      users={users}
      isLoading={isLoaderVisible}
      onSort={setSortParams}
      defaultSortParams={defaultSortParams}
      pagination={{
        currentPage,
        pageSize: PAGE_SIZE,
        total: totalSubscriptionUsersRecord,
        onChange: setPageNumber,
      }}
      onEditUser={handleUserEdit}
      onDeleteUser={handleUserDelete}
    />
  );
};

export { SubscriptionUsers };
