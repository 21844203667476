import { FC, useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import { InvitationsTable, InvitationsTableControlsValue } from 'features';
import { getErrorMessage, getOrganizationId } from 'shared/lib';
import {
  DeleteInvitationParams,
  EditInvitationParams,
  SortOrder,
  SortParamsType,
} from 'shared/ts';

import {
  useDeleteOrganizationInvitationMutation,
  useEditOrganizationInvitationMutation,
  useGetOrganizationInvitationsQuery,
} from 'shared/api/myOrganization';
import { useMsal } from '@azure/msal-react';

const PAGE_SIZE = 15;

const defaultSortParams = {
  sortBy: 'createdAt',
  sortOrder: SortOrder.DESC,
};

interface MyOrganizationInvitationsProps {
  controlsValue: InvitationsTableControlsValue;
}

const MyOrganizationInvitations: FC<MyOrganizationInvitationsProps> = ({
  controlsValue,
}) => {
  const { accounts } = useMsal();
  const currentAccount = accounts[0];
  const organizationId = getOrganizationId(currentAccount);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortParams, setSortParams] =
    useState<SortParamsType>(defaultSortParams);

  // Cache controlsValue to avoid refetching after prop changes
  const [cachedControlsValue, setCachedControlsValue] =
    useState<InvitationsTableControlsValue>(controlsValue);

  const { search } = cachedControlsValue;

  const { data, isFetching, isLoading } = useGetOrganizationInvitationsQuery({
    ...sortParams,
    pageNumber,
    pageSize: PAGE_SIZE,
    search,
  });

  const [editInvitation] = useEditOrganizationInvitationMutation();
  const [deleteInvitation] = useDeleteOrganizationInvitationMutation();

  // Reset the page number and update cachedControlsValue to trigger a refetch with updated page
  useEffect(() => {
    setPageNumber(1);
    setCachedControlsValue(controlsValue);
  }, [search, controlsValue]);

  const invitations = data?.data;
  const totalInvitationsRecord = data?.totalRecords ?? 0;

  const currentPage = data?.currentPage ?? pageNumber;

  const isLoaderVisible = isFetching || isLoading;

  const handleEditInvitation = async (
    editInvitationParams: Omit<EditInvitationParams, 'subscriptionId'>,
  ) => {
    try {
      await editInvitation(editInvitationParams).unwrap();

      toast.success(`${editInvitationParams.givenName} has been edited`);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  const handleDeleteInvitation = async (
    deleteInvitationParams: Omit<DeleteInvitationParams, 'subscriptionId'>,
  ) => {
    try {
      await deleteInvitation(deleteInvitationParams);

      toast.success('Invitation has been deleted');
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  return (
    <InvitationsTable
      organizationId={organizationId}
      invitations={invitations}
      isLoading={isLoaderVisible}
      onDeleteInvitation={handleDeleteInvitation}
      onEditInvitation={handleEditInvitation}
      onSort={setSortParams}
      defaultSortParams={defaultSortParams}
      pagination={{
        currentPage,
        pageSize: PAGE_SIZE,
        total: totalInvitationsRecord,
        onChange: setPageNumber,
      }}
    />
  );
};

export { MyOrganizationInvitations };
