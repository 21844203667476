import { RoleItem } from 'shared/api/roles';
import { userRoles } from 'shared/constants/userRoles';
import { InviteUserType } from 'shared/ts';

// params: roles: RoleItem[] | undefined - The list of roles available to the user.
const getInviteUserInitialValues = (
  roles: RoleItem[] | undefined,
): InviteUserType => {
  // Take the id of the first role with the name 'Basic User' and return it as the default role.
  const defaultRole = roles?.find(
    role => role.name.toLowerCase() === userRoles.basicUser.toLowerCase(),
  );

  return {
    givenName: '',
    surname: '',
    email: '',
    expired: false,
    role: defaultRole || ({} as RoleItem),
    roleId: defaultRole?.id || '',
  };
};

export { getInviteUserInitialValues };
