import { FC } from 'react';

import { Tooltip, ITooltip } from 'react-tooltip';

const CustomTooltip: FC<ITooltip> = props => {
  return (
    <Tooltip
      className="!bg-main-20 !duration-300 !rounded-[8px] !text-14 !leading-[21px]"
      classNameArrow="!rounded-br-[2px]"
      {...props}
    />
  );
};

export { CustomTooltip };
