import classnames from 'classnames';
import { extendTailwindMerge } from 'tailwind-merge';

const twMergeCustom = extendTailwindMerge({
  classGroups: {
    'font-size': [
      'text-12',
      'text-14',
      'text-15',
      'text-16',
      'text-18',
      'text-20',
    ],
  },
});

const cn = (...inputs: Parameters<typeof classnames>) => {
  const classes = classnames(inputs);
  return twMergeCustom(classes);
};

export { cn };
