import { FC } from 'react';

import { Skeleton } from 'shared/ui';

interface TabsSkeletonProps {
  itemsLength: number;
}

const TabsSkeleton: FC<TabsSkeletonProps> = ({ itemsLength }) => {
  return (
    <div className="flex gap-x-[16px]">
      {Array.from({ length: itemsLength }).map((_, index) => (
        <Skeleton key={index} className="w-[135px] h-[35px]" />
      ))}
    </div>
  );
};

export { TabsSkeleton };
