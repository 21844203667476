import parsePhoneNumber, { NumberFormat } from 'libphonenumber-js/min';

interface FormatPhoneNumberArgs {
  value: string;
  format?: NumberFormat;
}

const formatPhoneNumber = ({
  value,
  format = 'INTERNATIONAL',
}: FormatPhoneNumberArgs) => {
  const phoneNumber = parsePhoneNumber(value);

  if (!phoneNumber) {
    return value;
  }

  return phoneNumber.format(format);
};

export { formatPhoneNumber };
