import { FC } from 'react';

import { FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import {
  AuthStepForm,
  authStepSchema,
  authInitialValues,
  AmountOfUsersFormBlock,
  amountOfUsersFormBlockSchema,
  amountOfUsersInitialValues,
} from 'features/Forms';

import {
  SubscriptionResponse,
  useEditSubscriptionMutation,
} from 'shared/api/subscriptions';
import { ReactComponent as RefreshIcon } from 'shared/icons/refresh_icon.svg';
import { ReactComponent as CloseIcon } from 'shared/icons/close_icon.svg';
import { ReactComponent as CheckIcon } from 'shared/icons/check_icon.svg';
import { Button, Icon, Modal, Scrollbar } from 'shared/ui';
import { getErrorMessage } from 'shared/lib';

import { EditAuthAndUsersModalSkeleton } from './ui';

interface EditAuthAndUsersModalProps {
  isOpen: boolean;
  subscription?: SubscriptionResponse;
  subscriptionId: string;
  onClose: () => void;
  onConfirm: () => void;
  isReseller: boolean;
}

const EditAuthAndUsersModal: FC<EditAuthAndUsersModalProps> = ({
  isOpen,
  subscriptionId,
  subscription,
  onClose,
  onConfirm,
  isReseller,
}) => {
  const subscriptionTypeTextCapitalize = isReseller ? 'Reseller' : 'Customer';

  const [editSubscription, result] = useEditSubscriptionMutation();

  const { isLoading } = result;

  const preparedInitialValues = subscription
    ? {
        approvedDomains: subscription.approvedDomains,
        authenticationMethod: subscription.authenticationMethod,
        identityProvider: subscription.identityProvider,
        requiresNoInvitationForApprovedDomains:
          subscription.requiresNoInvitationForApprovedDomains,
        technicalContactEmail: subscription.technicalContactEmail,
        limitOfUsers: subscription.limitOfUsers,
      }
    : { ...authInitialValues, ...amountOfUsersInitialValues };

  const handleSubmit = async (values: typeof preparedInitialValues) => {
    try {
      await editSubscription({
        subscriptionId,
        authentication: values,
      }).unwrap();

      onConfirm();
      toast.success(
        `${subscriptionTypeTextCapitalize} has been successfully updated`,
      );
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  const formik = useFormik<typeof preparedInitialValues>({
    validationSchema: authStepSchema.concat(amountOfUsersFormBlockSchema),
    initialValues: preparedInitialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={onClose}
      customClassName="h-[90vh] overflow-y-hidden"
      contentClassName="p-0"
    >
      <FormikProvider value={formik}>
        <form
          className="relative h-full w-[614px]"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <div className="flex justify-between p-[24px] pb-[16px] items-center border-b-[1px] border-grey-80">
            <p className="text-22 leading-[32px] font-semibold">
              Authentication & Users
            </p>

            <button type="button" onClick={onClose}>
              <Icon icon={<CloseIcon />} size="lg" className=" fill-black" />
            </button>
          </div>

          <div className="overflow-hidden h-full">
            <Scrollbar
              height="100%"
              maxHeight="100%"
              viewClassName="pb-[204px] px-[24px] pt-[24px]"
              trackVerticalStyle={{ right: '3px' }}
              trackVerticalClassName="pb-[180px]"
            >
              {subscription ? (
                <>
                  <AuthStepForm isModalView />
                  <AmountOfUsersFormBlock />
                </>
              ) : (
                <EditAuthAndUsersModalSkeleton />
              )}
            </Scrollbar>
          </div>

          <div className="sticky border-t-[1px] border-grey-80  p-[24px] bottom-0  bg-white flex items-center gap-x-[16px]">
            <Button
              disabled={isLoading}
              color="tonal"
              fullWidth
              size="lg"
              startIcon={<RefreshIcon />}
              onClick={() => formik.resetForm()}
            >
              Reset changes
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              startIcon={<CheckIcon />}
              color="primary"
              fullWidth
              size="lg"
            >
              Save
            </Button>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export { EditAuthAndUsersModal };
