import {
  ComponentPropsWithRef,
  FocusEvent,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useLayoutEffect,
  ChangeEvent,
  MouseEvent,
} from 'react';

import { cn, moveCursorToEndOfTextArea } from 'shared/lib';

import { FieldWrapper } from '../FieldWrapper/FieldWrapper';

interface TextAreaProps extends ComponentPropsWithRef<'textarea'> {
  fullWidth?: boolean;
  label?: string;
  error?: boolean;
  helperText?: ReactNode;
  value?: string;
  isShowTextAmount?: boolean;
  textAreaClassName?: string;
  icon?: ReactNode;
  iconClassName?: string;
  wrapperClassName?: string;
  maxHeight?: number;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      label,
      helperText,
      disabled,
      fullWidth,
      isShowTextAmount,
      maxLength,
      error,
      textAreaClassName,
      wrapperClassName,
      onFocus,
      onBlur,
      onChange,
      value,
      maxHeight = 216,
      className,
      ...props
    },
    ref,
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [isFocused, setIsFocused] = useState(false);

    const textAreaValue = textAreaRef.current?.value ?? value;

    const handleWrapperMouseDown = (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }

      const textAreaRefCurrent = textAreaRef.current;

      const textAreaClicked = event.target === textAreaRefCurrent;

      if (!isFocused) {
        textAreaRefCurrent?.focus();
      }

      if (!textAreaClicked) {
        event.preventDefault();

        moveCursorToEndOfTextArea(textAreaRef);
      }
    };

    const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
      setIsFocused(true);

      if (onFocus) {
        onFocus(event);
      }
    };

    const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
      setIsFocused(false);

      if (onBlur) {
        onBlur(event);
      }
    };

    const adjustHeight = () => {
      const textAreaRefCurrent = textAreaRef.current;

      if (textAreaRefCurrent) {
        // To accurately calculate the scrollHeight, momentarily reset the height to '0px'
        textAreaRefCurrent.style.height = '0px';
        const { scrollHeight } = textAreaRefCurrent;

        // Set the height directly outside of the render loop to avoid incorrect values
        // Using state or a ref to set the height would result in an inaccurate value
        textAreaRefCurrent.style.height = `${Math.min(
          scrollHeight,
          maxHeight,
        )}px`;
      }
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      adjustHeight();

      if (onChange) {
        onChange(event);
      }
    };

    useLayoutEffect(() => {
      adjustHeight();
    }, []);

    useImperativeHandle(ref, () => textAreaRef.current!, []);

    return (
      <FieldWrapper
        isFocused={isFocused}
        fullWidth={fullWidth}
        label={label}
        onWrapperMouseDown={handleWrapperMouseDown}
        wrapperClassName={wrapperClassName}
        childrenContainerClassName={cn('h-auto cursor-default', className)}
        isShowTextAmount={isShowTextAmount}
        helperText={helperText}
        error={error}
        disabled={disabled}
        maxLength={maxLength}
        inputValue={textAreaValue}
      >
        <textarea
          id={`id-${label}`}
          ref={textAreaRef}
          disabled={disabled}
          onBlur={handleBlur}
          onFocus={handleFocus}
          maxLength={maxLength}
          value={value}
          rows={1}
          className={cn(
            'textArea bg-transparent flex-grow placeholder:text-black-50 resize-none outline-none',
            textAreaClassName,
          )}
          onChange={handleChange}
          {...props}
        />
      </FieldWrapper>
    );
  },
);

TextArea.displayName = 'TextArea';
export { TextArea };
