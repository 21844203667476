import { useState, FC, useRef } from 'react';

import Scrollbars from 'react-custom-scrollbars-2';

import { ReactComponent as ChevronDownIcon } from 'shared/icons/chevron_down_icon.svg';
import { cn } from 'shared/lib';
import { Dropdown, Scrollbar } from 'shared/ui';

interface YearDropdownProps {
  years: number[];
  onSelect: (year: number) => void;
  selectedYear: number;
  visibleOptions?: number;
}

const YearDropdown: FC<YearDropdownProps> = ({
  selectedYear,
  years,
  visibleOptions = 7,
  onSelect,
}) => {
  const [open, setOpen] = useState(false);

  const scrollbarRef = useRef<Scrollbars>(null);
  const selectedYearRef = useRef<HTMLDivElement>(null);

  const optionsHeight = visibleOptions * 44;

  const handleDropdownOpen = (isOpen: boolean) => {
    setOpen(isOpen);

    const scrollbarRefCurrent = scrollbarRef.current;
    const selectedYearRefCurrent = selectedYearRef.current;

    if (scrollbarRefCurrent && selectedYearRefCurrent) {
      const scrollY = selectedYearRefCurrent.offsetTop;

      scrollbarRefCurrent.scrollTop(scrollY);
    }
  };

  const handleYearDropdown = (year: number) => {
    onSelect(year);
    setOpen(false);
  };

  return (
    <Dropdown
      isOpen={open}
      positionY="bottom"
      alwaysMountedMenu
      positionX="center"
      buttonProps={{
        endIcon: <ChevronDownIcon />,
        color: undefined,
        className:
          'text-12 leading-[18px] p-0 w-[46px] outline-none text-black-50',
        iconClassName: cn('ml-[0px] fill-black-50', {
          'rotate-180': open,
        }),
        iconSize: 'sm',
      }}
      menuClassName="p-0"
      onOpen={handleDropdownOpen}
      buttonContent={selectedYear}
    >
      <div className="w-[152px] py-[12px]">
        <p className="pl-[24px] py-[8px] text-13 text-black-40 text-left mb-[4px]">
          Choose Year
        </p>
        <Scrollbar
          height={optionsHeight}
          ref={scrollbarRef}
          trackVerticalStyle={{ top: 2, width: 4 }}
          viewClassName="px-[12px] flex flex-col gap-y-[4px] h-full"
        >
          {years.map(year => {
            const isSelectedYear = year === selectedYear;

            return (
              <div
                key={year}
                onClick={() => handleYearDropdown(year)}
                ref={isSelectedYear ? selectedYearRef : undefined}
                className={cn(
                  'py-[8px] px-[12px] text-16 cursor-pointer font-semibold text-main-50 hover:bg-main-90 rounded-[5px]',
                  {
                    'text-main bg-main-99 hover:bg-main-99': isSelectedYear,
                  },
                )}
              >
                <span>{year}</span>
              </div>
            );
          })}
        </Scrollbar>
      </div>
    </Dropdown>
  );
};

export { YearDropdown };
