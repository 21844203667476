import { Configuration } from '@azure/msal-browser';

const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_POLICY_ID,
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_METADATA_URL,
    },
  },
  authorityDomain: `${process.env.REACT_APP_TENANT_NAME}.b2clogin.com`,
};

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    authority: b2cPolicies.authorities.signUpSignIn.authority ?? '',
    knownAuthorities: [b2cPolicies.authorityDomain ?? ''],
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const loginRequest = {
  scopes: [
    `https://${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/smapi/Organization.Read`,
    `https://${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/smapi/Organization.Write`,
    `https://${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/smapi/User.Write`,
    `https://${process.env.REACT_APP_TENANT_NAME}.onmicrosoft.com/smapi/User.Read`,
  ],
};

export { b2cPolicies, msalConfig, loginRequest };
