import { ChangeEvent } from 'react';

import { useFormikContext } from 'formik';

import { Input, Radio } from 'shared/ui';

import { AmountOfUsersInitialValuesType } from './amountOfUsersInitialValues';

const AmountOfUsersFormBlock = () => {
  const { values, handleBlur, errors, touched, setFieldValue } =
    useFormikContext<AmountOfUsersInitialValuesType>();

  const handleLimitUserInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const numericValue = Number(value.replace(/[^0-9]/g, ''));
    setFieldValue('limitOfUsers', numericValue);
  };

  return (
    <div>
      <h3 className="mt-8 mb-6 font-semibold text-black text-20">
        Limit of users
      </h3>
      <div>
        <Radio
          name="limitOfUsers"
          value={-1}
          endLabel="Unlimited"
          checked={values.limitOfUsers === -1}
          onChange={event =>
            setFieldValue('limitOfUsers', Number(event.target.value))
          }
        />
        <Radio
          name="limitOfUsers"
          labelClassName="mt-4 mb-3"
          value={0}
          checked={values.limitOfUsers > -1}
          endLabel="Limited"
          onChange={event =>
            setFieldValue('limitOfUsers', Number(event.target.value))
          }
        />
        {values.limitOfUsers !== -1 && (
          <Input
            name="limitOfUsers"
            className="max-w-[200px]"
            label="Limit of users"
            value={values.limitOfUsers}
            onChange={handleLimitUserInputChange}
            onBlur={handleBlur}
            error={!!errors.limitOfUsers && touched.limitOfUsers}
            helperText={(touched.limitOfUsers && errors.limitOfUsers) || ' '}
          />
        )}
      </div>
    </div>
  );
};

export { AmountOfUsersFormBlock };
