import { ComponentPropsWithRef, forwardRef, ReactNode } from 'react';

import { cn } from 'shared/lib';

import { Icon } from '../Icon/Icon';
import { Color, Size } from './button.types';

interface ButtonProps extends ComponentPropsWithRef<'button'> {
  children: ReactNode;
  color?: Color;
  iconSize?: Size;
  size?: Size;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  iconClassName?: string;
  fullWidth?: boolean;
}

const buttonColorMap = {
  primary: `[&>svg]:fill-white bg-main text-white
    hover:bg-[#1E65D9] 
    active:bg-[#1A59BF] 
    focus-visible:bg-[#1E65D9] focus-visible:outline-main-80 focus-visible:outline-2`,

  outline: `[&>svg]:fill-main text-main border-[1px] border-main 
  hover:bg-main-99 [&>svg]:hover:fill-[#1E65D9] hover:border-[#1E65D9] hover:text-[#1E65D9] 
  [&>svg]:active:fill-[#1A59BF] active:border-[#1A59BF] active:text-[#1A59BF] active:bg-main-90 
  focus-visible:bg-main-90`,

  secondary: `[&>svg]:fill-main-50 text-main-50 bg-main-99 border-[1px] border-main-80 
  hover:bg-main-90 [&>svg]:hover:fill-main-keesing hover:text-main-keesing 
  [&>svg]:active:fill-main-20 active:border-main active:text-main-20 active:bg-main-80 
  focus-visible:bg-main-90 focus-visible:outline-main focus-visible:text-main-keesing`,

  tonal: `[&>svg]:fill-main-keesing text-main-keesing bg-grey-80 
  hover:bg-main-90 [&>svg]:hover:fill-main-keesing hover:text-main-keesing 
  [&>svg]:active:fill-main-20 active:text-main-20 active:bg-main-80 
  focus-visible:bg-main-90 focus-visible:outline-main-70`,

  destructive: `[&>svg]:fill-error text-error bg-[#FFF5F6] 
  hover:bg-error-80 
  [&>svg]:active:fill-[#C41A2E] active:text-[#C41A2E] active:bg-[#FFDBD9] 
  focus-visible:bg-error-80 focus-visible:outline-error`,
};

const buttonSizeMap = {
  lg: 'max-h-[56px] px-[32px] py-[16px]',
  md: 'max-h-[48px] p-[12px]',
  sm: 'max-h-[40px] px-[16px] py-[8px]',
};

const disabledStyles = 'opacity-40 pointer-events-none';

const buttonBaseStyles =
  'inline-flex font-semibold text-16 justify-center items-center ease-in-out text-center rounded-[10px] duration-200';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className = '',
      iconClassName = '',
      color,
      disabled,
      size = 'md',
      iconSize = 'lg',
      startIcon,
      endIcon,
      fullWidth,
      ...props
    },
    ref,
  ) => {
    const buttonColor = color && buttonColorMap[color];

    const buttonSize = buttonSizeMap[size];

    const buttonClass = cn(
      buttonBaseStyles,
      buttonColor,
      buttonSize,
      disabled && disabledStyles,
      { 'w-full': fullWidth },
      className,
    );

    return (
      <button
        type="button"
        tabIndex={0}
        className={buttonClass}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {startIcon && (
          <Icon
            icon={startIcon}
            size={iconSize}
            className={cn('fill-black-30 duration-200 mr-[8px]', iconClassName)}
            disabled={disabled}
          />
        )}
        {children}
        {endIcon && (
          <Icon
            icon={endIcon}
            size={iconSize}
            className={cn('fill-black-30 duration-200 ml-[8px]', iconClassName)}
            disabled={disabled}
          />
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
export { Button };
