import { useRef } from 'react';

import { ReactComponent as FilterIcon } from 'shared/icons/filter_icon.svg';
import { Divider, Dropdown } from 'shared/ui';

import { FilterDataType, SelectedOptionsType } from './filter.types';
import { FilterOption } from './ui';

interface FilterProps<T extends SelectedOptionsType> {
  data: FilterDataType[];
  selectedOptions: T;
  onChange: (optionValue: T) => void;
}

const Filter = <T extends SelectedOptionsType>({
  selectedOptions,
  data,
  onChange,
}: FilterProps<T>) => {
  const multiSelectRef = useRef<HTMLDivElement>(null);

  const handleRadioChange = (value: string | undefined, name: string) => {
    onChange({ ...selectedOptions, [name]: value });
  };

  const handleSwitchChange = (value: boolean, name: string) => {
    onChange({ ...selectedOptions, [name]: !value });
  };

  const handleCheckboxChange = (
    value: string | undefined,
    name: string,
    isSelected: boolean,
  ) => {
    const currentValue = selectedOptions[name];

    if (!Array.isArray(currentValue) || !value) {
      return null;
    }

    if (!currentValue) {
      return onChange({
        ...selectedOptions,
        [name]: value,
      });
    }

    if (isSelected) {
      const filteredStatuses = currentValue.filter(
        selectedOption => selectedOption !== value,
      );

      return onChange({
        ...selectedOptions,
        [name]: filteredStatuses,
      });
    }

    return onChange({
      ...selectedOptions,
      [name]: [...currentValue, value],
    });
  };

  return (
    <div className="inline-block relative" ref={multiSelectRef}>
      <Dropdown
        buttonProps={{ startIcon: <FilterIcon /> }}
        buttonContent="Filter"
      >
        <div className="w-[248px]">
          {data.map((item, index) => (
            <div key={item.name}>
              {item.title && (
                <p className="px-[8px] py-[12px] text-13 text-black-40">
                  {item.title}
                </p>
              )}
              {item.options.map(option => (
                <FilterOption
                  key={`${item.name}-${option.value}`}
                  name={item.name}
                  type={item.type}
                  option={option}
                  onRadioChange={handleRadioChange}
                  onCheckboxChange={handleCheckboxChange}
                  onSwitchChange={handleSwitchChange}
                  selectedOptions={selectedOptions}
                />
              ))}
              {index + 1 < data.length && (
                <Divider className="bg-grey-80 mt-[-1px] mb-[8px]" />
              )}
            </div>
          ))}
        </div>
      </Dropdown>
    </div>
  );
};

export { Filter };
