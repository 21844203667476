import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Button } from 'shared/ui';

interface ListItemProps {
  color: 'destructive' | 'default';
  text: ReactNode;
  icon: ReactNode;
  onClick?: () => void;
}

const ListItem: FC<ListItemProps> = ({ color, text, icon, onClick }) => {
  const isDestructiveColor = color === 'destructive';

  return (
    <Button
      color={isDestructiveColor ? 'destructive' : undefined}
      size="sm"
      className={classNames(
        'rounded-[4px] font-normal px-[12px] justify-start',
        {
          'text-black-30 hover:bg-main-90 active:bg-main-80':
            !isDestructiveColor,
        },
      )}
      startIcon={icon}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export { ListItem };
