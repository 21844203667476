import { FC, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from 'shared/ui';
import { ReactComponent as ChevronRightIcon } from 'shared/icons/chevron_right_icon.svg';

interface BreadcrumbsProps {
  matches: {
    crumb: ReactNode;
    pathname: string;
  }[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ matches }) => {
  return (
    <div className="flex items-center mb-[4px] gap-x-[4px]">
      {matches.map((match, index) => {
        const isFirstMatch = index === 0;

        if (isFirstMatch) {
          return (
            <span
              key={match.pathname}
              className="flex items-center text-12 pointer-events-none h-5"
            >
              {match.crumb}
              <Icon
                icon={<ChevronRightIcon />}
                size="sm"
                className="fill-main"
              />
            </span>
          );
        }

        return (
          <Link
            key={match.pathname}
            to={match.pathname}
            className="flex items-center text-main text-12 h-5 underline hover:text-[#1A59BF] duration-200"
          >
            {match.crumb}

            {index < matches.length - 1 && (
              <Icon
                icon={<ChevronRightIcon />}
                size="sm"
                className="fill-main hover:fill-[#1A59BF]"
              />
            )}
          </Link>
        );
      })}
    </div>
  );
};

export { Breadcrumbs };
