import {
  useState,
  useCallback,
  FC,
  ChangeEvent,
  useEffect,
  useRef,
} from 'react';

import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';

import { useUpdateSubscriptionNotesMutation } from 'shared/api/subscriptions';
import { getErrorMessage, moveCursorToEndOfTextArea } from 'shared/lib';
import { Divider, TextArea } from 'shared/ui';
import { SubscriptionType } from 'shared/ts';

interface NoteInputProps {
  subscriptionId: string;
  isVisible: boolean;
  notes?: string;
  subscriptionType: SubscriptionType;
}

const NoteInput: FC<NoteInputProps> = ({
  notes,
  subscriptionId,
  isVisible,
  subscriptionType,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textAreaValue, setTextAreaValue] = useState(notes);

  const [updateNotes] = useUpdateSubscriptionNotesMutation();

  const isReseller = subscriptionType === 'reseller';

  const saveNotes = async (note: string) => {
    try {
      await updateNotes({
        subscriptionId,
        note,
      }).unwrap();
      toast.success(`The notes were successfully saved`);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  const debouncedSaveNotes = useCallback(
    debounce((value: string) => {
      saveNotes(value);
    }, 1000),
    [],
  );

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(event.target.value);
    debouncedSaveNotes(event.target.value);
  };

  useEffect(() => {
    setTextAreaValue(notes);
  }, [notes]);

  useEffect(() => {
    moveCursorToEndOfTextArea(textAreaRef, textAreaValue);
  }, [isVisible, notes]);

  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <Divider className="my-4 bg-grey-80" />
      <TextArea
        isShowTextAmount
        ref={textAreaRef}
        id="notes"
        maxLength={500}
        value={textAreaValue}
        onChange={handleChange}
        placeholder={`${isReseller ? 'Reseller' : 'Customer'} notes`}
        autoFocus
      />
    </div>
  );
};

export { NoteInput };
