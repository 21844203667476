import { FC } from 'react';

import { useFormikContext } from 'formik';

import { Checkbox, Input, Radio, Select, TagInput } from 'shared/ui';
import { cn } from 'shared/lib';

import { AuthInitialValuesType } from './authInitialValues';

const options = [
  { label: 'OpenIdConnect IDP', value: 1 },
  { label: 'Microsoft Office 365', value: 2 },
];

interface AuthStepFormProps {
  isModalView?: boolean;
}

const AuthStepForm: FC<AuthStepFormProps> = ({ isModalView }) => {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormikContext<AuthInitialValuesType>();

  return (
    <div className={cn('w-full p-[24px]', { 'p-0': isModalView })}>
      <h3 className="mb-[32px] text-20 font-semibold text-black">
        Authentication Method
      </h3>

      <div className="flex flex-col gap-y-[16px]">
        <Radio
          name="authenticationMethod"
          endLabel="Email and passwords"
          disabled={isModalView}
          value="1"
          checked={values.authenticationMethod === 1}
          onChange={event => {
            setValues({
              ...values,
              identityProvider: undefined,
              technicalContactEmail: '',
              authenticationMethod: Number(event.target.value) as 1 | 2,
            });
            setFieldTouched('technicalContactEmail', false);
            setFieldTouched('approvedDomains', false);
          }}
        />
        <Radio
          name="authenticationMethod"
          endLabel="SSO (Single Sign On)"
          helperText="Currently only supporting Microsoft accounts"
          disabled={isModalView}
          value="2"
          checked={values.authenticationMethod === 2}
          onChange={event => {
            setValues({
              ...values,
              identityProvider: 1,
              authenticationMethod: Number(event.target.value) as 1 | 2,
            });
          }}
        />
      </div>

      <div className="flex flex-col gap-y-[24px] mt-[24px]">
        {values.authenticationMethod === 2 && (
          <>
            <Select
              options={options}
              fullWidth
              name="identityProvider"
              label="Identity Provider"
              disabled={isModalView}
              placeholder="OpenIDConnect IDP"
              value={values.identityProvider}
              onChange={value => setFieldValue('identityProvider', value)}
            />
            <Input
              name="technicalContactEmail"
              label="Technical responsible email"
              helperText={
                <span>
                  An email will be sent to the responsible for configuring th
                  IDP on the client side, <br />
                  usually someone from the IT Department
                </span>
              }
              fullWidth
              value={values.technicalContactEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                !!errors.technicalContactEmail && touched.technicalContactEmail
              }
            />
          </>
        )}

        <TagInput
          name="approvedDomains"
          label="Approved domains"
          value={values.approvedDomains}
          onChange={value => setFieldValue('approvedDomains', value)}
          onBlur={handleBlur}
          error={!!errors.approvedDomains && touched.approvedDomains}
          helperText={
            (touched.approvedDomains && errors.approvedDomains) || ' '
          }
        />

        <Checkbox
          name="requiresNoInvitationForApprovedDomains"
          checked={values.requiresNoInvitationForApprovedDomains}
          onChange={handleChange}
          labelClassName="font-semibold"
          helperTextClassName="text-14 leading-[24px] text-black-40"
          endLabel="No Invitation Required for approved domains"
          helperText="A user can sign in without invitation if it its email address belong to one of the approved domains."
        />
      </div>
    </div>
  );
};

export { AuthStepForm };
