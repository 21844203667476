import { FC } from 'react';

import { ToastContainer, ToastContainerProps } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

interface ToastProps extends ToastContainerProps {}

const Toast: FC<ToastProps> = props => {
  return (
    <ToastContainer
      hideProgressBar
      position="top-right"
      autoClose={2000}
      {...props}
    />
  );
};

export { Toast };
