import { FC } from 'react';
import { useNavigate } from 'react-router';
import { IconButton, CustomTooltip } from 'shared/ui';
import { Filter, FilterDataType } from 'features';
import { routesMap } from 'shared/constants';
import { ReactComponent as PlusIcon } from 'shared/icons/plus_icon.svg';
import { CustomersSearchBar } from './ui';
import { CustomersFilterValues } from './customers.types';

const filterData: FilterDataType[] = [
  {
    name: 'statuses',
    title: 'Status',
    type: 'checkbox',
    options: [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '2' },
      { label: 'Waiting customer', value: '3' },
      { label: 'Ready for activation', value: '4' },
    ],
  },
  {
    name: 'demo',
    type: 'radio',
    title: 'Demo',
    options: [
      { label: 'All Customers', value: undefined },
      { label: 'Demo customers', value: 'true' },
      { label: 'Regular customers', value: 'false' },
    ],
  },
  {
    name: 'expired',
    type: 'radio',
    title: 'Expiration',
    options: [
      { label: 'All customers', value: undefined },
      { label: 'Expired customers', value: 'true' },
      { label: 'Not expired customers', value: 'false' },
    ],
  },
];

interface CustomersActionsProps {
  filterValues: CustomersFilterValues;
  setFilterValues: (filterStatuses: CustomersFilterValues) => void;
  setPageNumber: (page: number) => void;
}

const CustomersActions: FC<CustomersActionsProps> = ({
  filterValues,
  setFilterValues,
  setPageNumber,
}) => {
  const navigate = useNavigate();

  const handleFilterChange = (values: CustomersFilterValues) => {
    setPageNumber(1);
    setFilterValues(values);
  };

  return (
    <div className="flex items-center gap-x-[12px]">
      <CustomersSearchBar />
      <Filter<CustomersFilterValues>
        data={filterData}
        selectedOptions={filterValues}
        onChange={handleFilterChange}
      />
      <IconButton
        color="primary"
        icon={<PlusIcon />}
        onClick={() => navigate(routesMap.addCustomer.path)}
        data-tooltip-id="addCustomer"
      />
      <CustomTooltip
        id="addCustomer"
        content="Add customer"
        place="bottom-start"
      />
    </div>
  );
};

export { CustomersActions };
