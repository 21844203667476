import { FC, Fragment } from 'react';

import { cn } from 'shared/lib';
import { Divider } from 'shared/ui';

import { TOption } from '../select.types';
import { SelectOption } from './SelectOption';

interface SelectMenuProps {
  options: TOption[];
  highlightedOptionIndex: number;
  selectedOption?: TOption;
  onChange: (option: TOption) => void;
  error?: boolean;
}

const SelectMenu: FC<SelectMenuProps> = ({
  options,
  error,
  selectedOption,
  highlightedOptionIndex,
  onChange,
}) => {
  return (
    <div
      className={cn(
        'absolute overflow-hidden w-full z-[4] block top-[100%] translate-y-[6px] right-0',
        'bg-main-99 border-[1px] border-main-80 rounded-[10px] shadow-medium',
        { 'border-error-80 bg-error-99': error },
      )}
    >
      <div className="flex flex-col">
        {options.map((option, index) => (
          <Fragment key={option.value}>
            <SelectOption
              index={index}
              option={option}
              selectedOption={selectedOption}
              highlightedOptionIndex={highlightedOptionIndex}
              onChange={onChange}
            />
            {index + 1 < options.length && (
              <div
                className={cn('px-[16px]', {
                  'p-0': highlightedOptionIndex === index,
                })}
              >
                <Divider className="bg-main-80 z-[0] mb-[-1px]" />
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export { SelectMenu };
