import { FC, MouseEvent, ReactNode } from 'react';
import { cn } from 'shared/lib';

interface FieldWrapperProps {
  wrapperClassName?: string;
  childrenContainerClassName?: string;
  fullWidth?: boolean;
  label?: ReactNode;
  required?: boolean;
  onWrapperMouseDown?: (event: MouseEvent<HTMLDivElement>) => void;
  isFocused: boolean;
  children: ReactNode;
  isShowTextAmount?: boolean;
  helperText: ReactNode;
  error: boolean | undefined;
  disabled: boolean | undefined;
  inputValue: string | undefined;
  maxLength?: number;
}

const FieldWrapper: FC<FieldWrapperProps> = ({
  children,
  isFocused,
  onWrapperMouseDown,
  fullWidth,
  label,
  required,
  wrapperClassName,
  isShowTextAmount: isShowTextAmountProp,
  helperText,
  error,
  childrenContainerClassName,
  disabled,
  maxLength,
  inputValue,
}) => {
  const hasInputValue = !!inputValue;
  const isShowTextAmount = isShowTextAmountProp && maxLength;

  return (
    <div className={cn('relative', wrapperClassName, { 'w-full': fullWidth })}>
      {label && (
        <label
          htmlFor={`id-${label}`}
          className="w-fit block mb-2 text-14 text-black-30"
        >
          {label}
          {required && <span className="inline-block ml-1 text-error">*</span>}
        </label>
      )}
      <div
        onMouseDown={onWrapperMouseDown}
        className={cn(
          'group flex min-h-[56px] p-4 bg-main-99 border-[1px] border-main-80 rounded-[10px] transition duration-300',
          'text-16 text-black-40',
          {
            'w-full': fullWidth,
            'cursor-text': !disabled,
          },
          childrenContainerClassName,
          {
            'text-main-20': hasInputValue,
            'border-main text-main-20 bg-main-90': isFocused,
            'hover:border-main-70 hover:bg-main-90': !isFocused,
          },
          {
            ...(error && {
              'border-error-80 bg-error-99 text-error-20': true,
              'border-error bg-error-90': isFocused,
              'hover:border-error-70 hover:bg-error-90': !isFocused,
            }),
          },
          {
            'border-gray-200 bg-stone-200 bg-opacity-30 text-zinc-400 hover:border-gray-200 hover:text-zinc-400 hover:bg-stone-200 hover:bg-opacity-30':
              disabled,
          },
        )}
      >
        {children}
      </div>

      {(isShowTextAmount || helperText) && (
        <div className="flex justify-between w-full text-12 text-black-50 mt-1">
          {helperText && (
            <span
              className={cn('block whitespace-pre-wrap duration-300', {
                'text-error': error,
              })}
            >
              {helperText}
            </span>
          )}

          {isShowTextAmount && (
            <span className="block ml-auto">
              {inputValue?.length}/{maxLength}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export { FieldWrapper };
