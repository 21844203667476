import { Dispatch, FC, SetStateAction } from 'react';

import { SearchBar } from 'features';

import { RequestsTableControlsValue } from '../requestsTable.types';

import { RequestsTableControlsSkeleton } from './RequestsTableControlsSkeleton';

interface RequestsTableControlsProps {
  controlValues: RequestsTableControlsValue;
  onChange: Dispatch<SetStateAction<RequestsTableControlsValue>>;
  isLoading?: boolean;
}

const RequestsTableControls: FC<RequestsTableControlsProps> = ({
  controlValues,
  onChange,
  isLoading,
}) => {
  const { search } = controlValues;

  if (isLoading) {
    return <RequestsTableControlsSkeleton />;
  }

  return (
    <SearchBar
      data={undefined}
      query={search}
      onQueryChange={query => onChange({ search: query })}
    />
  );
};

export { RequestsTableControls };
