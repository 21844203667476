import { FC, ReactNode } from 'react';

import { Divider, Scrollbar } from 'shared/ui';
import { cn } from 'shared/lib';
import { UnknownDataWithRequiredId } from 'shared/ts';

interface SearchBarOptionsProps {
  data: UnknownDataWithRequiredId[] | undefined;
  renderOption: (item: any) => ReactNode;
}

const SearchBarOptions: FC<SearchBarOptionsProps> = ({
  data,
  renderOption,
}) => {
  return (
    <div
      className={cn(
        'absolute w-[534px] py-[16px] z-[5] translate-y-[4px] right-0 shadow-menu rounded-[10px] bg-white',
        { hidden: !data?.length },
      )}
      onMouseDown={event => event.preventDefault()}
    >
      <Scrollbar
        autoHeight
        autoHeightMax="212px"
        trackVerticalStyle={{ top: 2, width: 4, right: 6 }}
        viewClassName="pb-[18px]"
      >
        <div className="flex flex-col px-[16px]">
          {data?.map((item, index) => (
            <div key={item.id} className="w-[502px]">
              {renderOption(item)}

              {index + 1 < data.length && (
                <div className="py-[8px]">
                  <Divider className="bg-main-99 mb-[-1px] pb-[1px]" />
                </div>
              )}
            </div>
          ))}
        </div>
      </Scrollbar>
    </div>
  );
};

export { SearchBarOptions };
