import { loginRequest } from 'authConfig';
import { msalInstance } from 'msalInstance';

const getAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  const request = {
    scopes: loginRequest.scopes,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
  } catch (error) {
    return '';
  }
};

export { getAccessToken };
