import {
  ComponentProps,
  FC,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { cn } from 'shared/lib';
import { Statuses, StatusType, SubscriptionType } from 'shared/ts';
import { CustomTooltip } from 'shared/ui';

import { getIsTruncated } from './lib';

const getStatusOptions = (
  isExpired?: boolean,
  subscriptionType?: SubscriptionType,
) => ({
  [Statuses.ACTIVE]: {
    text: 'Active',
    colorClassName: isExpired
      ? '[&>span]:bg-warning-40 bg-warning-40 bg-warning-70 text-warning-40'
      : '[&>span]:bg-success-40 bg-success-40 bg-success-70 text-success-40',
  },
  [Statuses.INACTIVE]: {
    text: 'Inactive',
    colorClassName: '[&>span]:bg-grey-30 bg-black-90 text-grey-30',
  },
  [Statuses.READY_FOR_ACTIVATION]: {
    text: 'Ready for activation',
    colorClassName: '[&>span]:bg-main bg-main-90 text-main',
  },
  [Statuses.WAITING_CUSTOMER]: {
    text: `Waiting ${
      subscriptionType === 'reseller' ? 'reseller' : 'customer'
    }`,
    colorClassName: '[&>span]:bg-grey-30 bg-black-90 text-grey-30',
  },
});

interface ChipProps extends ComponentProps<'div'> {
  status: StatusType;
  text?: string;
  isDemo?: boolean;
  isExpired?: boolean;
  subscriptionType?: SubscriptionType;
}

const Chip: FC<ChipProps> = ({
  status,
  className,
  isDemo,
  isExpired,
  text,
  subscriptionType,
}) => {
  const textRef = useRef<HTMLSpanElement | null>(null);
  const tooltipId = useId();
  const [isTruncated, setIsTruncated] = useState(false);
  const { text: statusText, colorClassName } = getStatusOptions(
    isExpired,
    subscriptionType,
  )[status];

  const chipClassName = cn(
    'inline-flex items-center rounded-full px-4 h-[44px] text-15 font-semibold leading-[21px]',
    colorClassName,
    className,
  );

  const primaryText = text || statusText;
  let secondaryText = '';
  if (isDemo) secondaryText += 'Demo';
  if (isExpired) secondaryText += ' Expired';

  useLayoutEffect(() => setIsTruncated(getIsTruncated(textRef.current)), []);
  return (
    <>
      <div className={chipClassName} data-tooltip-id={tooltipId}>
        <span className="block w-2 h-2 mr-2 rounded-full" />
        <div className="flex flex-col max-w-[87px]">
          {secondaryText && (
            <span className="font-normal capitalize text-12">
              {secondaryText.trim()}
            </span>
          )}
          <span ref={textRef} className="truncate first-letter:capitalize">
            {primaryText}
          </span>
        </div>
      </div>
      {isTruncated && (
        <CustomTooltip id={tooltipId} content={primaryText} place="bottom" />
      )}
    </>
  );
};

export { Chip };
