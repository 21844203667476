interface IdpFormInitialValuesType {
  documentMetadataUrl: string;
  clientId: string;
  clientSecret: string;
  secretExpireAt: Date | null;
}

const idpFormInitialValues: IdpFormInitialValuesType = {
  documentMetadataUrl: '',
  clientId: '',
  clientSecret: '',
  secretExpireAt: null,
};

export { idpFormInitialValues };
export type { IdpFormInitialValuesType };
