import { useEffect } from 'react';

import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import {
  BrowserAuthError,
  ClientAuthError,
  InteractionRequiredAuthError,
  InteractionType,
} from '@azure/msal-browser';

import { loginRequest } from 'authConfig';
import { getUserRole } from 'shared/lib';
import { MainLayout, PublicLayout } from 'widgets';
import { routesMap } from 'shared/constants';

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const authenticated = useIsAuthenticated();

  const { login, error } = useMsalAuthentication(InteractionType.Silent, {
    ...loginRequest,
    redirectUri: '/redirect',
  });

  const { accounts } = useMsal();
  const currentAccount = accounts[0];
  const currentUserRole = getUserRole(currentAccount);

  useEffect(() => {
    if (error) {
      if (
        error instanceof InteractionRequiredAuthError ||
        error instanceof ClientAuthError ||
        error instanceof BrowserAuthError
      ) {
        login(InteractionType.Redirect, {
          ...loginRequest,
          redirectUri: '/redirect',
        });
        return;
      }

      navigate(routesMap.error.path, { state: { error } });
    }
  }, [error]);

  if (!authenticated) {
    return <PublicLayout />;
  }

  if (currentUserRole === 'regularUser' && currentAccount) {
    localStorage.clear();

    return (
      <Navigate
        to={routesMap.error.path}
        state={{
          customErrorMessage:
            "You don't have permission to use this application",
        }}
      />
    );
  }

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export { ProtectedRoute };
