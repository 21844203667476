import queryString from 'query-string';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

import { getAccessToken } from 'shared/lib';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: async headers => {
    const token = await getAccessToken();

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
  paramsSerializer: params =>
    queryString.stringify(params, { arrayFormat: 'none' }),
});

const baseQueryWrapper: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result?.error && result?.error?.status === 401) {
    localStorage.clear();
    window.location.href = '/';
  }

  return result;
};

export { baseQueryWrapper as baseQuery };
