import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { SubscriptionOptionsDropdown, InviteUserModal } from 'features';
import { Chip, IconButton, Skeleton, CustomTooltip } from 'shared/ui';
import { getErrorMessage } from 'shared/lib';
import {
  CustomerSummaryResponse,
  useInviteSubscriptionUserMutation,
} from 'shared/api/subscriptions';
import { ReactComponent as PlusIcon } from 'shared/icons/plus_icon.svg';
import { InviteUserType, Statuses } from 'shared/ts';

interface CustomerActionsProps {
  customerId: string;
  customer?: CustomerSummaryResponse;
  isLoading: boolean;
}

const CustomerActions: FC<CustomerActionsProps> = ({
  customer,
  customerId,
  isLoading,
}) => {
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [inviteCustomerUser] = useInviteSubscriptionUserMutation();

  const canInviteUsers =
    customer?.status === Statuses.ACTIVE && !customer?.reachedUserLimit;

  const handleInviteUserModalConfirm = async (invitation: InviteUserType) => {
    setIsInviteUserModalOpen(false);
    try {
      await inviteCustomerUser({
        subscriptionId: customerId,
        ...invitation,
      }).unwrap();

      toast.success(`${invitation.givenName} has been invited`);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  if (isLoading || !customer) {
    return (
      <div className="flex items-center gap-x-[12px]">
        <Skeleton className="w-[96px] h-[44px]" />
        <Skeleton className="w-[48px] h-[48px] rounded-[10px]" />
        <Skeleton className="w-[48px] h-[48px] rounded-[10px]" />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-[12px]">
      <Chip
        status={customer.status}
        isDemo={customer.demo}
        isExpired={customer.expired}
      />
      <SubscriptionOptionsDropdown id={customerId} subscription={customer} />
      <IconButton
        icon={<PlusIcon />}
        color="primary"
        onClick={() => setIsInviteUserModalOpen(true)}
        disabled={!canInviteUsers}
        data-tooltip-id="inviteUser"
      />
      <CustomTooltip
        id="inviteUser"
        content="Invite user"
        place="bottom-start"
      />
      <InviteUserModal
        organizationId={customerId}
        isOpen={isInviteUserModalOpen}
        onClose={() => setIsInviteUserModalOpen(false)}
        onConfirm={handleInviteUserModalConfirm}
      />
    </div>
  );
};

export { CustomerActions };
