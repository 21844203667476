import { FC, ReactNode } from 'react';

type AvatarProps = {
  size?: 'sm' | 'md' | 'lg';
} & (
  | {
      src: string;
      alt: string;
      children?: never;
    }
  | {
      src?: never;
      alt?: never;
      children: ReactNode;
    }
);

const iconSizeMap = {
  sm: 'w-[16px] h-[16px]',
  md: 'w-[24px] h-[24px]',
  lg: 'w-[44px] h-[44px]',
};

const Avatar: FC<AvatarProps> = ({ size = 'lg', src, alt, children }) => {
  return (
    <div className={iconSizeMap[size]}>
      {src && (
        <img
          src={src}
          alt={alt}
          className="w-full h-full object-cover rounded-full"
        />
      )}

      {children && (
        <div className="w-full h-full bg-gray-300 rounded-full flex items-center justify-center">
          <span className="text-xl font-semibold text-gray-600">
            {children}
          </span>
        </div>
      )}
    </div>
  );
};

export { Avatar };
