import {
  ComponentPropsWithRef,
  FocusEvent,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  MouseEvent,
} from 'react';

import { cn } from 'shared/lib';

import { Icon } from '../Icon/Icon';

import { FieldWrapper } from '../FieldWrapper/FieldWrapper';

interface InputProps extends ComponentPropsWithRef<'input'> {
  fullWidth?: boolean;
  label?: string;
  error?: boolean;
  helperText?: ReactNode;
  isShowTextAmount?: boolean;
  inputClassName?: string;
  icon?: ReactNode;
  iconClassName?: string;
  wrapperClassName?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      required,
      className,
      label,
      icon,
      helperText,
      disabled,
      fullWidth,
      isShowTextAmount,
      maxLength,
      iconClassName,
      error,
      inputClassName,
      wrapperClassName,
      onFocus,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [isFocused, setIsFocused] = useState(false);

    const inputValue = inputRef.current?.value;
    const hasInputValue = !!inputValue;

    const handleWrapperMouseDown = (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }

      if (!isFocused) {
        inputRef.current?.focus();
      }

      const inputClicked = event.target === inputRef.current;

      if (!inputClicked) {
        event.preventDefault();
      }
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);

      if (onFocus) {
        onFocus(event);
      }
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);

      if (onBlur) {
        onBlur(event);
      }
    };

    useImperativeHandle(ref, () => inputRef.current!, []);

    return (
      <FieldWrapper
        isFocused={isFocused}
        onWrapperMouseDown={handleWrapperMouseDown}
        fullWidth={fullWidth}
        label={label}
        required={required}
        childrenContainerClassName={className}
        wrapperClassName={wrapperClassName}
        isShowTextAmount={isShowTextAmount}
        helperText={helperText}
        error={error}
        disabled={disabled}
        maxLength={maxLength}
        inputValue={inputValue}
      >
        {icon && (
          <Icon
            icon={icon}
            className={cn(
              'fill-black-50 group-hover:fill-main-50 duration-300 mr-[8px] cursor-default',
              {
                'fill-main-20 group-hover:fill-main-20':
                  isFocused || hasInputValue,
              },
              {
                ...(error && {
                  'group-hover:fill-error-20': true,
                  'fill-error-20': isFocused || hasInputValue,
                }),
              },
              {
                'fill-zinc-400 group-hover:fill-zinc-400': disabled,
              },
              iconClassName,
            )}
            size="lg"
            disabled={disabled}
          />
        )}
        <input
          type="text"
          id={`id-${label}`}
          ref={inputRef}
          disabled={disabled}
          onBlur={handleBlur}
          onFocus={handleFocus}
          maxLength={maxLength}
          className={cn(
            'bg-transparent flex-grow placeholder:text-black-50',
            inputClassName,
          )}
          {...props}
        />
      </FieldWrapper>
    );
  },
);

Input.displayName = 'Input';
export { Input };
