import { FC } from 'react';

import { cn } from 'shared/lib';

interface CustomDatePickerDayProps {
  dayOfMonth: number;
  date?: Date;
  firstDayInTheNextMonth: Date;
  lastDayInTheCurrentMonth: Date;
  isDoublePicker: boolean;
}

const CustomDatePickerDay: FC<CustomDatePickerDayProps> = ({
  dayOfMonth,
  date,
  firstDayInTheNextMonth,
  lastDayInTheCurrentMonth,
  isDoublePicker,
}) => {
  const dayClass = cn(
    'flex test justify-center items-center w-[32px] h-[32px] relative z-[1]',
    {
      ...(isDoublePicker && {
        firstDayInTheNextMonth:
          firstDayInTheNextMonth.toDateString() === date?.toDateString(),
        lastDayInTheCurrentMonth:
          lastDayInTheCurrentMonth.toDateString() === date?.toDateString(),
      }),
    },
  );

  return (
    <div className={dayClass}>
      <span>{dayOfMonth}</span>
    </div>
  );
};

export { CustomDatePickerDay };
