import { useState } from 'react';
import { InfoBlock, PageLayout } from 'features';
import { useNavigate, useParams } from 'react-router';
import { formatStringToDate } from 'shared/lib';

import { Chip, Table, TableHeaderType } from 'shared/ui';
import { SortOrder, SortParamsType } from 'shared/ts';
import {
  CustomersItem,
  useGetCustomersQuery,
  useGetResellerSummaryQuery,
} from 'shared/api/subscriptions';

import { ResellerCustomerListActions } from './ResellerCustomerListActions';
import { ResellerCustomerListFilterValues } from './resellerCustomerList.types';

const PAGE_SIZE = 15;

const headers: TableHeaderType[] = [
  {
    title: 'Organization',
    field: 'name',
    render: (item: CustomersItem) => (
      <div className="flex flex-col gap-y-[8px]">
        <p className="text-12 text-black-40">
          Contract number: {item.contractNumber}
        </p>
        <p>{item.name}</p>
      </div>
    ),
  },
  {
    title: 'Phone',
    field: 'phone',
    canSort: false,
  },
  {
    title: 'Industry',
    field: 'industry',
  },
  {
    title: 'Valid period',
    field: 'expiresAt',
    render: (item: CustomersItem) => (
      <div className="flex flex-col gap-y-[8px]">
        <p className="text-12 text-black-40">
          From {formatStringToDate(item.createdAt)}
        </p>
        <p className="font-medium text-black">
          To {formatStringToDate(item.expiresAt)}
        </p>
      </div>
    ),
  },
  {
    title: 'Status',
    field: 'status',
    className: 'w-[167px]',
    render: (item: CustomersItem) => (
      <Chip status={item.status} isDemo={item.demo} isExpired={item.expired} />
    ),
  },
];

const defaultSortParams = {
  sortBy: 'createdAt',
  sortOrder: SortOrder.DESC,
};

const defaultStatuses = ['1', '2', '3', '4'];

const ResellerCustomerList = () => {
  const params = useParams();
  const resellerId = params.resellerId ?? '';

  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [sortParams, setSortParams] =
    useState<SortParamsType>(defaultSortParams);
  const [filterValues, setFilterValues] =
    useState<ResellerCustomerListFilterValues>({
      statuses: [],
    });

  const { demo, statuses } = filterValues;

  const {
    data: customersData,
    isLoading: isCustomersLoading,
    isFetching: isCustomersFetching,
  } = useGetCustomersQuery({
    ...sortParams,
    type: 1,
    resellerId,
    managedBy: 2,
    pageNumber,
    pageSize: PAGE_SIZE,
    demo,
    statuses: statuses.length ? statuses : defaultStatuses,
  });

  const customers = customersData?.data;
  const totalCustomersRecord = customersData?.totalRecords ?? 0;
  const currentPage = customersData?.currentPage ?? pageNumber;

  const isTableLoaderVisible = isCustomersFetching || isCustomersLoading;

  const {
    data: reseller,
    isLoading: isResellerLoading,
    isFetching: isResellerFetching,
    error: resellerError,
  } = useGetResellerSummaryQuery({
    id: resellerId,
  });

  const formattedCreatedAt = formatStringToDate(reseller?.createdAt);
  const formattedExpiriesAt = formatStringToDate(reseller?.expiresAt);
  const isResellerLoaderVisible =
    isResellerLoading || isResellerFetching || !!resellerError;

  const handleSort = (sortParams: SortParamsType) => {
    setSortParams(sortParams);
  };

  const customerInfo = [
    { title: 'Contract number', value: reseller?.contractNumber },
    { title: 'Industry', value: reseller?.industry },
    { title: 'Phone', value: reseller?.phone },
    {
      title: 'Created at',
      value: `${formattedCreatedAt}`,
    },
    {
      title: 'Expiries At',
      value: `${formattedExpiriesAt}`,
    },
    {
      title: 'Limit of users',
      value: `${
        (reseller?.limitOfUsers ?? -1) >= 0
          ? reseller?.limitOfUsers
          : 'Unlimited'
      }`,
    },
  ];

  return (
    <PageLayout
      className="flex pt-[24px] flex-col gap-y-[24px]"
      headerActions={
        <ResellerCustomerListActions
          resellerId={resellerId}
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setPageNumber={setPageNumber}
        />
      }
      isChildRoute
    >
      <InfoBlock infoItems={customerInfo} isLoading={isResellerLoaderVisible} />
      <Table
        headers={headers}
        data={customers}
        isLoading={isTableLoaderVisible}
        onSort={handleSort}
        defaultSortParams={defaultSortParams}
        pagination={{
          currentPage,
          pageSize: PAGE_SIZE,
          total: totalCustomersRecord,
          onChange: setPageNumber,
        }}
        onRowAction={id => navigate(`${id}`)}
      />
    </PageLayout>
  );
};

export { ResellerCustomerList };
