import { FC } from 'react';
import { cn } from 'shared/lib';

interface RadioHelperTextProps {
  helperText: string;
  className?: string;
}

const RadioHelperText: FC<RadioHelperTextProps> = ({
  helperText,
  className,
}) => {
  return (
    <span
      className={cn(
        'block text-12 font-normal leading-[20px] font-inter text-black-50',
        className,
      )}
    >
      {helperText}
    </span>
  );
};

export { RadioHelperText };
