import { regExp } from 'shared/constants';
import * as Yup from 'yup';

const authStepSchema = Yup.object().shape({
  authenticationMethod: Yup.number(),
  identityProvider: Yup.string().when(['authenticationMethod'], {
    is: 2,
    then: schema => schema.required('IdentityProvider is required'),
    otherwise: schema => schema.notRequired(),
  }),
  approvedDomains: Yup.array()
    .of(Yup.string())
    .when(['authenticationMethod', 'requiresNoInvitationForApprovedDomains'], {
      is: (authMethod: number, requireNoInvitation: boolean) =>
        authMethod === 2 || (authMethod === 1 && requireNoInvitation),
      then: schema => schema.min(1, 'Enter at least one domain'),
      otherwise: schema => schema.notRequired(),
    }),
  requiresNoInvitationForApprovedDomains: Yup.boolean(),
  technicalContactEmail: Yup.string().when(['authenticationMethod'], {
    is: 2,
    then: schema =>
      schema
        .matches(regExp.email, 'Enter a valid email')
        .required('Technical contact email is required'),
    otherwise: schema => schema.notRequired(),
  }),
});

export { authStepSchema };
