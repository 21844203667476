import { FC, useState } from 'react';

import { cn } from 'shared/lib';
import { CustomTooltip, IconButton } from 'shared/ui';
import { ReactComponent as DocumentIcon } from 'shared/icons/document_icon.svg';
import { SubscriptionType } from 'shared/ts';

import { InfoBlockItemsSkeleton, NoteInput } from './ui';
import { InfoItemType } from './InfoBlock.types';

interface InfoProps {
  infoItems: InfoItemType[];
  className?: string;
  isLoading?: boolean;
  subscriptionType?: SubscriptionType;
  subscriptionId?: string;
  subscriptionNotes?: string;
}

const InfoBlock: FC<InfoProps> = ({
  infoItems,
  isLoading,
  subscriptionType,
  className,
  subscriptionId,
  subscriptionNotes,
}) => {
  const [isNoteVisible, setIsNoteVisible] = useState(false);

  const toggleIsNoteVisible = () => {
    setIsNoteVisible(!isNoteVisible);
  };

  const isRenderedNote = subscriptionId && subscriptionType;

  return (
    <div
      className={cn(
        'flex flex-col w-full p-6 bg-white border-[1px] min-h-[151px] border-grey-80 rounded-[15px]',
        className,
      )}
    >
      <div className="flex items-center w-full">
        {isLoading ? (
          <InfoBlockItemsSkeleton infoLength={infoItems.length} />
        ) : (
          <>
            <div className="w-full grid gap-x-[8px] gap-y-[12px] grid-cols-3 grid-rows-2">
              {infoItems.map(item => (
                <div key={item.title} className="h-[45px]">
                  <p className=" text-black-40 text-12">{item.title}</p>
                  <p className="text-black-30 font-semibold text-15 mt-[4px]">
                    {item.value}
                  </p>
                </div>
              ))}
            </div>

            {isRenderedNote && (
              <>
                <IconButton
                  icon={<DocumentIcon />}
                  onClick={toggleIsNoteVisible}
                  color="secondary"
                  data-tooltip-id="notes"
                  className="[&>svg]:fill-[#000]"
                />
                <CustomTooltip id="notes" content="Notes" place="left" />
              </>
            )}
          </>
        )}
      </div>
      {isRenderedNote && (
        <NoteInput
          isVisible={isNoteVisible}
          subscriptionId={subscriptionId}
          notes={subscriptionNotes}
          subscriptionType={subscriptionType}
        />
      )}
    </div>
  );
};

export { InfoBlock };
