import { FC, ReactNode, useRef, useEffect } from 'react';

import ModalWindow, { Props } from 'react-modal';

import { useClickOutside } from 'shared/hooks';
import { cn } from 'shared/lib';

import './style.css';

ModalWindow.setAppElement('#root');

interface ModalProps extends Props {
  toggleModal?: (isOpen: boolean) => void;
  customClassName?: string;
  children: ReactNode;
  contentClassName?: string;
}

const customStyles = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.50)',
    zIndex: 999,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    margin: '0 auto',
    outline: 'none',
  },
};

const Modal: FC<ModalProps> = ({
  children,
  isOpen = false,
  closeTimeoutMS = 200,
  ariaHideApp = false,
  customClassName = '',
  contentClassName = '',
  toggleModal,
  ...props
}) => {
  const modalRef = useRef(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isOpen) {
      const scrollWidth = Math.abs(
        document.body.clientWidth - window.innerWidth,
      );

      document.body.style.paddingRight = `${scrollWidth}px`;
      document.body.style.overflow = 'hidden';
    } else {
      timeoutRef.current = setTimeout(() => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
      }, closeTimeoutMS);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [isOpen]);

  useClickOutside(modalRef, () => {
    if (toggleModal) toggleModal(isOpen);
  });

  return (
    <ModalWindow
      isOpen={isOpen}
      style={customStyles}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={ariaHideApp}
      className={cn(
        'bg-white absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] min-w-[396px]',
        'rounded-[20px]',
        customClassName,
      )}
      {...props}
    >
      <div ref={modalRef} className={cn('p-[24px] h-full', contentClassName)}>
        {children}
      </div>
    </ModalWindow>
  );
};

export { Modal };
export type { ModalProps };
