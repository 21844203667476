import { FC } from 'react';
import { useFormikContext } from 'formik';

import { CustomDatePicker, Input, PhoneInput } from 'shared/ui';
import { cn, getExpirationDateValue } from 'shared/lib';

import { InformationInitialValuesType } from './informationInitialValues';

interface InformationStepFormProps {
  isReseller?: boolean;
  isModalView?: boolean;
}

const InformationStepForm: FC<InformationStepFormProps> = ({
  isReseller = false,
  isModalView,
}) => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    useFormikContext<InformationInitialValuesType>();

  const expireAtValue = getExpirationDateValue(values.expiresAt);

  const subscriptionTypeTextCapitalize = isReseller ? 'Reseller' : 'Customer';

  return (
    <div className={cn('w-full p-[24px]', { 'p-0': isModalView })}>
      {!isModalView && (
        <h3 className="mb-[32px] text-20 font-semibold text-black">{`${subscriptionTypeTextCapitalize} information`}</h3>
      )}

      <div className="flex flex-col gap-y-[12px]">
        <Input
          fullWidth
          label={`Name of the ${subscriptionTypeTextCapitalize}`}
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.name && touched.name}
          helperText={(touched.name && errors.name) || ' '}
          autoFocus
        />

        <Input
          fullWidth
          label="Industry"
          name="industry"
          value={values.industry}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.industry && touched.industry}
          helperText={(touched.industry && errors.industry) || ' '}
        />

        <PhoneInput
          fullWidth
          label="Phone number"
          placeholder="+xx xxx xxx xxx"
          name="phone"
          value={values.phone}
          onChange={phoneValue => setFieldValue('phone', phoneValue)}
          onBlur={handleBlur}
          error={!!errors.phone && touched.phone}
          helperText={(touched.phone && errors.phone) || ' '}
        />

        <CustomDatePicker
          name="expiresAt"
          isDoublePicker={false}
          selectsRange={false}
          value={expireAtValue}
          label="Expire at"
          onChange={date => setFieldValue('expiresAt', date[0])}
          onBlur={handleBlur}
          error={!!errors.expiresAt && touched.expiresAt}
          helperText={(touched.expiresAt && errors.expiresAt) || ' '}
        />

        <div
          className={cn('flex flex-col gap-y-[12px]', {
            'flex-row w-full justify-between gap-x-[16px]': isModalView,
          })}
        >
          <Input
            fullWidth
            label="Account number"
            name="accountNumber"
            className={cn({ 'max-w-[275px]': isModalView })}
            value={values.accountNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.accountNumber && touched.accountNumber}
            helperText={(touched.accountNumber && errors.accountNumber) || ' '}
          />
          <Input
            fullWidth
            label="Contract number"
            name="contractNumber"
            className={cn({ 'max-w-[275px]': isModalView })}
            value={values.contractNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.contractNumber && touched.contractNumber}
            helperText={
              (touched.contractNumber && errors.contractNumber) || ' '
            }
          />
        </div>
      </div>
    </div>
  );
};

export { InformationStepForm };
