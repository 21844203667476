type ServicesFormFeaturesType = {
  featureId: string;
  productId: string;
};

interface ServicesInitialValuesType {
  demo: boolean;
  dataStorageRegion: string;
  products: string[];
  features: ServicesFormFeaturesType[];
  industry?: string;
}

const servicesInitialValues: ServicesInitialValuesType = {
  demo: false,
  dataStorageRegion: '',
  products: [],
  features: [],
};

export { servicesInitialValues };
export type { ServicesInitialValuesType, ServicesFormFeaturesType };
