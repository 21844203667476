import { FC, ReactNode } from 'react';

import { SortParamsType } from 'shared/ts';
import { ReactComponent as ChevronRightIcon } from 'shared/icons/chevron_right_icon.svg';

import { Button, Icon, Scrollbar, Loader } from 'shared/ui';
import { cn } from 'shared/lib';

import { TableHeaderType, TablePaginationType } from './table.types';

import { TableHeader, Pagination, ActionDropdown } from './ui';

const defaultTextForEmptyState = 'There are no items';

interface TableProps {
  headers: TableHeaderType[];
  data: any[] | undefined;
  onRowAction?: (id: number | string) => void;
  onSort?: (sortParams: SortParamsType) => void;
  renderActions?: (row: any) => ReactNode;
  isLoading?: boolean;
  defaultSortParams?: SortParamsType;
  pagination?: TablePaginationType;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
}

const Table: FC<TableProps> = ({
  headers,
  data,
  isLoading,
  onSort,
  renderActions,
  defaultSortParams,
  pagination,
  onRowAction,
  emptyStateTitle,
  emptyStateSubtitle,
}) => {
  const isFirstLoad = !data && isLoading;

  const isActionShow = !!(renderActions || onRowAction);

  return (
    <div className="w-full relative border-[1px] overflow-hidden rounded-[20px] border-gray-80">
      {isLoading && (
        <Loader
          withText={isFirstLoad}
          className={cn({
            'bg-grey-50 bg-opacity-30': data && isLoading,
          })}
        />
      )}
      <Scrollbar height="75vh" trackVerticalClassName="pt-[52px] pb-[10px]">
        <table className="w-full border-collapse">
          <thead
            className={cn('bg-main-90 sticky z-[2] top-0 margin-0', {
              hidden: isFirstLoad,
            })}
          >
            <TableHeader
              headers={headers}
              isActionShow={isActionShow}
              defaultSortParams={defaultSortParams}
              onSort={onSort}
            />
          </thead>

          <tbody>
            {data?.map((item, rowIndex, initialArray) => (
              <tr
                key={item.id}
                className={cn('bg-main-99 border-b-[1px] border-grey-80 ', {
                  'bg-white': (rowIndex + 1) % 2 > 0,
                  'cursor-pointer hover:bg-grey-80': onRowAction,
                })}
                onClick={() => onRowAction && onRowAction(item.id)}
              >
                {headers.map(header => (
                  <td
                    key={header.field}
                    className={cn(
                      'px-[16px] py-[22px] text-15 text-black-40 first:text-black first:font-semibold',
                      header.className,
                    )}
                  >
                    {header.render
                      ? header.render(item)
                      : item[header.field] || '-'}
                  </td>
                ))}
                {isActionShow && (
                  <td className="w-[52px]">
                    <div className="flex justify-start pr-[16px]">
                      {onRowAction ? (
                        <Button className="[&>svg]:fill-main bg-transparent rounded-full">
                          <Icon icon={<ChevronRightIcon />} size="lg" />
                        </Button>
                      ) : (
                        <ActionDropdown
                          initialArray={initialArray}
                          rowIndex={rowIndex}
                        >
                          {renderActions && renderActions(item)}
                        </ActionDropdown>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))}
            {data?.length === 0 && (
              <tr>
                <td colSpan={headers.length} className="h-[calc(75vh_-_50px)]">
                  <div className="flex w-full flex-col items-center gap-y-2 text-center">
                    <p className="text-20">
                      {emptyStateTitle || defaultTextForEmptyState}
                    </p>
                    {emptyStateSubtitle && (
                      <p className="text-14 text-grey-40 max-w-[314px]">
                        {emptyStateSubtitle}
                      </p>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Scrollbar>

      {pagination && <Pagination pagination={pagination} />}
    </div>
  );
};

export { Table };
