import { useMsal } from '@azure/msal-react';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { useActivateSubscriptionMutation } from 'shared/api/subscriptions';
import { getErrorMessage, hasUserPermissions } from 'shared/lib';
import { StatusType, Statuses, Permissions } from 'shared/ts';
import { Button, Loader } from 'shared/ui';

interface SubscriptionStatusInfoBlockProps {
  isReseller?: boolean;
  id: string;
  status: StatusType | undefined;
  isLoading: boolean;
  name: string | undefined;
}

const SubscriptionStatusInfoBlock: FC<SubscriptionStatusInfoBlockProps> = ({
  isReseller,
  status,
  id,
  name,
  isLoading,
}) => {
  const [activateSubscription] = useActivateSubscriptionMutation();

  const { accounts } = useMsal();
  const currentAccount = accounts[0];

  const isShowActiveButton =
    status === Statuses.READY_FOR_ACTIVATION &&
    hasUserPermissions(currentAccount, Permissions.ManageIdpSettings);

  const subscriptionTypeText = isReseller ? 'reseller' : 'customer';
  const subscriptionTypeTextCapitalize = isReseller ? 'Reseller' : 'Customer';
  const readyForActivationDescription = isShowActiveButton
    ? `You can activate the ${subscriptionTypeText} once the IDP configuration is done`
    : `The engineering team will activate the ${subscriptionTypeText} once the IDP configuration is done`;

  const isReadyForActivate = status === Statuses.READY_FOR_ACTIVATION;
  const title = isReadyForActivate
    ? `Activate ${subscriptionTypeTextCapitalize}`
    : `Waiting for ${subscriptionTypeText}'s IDP data`;

  const description = isReadyForActivate
    ? readyForActivationDescription
    : `We are waiting for the ${subscriptionTypeText} to send their IDP data for the SSO configuration`;

  const handleSubscriptionActivate = async () => {
    try {
      await activateSubscription({ id }).unwrap();
      toast.success(
        `${subscriptionTypeTextCapitalize} ${name} has been activated`,
      );
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  return (
    <div className="relative flex w-full h-[55vh] items-center justify-center">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex max-w-[434px] items-center flex-col bg-white p-6 rounded-[15px] border-grey-80 border-[1px] text-center">
          <p className="mb-3 text-24 font-medium text-[#000000]">{title}</p>

          <p className="text-black-40 text-16">{description}</p>

          {isShowActiveButton && (
            <Button
              color="primary"
              className="mt-6"
              onClick={handleSubscriptionActivate}
            >
              Activate
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export { SubscriptionStatusInfoBlock };
