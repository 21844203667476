import { FC, useMemo } from 'react';

import { Table, TablePaginationType, TableHeaderType } from 'shared/ui';
import {
  AccessReqActionParams,
  AccessRequestType,
  SortParamsType,
} from 'shared/ts';
import { DateCell } from 'features';

import { RequestsTableActionsCell } from './ui';

interface RequestsTableProps {
  onAccessReqApprove: (
    accessReqActionParams: Omit<AccessReqActionParams, 'subscriptionId'>,
    request: AccessRequestType,
  ) => void;
  onAccessReqDeny: (
    accessReqActionParams: Omit<AccessReqActionParams, 'subscriptionId'>,
    request: AccessRequestType,
  ) => void;
  requests?: AccessRequestType[];
  onSort?: (sortParams: SortParamsType) => void;
  defaultSortParams?: SortParamsType;
  isLoading: boolean;
  pagination?: TablePaginationType;
}

const RequestsTable: FC<RequestsTableProps> = ({
  requests,
  onSort,
  defaultSortParams,
  isLoading,
  pagination,
  onAccessReqDeny,
  onAccessReqApprove,
}) => {
  const headers: TableHeaderType[] = useMemo(
    () => [
      {
        title: 'User',
        field: 'user',
        render: (request: AccessRequestType) => (
          <div className="flex flex-col gap-y-[8px]">
            <p className="text-12  text-black-40">{request.email}</p>
            <p className="max-w-[470px] truncate">
              {request.givenName} {request.surname}
            </p>
          </div>
        ),
      },

      {
        title: 'Requested at',
        field: 'requestedAt',
        render: (request: AccessRequestType) => (
          <DateCell date={request.createdAt} />
        ),
      },
      {
        title: 'Actions',
        field: 'actions',
        className: 'flex justify-end',
        render: (request: AccessRequestType) => (
          <RequestsTableActionsCell
            request={request}
            onAccessReqApprove={onAccessReqApprove}
            onAccessReqDeny={onAccessReqDeny}
          />
        ),
        canSort: false,
      },
    ],
    [],
  );

  return (
    <Table
      headers={headers}
      data={requests}
      isLoading={isLoading}
      emptyStateTitle="There are no access requests"
      emptyStateSubtitle="Here we are going to show you the users who will try to gain access."
      onSort={onSort}
      defaultSortParams={defaultSortParams}
      pagination={pagination}
    />
  );
};

export { RequestsTable };
