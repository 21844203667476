import { FC } from 'react';

import { Icon, Skeleton } from 'shared/ui';
import { ReactComponent as ChevronLeftIcon } from 'shared/icons/chevron_left_icon.svg';

import { CrumbSkeleton } from './CrumbSkeleton';

interface HeaderSkeletonProps {
  matchesLength: number;
  isChildRoute?: boolean;
}

const HeaderSkeleton: FC<HeaderSkeletonProps> = ({
  matchesLength,
  isChildRoute,
}) => {
  return (
    <div className="flex gap-x-[4px] items-center flex-1">
      {isChildRoute && (
        <Skeleton className="w-[48px] h-[48px] rounded-full mr-[16px]" />
      )}
      <div className="flex flex-col">
        {isChildRoute && (
          <div className="flex mb-[4px] items-center gap-x-[4px]">
            {Array.from({ length: matchesLength }).map((_, index) => (
              <div className="flex h-5 items-center" key={index}>
                <CrumbSkeleton />

                {index < matchesLength - 1 && (
                  <Icon
                    icon={<ChevronLeftIcon />}
                    size="sm"
                    className="fill-grey-70 rotate-180"
                  />
                )}
              </div>
            ))}
          </div>
        )}

        <Skeleton className="w-[155px] h-[40px]" />
      </div>
    </div>
  );
};

export { HeaderSkeleton };
