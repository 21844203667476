import { FC } from 'react';
import { ListItem } from 'features';
import { ReactComponent as LockIcon } from 'shared/icons/lock_icon.svg';
import { ReactComponent as EditIcon } from 'shared/icons/edit_icon.svg';
import { ReactComponent as UserRemoveIcon } from 'shared/icons/user_remove_icon.svg';
import { Divider } from 'shared/ui';
import { UserType } from 'shared/ts';

interface UsersTableActionsProps {
  user: UserType;
  onDeleteClick: (user: UserType) => void;
  onEditClick: (user: UserType) => void;
  onActivateClick: (user: UserType) => void;
  onInactivateClick: (user: UserType) => void;
}

const UsersTableActions: FC<UsersTableActionsProps> = ({
  user,
  onEditClick,
  onActivateClick,
  onInactivateClick,
  onDeleteClick,
}) => {
  const handleUserDelete = () => {
    onDeleteClick(user);
  };

  const handleUserInactivate = () => {
    onInactivateClick(user);
  };

  const handleUserEdit = () => {
    onEditClick(user);
  };

  const handleUserActivate = () => {
    onActivateClick(user);
  };

  return (
    <div>
      <p className="px-[8px] py-[12px] font-poppins text-13 text-black-40">
        Users settings
      </p>
      <div className="w-[248px] flex flex-col">
        <ListItem
          onClick={handleUserEdit}
          color="default"
          text="Edit"
          icon={<EditIcon />}
        />

        {user.active ? (
          <ListItem
            onClick={handleUserInactivate}
            color="default"
            text="Inactivate"
            icon={<LockIcon />}
          />
        ) : (
          <ListItem
            onClick={handleUserActivate}
            color="default"
            text="Activate"
            icon={<EditIcon />}
          />
        )}

        <Divider className="bg-grey-80 mr-[-1px] mb-2" />
        <ListItem
          onClick={handleUserDelete}
          color="destructive"
          text="Delete"
          icon={<UserRemoveIcon />}
        />
      </div>
    </div>
  );
};

export { UsersTableActions };
