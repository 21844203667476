interface InformationInitialValuesType {
  name: string;
  industry: string;
  expiresAt: Date | null;
  phone: string;
  accountNumber: string;
  contractNumber: string;
}

const informationInitialValues: InformationInitialValuesType = {
  name: '',
  industry: '',
  expiresAt: null,
  phone: '',
  accountNumber: '',
  contractNumber: '',
};

export { informationInitialValues };
export type { InformationInitialValuesType };
