import { Dispatch, FC, SetStateAction } from 'react';

import { Filter, FilterDataType, SearchBar } from 'features';

import {
  UsersTableControlsValue,
  UsersTableFilters,
} from '../usersTable.types';

import { UsersTableControlsSkeleton } from './UsersTableControlsSkeleton';

const filterData: FilterDataType[] = [
  {
    name: 'status',
    title: 'Status',
    type: 'checkbox',
    options: [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '2' },
    ],
  },
  {
    name: 'type',
    title: 'Type of user',
    type: 'radio',
    options: [
      { label: 'All users', value: undefined },
      {
        label: 'Super users',
        value: '1',
      },
      {
        label: 'Regular users',
        value: '2',
      },
    ],
  },
];

interface UsersTableControlsProps {
  controlValues: UsersTableControlsValue;
  onChange: Dispatch<SetStateAction<UsersTableControlsValue>>;
  isLoading?: boolean;
}

const UsersTableControls: FC<UsersTableControlsProps> = ({
  controlValues,
  onChange,
  isLoading,
}) => {
  const { filters, search } = controlValues;

  if (isLoading) {
    return <UsersTableControlsSkeleton />;
  }

  return (
    <div className="flex gap-x-[12px]">
      <SearchBar
        data={undefined}
        query={search}
        onQueryChange={query => onChange({ ...controlValues, search: query })}
      />
      <Filter<UsersTableFilters>
        data={filterData}
        selectedOptions={filters}
        onChange={optionValue =>
          onChange({ ...controlValues, filters: optionValue })
        }
      />
    </div>
  );
};

export { UsersTableControls };
