const SortOrder = {
  DESC: 'desc',
  ASC: 'asc',
} as const;

type SortParamsType = {
  sortBy?: string;
  sortOrder?: typeof SortOrder.DESC | typeof SortOrder.ASC;
};

export { SortOrder };
export type { SortParamsType };
