import { ComponentPropsWithRef, forwardRef, ChangeEvent } from 'react';

import { Input } from 'shared/ui';

import { formatPhoneNumber } from './lib';

const NUMBER_REGEX = /[^\d+\s]*$/g;

interface PhoneInputProps
  extends Omit<ComponentPropsWithRef<typeof Input>, 'value' | 'onChange'> {
  value: string;
  onChange: (phoneValue: string) => void;
}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ value, onChange, ...props }, ref) => {
    const handlePhoneInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const filteredValue = value.replace(NUMBER_REGEX, '');

      const formattedValue = formatPhoneNumber({ value: filteredValue });

      onChange(formattedValue);
    };

    return (
      <Input
        ref={ref}
        value={value}
        onChange={handlePhoneInputChange}
        {...props}
      />
    );
  },
);

PhoneInput.displayName = 'PhoneInput';
export { PhoneInput };
