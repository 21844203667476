import { configureStore } from '@reduxjs/toolkit';

import {
  subscriptionsApi,
  productsApi,
  myOrganizationApi,
  idpApi,
  rolesApi,
} from 'shared/api';

import { rootReducer } from './rootReducer';

const createStore = () => {
  const apiMiddleware = [
    subscriptionsApi.middleware,
    productsApi.middleware,
    myOrganizationApi.middleware,
    idpApi.middleware,
    rolesApi.middleware,
  ];

  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(...apiMiddleware),
  });

  return store;
};

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
