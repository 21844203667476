import { ComponentPropsWithRef, ReactNode, forwardRef } from 'react';

import { cn } from 'shared/lib';

import { Icon } from '../Icon/Icon';

import { Color, Size } from './iconButton.types';

const buttonColorMap = {
  primary: `[&>svg]:fill-white bg-main
      hover:bg-[#1E65D9] 
      active:bg-[#1A59BF] 
      focus-visible:bg-[#1E65D9] focus-visible:outline-main-80 focus-visible:outline-2`,

  outline: `[&>svg]:fill-main border-[1px] border-main 
    hover:bg-main-99 [&>svg]:hover:fill-[#1E65D9] hover:border-[#1E65D9] hover:text-[#1E65D9] 
    [&>svg]:active:fill-[#1A59BF] active:border-[#1A59BF] active:text-[#1A59BF] active:bg-main-90 
    focus-visible:outline-main-90 focus-visible:outline-2`,

  secondary: `[&>svg]:fill-main-50 bg-main-99 border-[1px] border-main-80 
    hover:bg-main-90 [&>svg]:hover:fill-main-keesing 
    [&>svg]:active:fill-main-20 active:border-main active:bg-main-80 
    focus-visible:outline-main-80 focus-visible:outline-2`,

  ghost: `[&>svg]:fill-main-50 bg-transparent border-[1px] border-transparent
    hover:border-[#C7DBFC] 
    active:[&>svg]:fill-main
    focus-visible:outline-[#C7DBFC]
  `,
};

const buttonSizeMap = {
  lg: 'w-[56px] h-[56px] p-[16px]',
  md: 'w-[48px] h-[48px] p-[12px]',
  sm: 'w-[40px] h-[40px] p-[8px]',
};

interface IconButtonProps extends ComponentPropsWithRef<'button'> {
  color?: Color;
  size?: Size;
  icon: ReactNode;
  fullWidth?: boolean;
  iconClassName?: string;
}

const disabledStyles = 'opacity-40 pointer-events-none';

const buttonBaseStyles =
  'inline-flex text-16 leading-[24px]  justify-center items-center text-center rounded-[10px] transition-colors duration-200';

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className = '',
      color,
      disabled,
      size = 'md',
      fullWidth,
      icon,
      iconClassName = '',
      ...props
    },
    ref,
  ) => {
    const buttonColor = color && buttonColorMap[color];

    const buttonSize = buttonSizeMap[size];

    const buttonClass = cn(
      buttonBaseStyles,
      buttonColor,
      buttonSize,
      disabled && disabledStyles,
      { 'w-full': fullWidth },
      className,
    );

    return (
      <button
        type="button"
        tabIndex={0}
        className={buttonClass}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        <Icon
          icon={icon}
          size="lg"
          className={cn('duration-200 transition-colors', iconClassName)}
          disabled={disabled}
        />
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';
export { IconButton };
