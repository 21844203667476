import { FC, ReactNode, useState } from 'react';

import { ReactComponent as MoreVerticalIcon } from 'shared/icons/more_vertical_icon.svg';
import { cn } from 'shared/lib';
import { Dropdown } from 'shared/ui';

interface ActionDropdownProps {
  children: ReactNode;
  rowIndex: number;
  initialArray: any[];
}

const ActionDropdown: FC<ActionDropdownProps> = ({
  children,
  rowIndex,
  initialArray,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const isLastRow =
    initialArray.length >= 5 &&
    (rowIndex + 1 === initialArray.length ||
      rowIndex + 2 === initialArray.length);

  return (
    <Dropdown
      isOpen={isOpen}
      onOpen={setIsOpen}
      closeOnOptionClick
      buttonContent={<MoreVerticalIcon />}
      positionY={isLastRow ? 'top' : 'bottom'}
      menuClassName={cn('z-[1]', {
        'translate-y-[-4px]': isOpen && isLastRow,
        'translate-y-[4px]': isOpen && !isLastRow,
      })}
      buttonProps={{
        color: undefined,
        className: cn(
          '[&>svg]:fill-main-50 h-[24px] outline-none bg-transparent border-none p-[0] rounded-full',
        ),
      }}
    >
      {children}
    </Dropdown>
  );
};

export { ActionDropdown };
