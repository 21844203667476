import { FC } from 'react';

import { format, getYear, startOfMonth } from 'date-fns';

import { IconButton } from 'shared/ui';
import { cn } from 'shared/lib';

import { ReactComponent as ChevronLeftIcon } from 'shared/icons/chevron_left_icon.svg';
import { ReactComponent as ChevronRightIcon } from 'shared/icons/chevron_right_icon.svg';

import { currentDate } from '../lib';

import { YearDropdown } from './YearDropdown';

interface CustomDatePickerHeaderProps {
  monthDate: Date;
  customHeaderCount: number;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  changeYear: (year: number) => void;
  nextMonthButtonDisabled: boolean;
  yearDropdownOptions: number[];
  startDate: Date | null;
  endDate: Date | null;
  isDoublePicker: boolean;
  selectsRange: boolean;
}

const CustomDatePickerHeader: FC<CustomDatePickerHeaderProps> = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  changeYear,
  yearDropdownOptions,
  nextMonthButtonDisabled,
  startDate,
  endDate,
  isDoublePicker,
  selectsRange,
}) => {
  const isPrevMonthButtonDisabled =
    startOfMonth(currentDate).toString() === startOfMonth(monthDate).toString();

  const isFirstMonthContainer = customHeaderCount === 0 && isDoublePicker;
  const isSecondMonthContainer = customHeaderCount === 1 && isDoublePicker;

  const visibleOptions = isDoublePicker ? 7 : 5;

  const selectedYear = getYear(monthDate);

  const fromValue = startDate ? format(startDate, 'E. dd.MM.yyyy') : '-';

  const toValue = endDate ? format(endDate, 'E. dd.MM.yyyy') : '-';

  return (
    <div>
      <div
        className={cn('flex items-center justify-between max-w-[320px]', {
          ...(isDoublePicker && {
            'ml-[24px] max-w-[308px]': isSecondMonthContainer,
            'mr-[24px] max-w-[308px]': isFirstMonthContainer,
          }),
        })}
      >
        <IconButton
          color="secondary"
          icon={<ChevronLeftIcon />}
          className={cn({ hidden: isSecondMonthContainer })}
          aria-label="Previous Month"
          disabled={isPrevMonthButtonDisabled}
          onClick={decreaseMonth}
        />

        <div className="w-[260px] flex flex-col items-center gap-y-[4px]">
          <YearDropdown
            selectedYear={selectedYear}
            onSelect={year => changeYear(year)}
            visibleOptions={visibleOptions}
            years={yearDropdownOptions}
          />
          <span className="text-main-20 text-16 font-semibold">
            {monthDate.toLocaleString('en-US', {
              month: 'long',
            })}
          </span>
        </div>

        <IconButton
          color="secondary"
          icon={<ChevronRightIcon />}
          className={cn({ hidden: isFirstMonthContainer })}
          aria-label="Next Month"
          disabled={nextMonthButtonDisabled}
          onClick={increaseMonth}
        />
      </div>
      {isDoublePicker && selectsRange && (
        <div
          className={cn(
            'flex flex-col relative w-[308px] gap-y-[8px] mt-[13px] text-left cursor-default',
            {
              'ml-auto': isSecondMonthContainer,
            },
          )}
        >
          <p className="text-black-50 text-14 leading-[20px] ">
            {isFirstMonthContainer ? 'From' : 'To'}
          </p>

          <div
            className={cn('relative', {
              'after:top-[50%] after:left-[-36px] after:content-[""] after:block after:absolute after:w-[24px] after:h-[2px] after:bg-main-90 after:rounded-full':
                isSecondMonthContainer,
            })}
          >
            <div
              className={cn(
                'text-black-40 rounded-[10px] text-16  gap-x-[16px] p-[16px] bg-main-99 border-[1px] border-main-80',
                'text-black-40 rounded-[10px] text-16  gap-x-[16px] p-[16px] bg-main-99 border-[1px] border-main-80',
              )}
            >
              {isFirstMonthContainer ? fromValue : toValue}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { CustomDatePickerHeader };
