import { RefObject } from 'react';

const moveCursorToEndOfTextArea = (
  textAreaRef: RefObject<HTMLTextAreaElement>,
  value?: string,
) => {
  const textAreaRefCurrent = textAreaRef.current;

  if (!textAreaRefCurrent) {
    return;
  }
  const textAreaValue = value || textAreaRefCurrent.value;

  textAreaRefCurrent.setSelectionRange(
    textAreaValue.length,
    textAreaValue.length,
  );
  textAreaRefCurrent.scrollTop = textAreaRefCurrent.scrollHeight;
};

export { moveCursorToEndOfTextArea };
