import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  RouteObject,
} from 'react-router-dom';

import { routesMap } from 'shared/constants';
import {
  Customers,
  Resellers,
  Customer,
  Reseller,
  ResellerCustomerList,
  CustomerBreadcrumb,
  ResellerBreadcrumb,
  AddNewCustomer,
  AddNewReseller,
  MyOrganization,
  MyOrganizationBreadcrumb,
  ErrorPage,
  IdpConfigurationPage,
} from 'pages';
import Signout from 'features/Signout';
import { ProtectedRoute } from './ProtectedRoute';
import { PrivateRoute } from './PrivateRoute';

const customersRoutes: RouteObject[] = [
  {
    index: true,
    element: <Customers />,
  },
  {
    path: ':customerId',
    element: <Customer />,
    handle: {
      crumb: <CustomerBreadcrumb />,
    },
  },
  {
    path: routesMap.addCustomer.path,
    element: <AddNewCustomer />,
    handle: {
      crumb: routesMap.addCustomer.title,
    },
  },
];

const resellersRoutes: RouteObject[] = [
  {
    index: true,
    element: <Resellers />,
  },
  {
    path: ':resellerId',
    handle: {
      crumb: <ResellerBreadcrumb />,
    },
    children: [
      {
        index: true,
        element: <Reseller />,
      },
      {
        path: 'customerList',
        handle: {
          crumb: 'Customers',
        },
        children: [
          {
            index: true,
            element: <ResellerCustomerList />,
          },
          {
            path: ':customerId',
            element: <Customer />,
            handle: {
              crumb: <CustomerBreadcrumb />,
            },
          },
        ],
      },
    ],
  },
  {
    path: routesMap.addReseller.path,
    element: <AddNewReseller />,
    handle: {
      crumb: routesMap.addReseller.title,
    },
  },
];

const publicRoutes: RouteObject[] = [
  {
    path: `${routesMap.idp.path}/:idpConfigId`,
    element: <IdpConfigurationPage />,
  },
  {
    path: routesMap.error.path,
    element: <ErrorPage />,
  },
];

const router = createBrowserRouter([
  {
    path: routesMap.home.path,
    element: <ProtectedRoute />,
    children: [
      {
        index: true,
        element: (
          <PrivateRoute userRoles={routesMap.home.userRoles}>
            <MyOrganization />
          </PrivateRoute>
        ),
        handle: {
          crumb: <MyOrganizationBreadcrumb />,
        },
      },
      {
        path: '/redirect',
        element: <div>redirect</div>,
      },
      {
        path: routesMap.customers.path,
        element: <PrivateRoute userRoles={routesMap.customers.userRoles} />,
        handle: {
          crumb: routesMap.customers.title,
        },
        children: customersRoutes,
      },
      {
        path: routesMap.resellers.path,
        handle: {
          crumb: routesMap.resellers.title,
        },
        element: <PrivateRoute userRoles={routesMap.resellers.userRoles} />,
        children: resellersRoutes,
      },
    ],
  },
  ...publicRoutes,
  { path: '*', element: <Navigate to="/" replace /> },
  { path: '/signout', element: <Signout /> },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export { AppRouter };
