import { FC, ReactNode } from 'react';

import { ReactComponent as LogoSmall } from 'shared/icons/logo_keesing_small.svg';
import { cn } from 'shared/lib';
import { Icon } from 'shared/ui';

interface PublicLayoutProps {
  children?: ReactNode;
  mainClassName?: string;
}

const PublicLayout: FC<PublicLayoutProps> = ({ children, mainClassName }) => {
  return (
    <div className="flex flex-col min-h-screen bg-main-90 p-[40px] pt-0">
      <header className="flex w-full py-[40px]">
        <Icon icon={<LogoSmall />} className="!fill-main-20 w-[180px] h-10" />
      </header>

      <main
        className={cn(
          'flex flex-grow min-h-full w-full items-center justify-center',
          mainClassName,
        )}
      >
        {children}
      </main>
    </div>
  );
};

export { PublicLayout };
