import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../baseQuey';

import { ProductsResponse } from './models';

export const productsApi = createApi({
  baseQuery,
  reducerPath: 'productsApi',
  tagTypes: ['products'],
  endpoints: builder => ({
    getProducts: builder.query<ProductsResponse, void>({
      query: () => ({
        url: '/api/products',
        method: 'GET',
      }),
      providesTags: ['products'],
    }),
  }),
});

export const { useGetProductsQuery } = productsApi;
