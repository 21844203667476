import { FC } from 'react';

import { Checkbox, Radio, Switch } from 'shared/ui';

import { FillerType, OptionType, SelectedOptionsType } from '../filter.types';

interface FilterOptionProps {
  selectedOptions: SelectedOptionsType;
  type: FillerType;
  option: OptionType;
  onRadioChange: (value: string | undefined, name: string) => void;
  onCheckboxChange: (
    value: string | undefined,
    name: string,
    isSelected: boolean,
  ) => void | null;
  onSwitchChange: (value: boolean, name: string) => void;
  name: string;
}

const FilterOption: FC<FilterOptionProps> = ({
  type,
  option,
  selectedOptions,
  onRadioChange,
  onCheckboxChange,
  onSwitchChange,
  name,
}) => {
  const currentOption = selectedOptions[name];

  const isSelected = Array.isArray(currentOption)
    ? currentOption.includes(option.value ?? '')
    : false;

  return (
    <div className="px-[8px] py-[12px]">
      {type === 'checkbox' && (
        <Checkbox
          labelClassName="text-16 text-black"
          startLabel={option.label}
          fullWidth
          checked={isSelected}
          onChange={() => onCheckboxChange(option.value, name, isSelected)}
        />
      )}
      {type === 'radio' && (
        <Radio
          labelClassName="text-16 text-black"
          startLabel={option.label}
          checked={option.value === currentOption}
          fullWidth
          onChange={() => onRadioChange(option.value, name)}
        />
      )}
      {type === 'switch' && (
        <Switch
          labelClassName="text-16 text-black"
          startLabel={option.label}
          checked={!!currentOption}
          onChange={() => onSwitchChange(!!currentOption, name)}
          fullWidth
        />
      )}
    </div>
  );
};

export { FilterOption };
