// Original error format: "...: ((Error message)); ..."

// This function extract the error message between the brackets
const formatErrorMessage = (errorMessage: string) => {
  const openBracketIndex = errorMessage.indexOf('(');
  const closeBracketIndex = errorMessage.indexOf(')');

  if (openBracketIndex === -1 || closeBracketIndex === -1) {
    return 'Something went wrong. Please contact your manager.';
  }

  return errorMessage.slice(openBracketIndex + 2, closeBracketIndex);
};

export { formatErrorMessage };
