import { FC, ComponentProps } from 'react';

import { cn } from 'shared/lib';

import './style.css';

interface SwitchProps extends Omit<ComponentProps<'input'>, 'size'> {
  startLabel?: string;
  endLabel?: string;
  labelClassName?: string;
  size?: 'small' | 'large';
  sliderClassName?: string;
  fullWidth?: boolean;
}

const Switch: FC<SwitchProps> = ({
  name,
  size = 'small',
  value,
  checked,
  endLabel,
  onChange,
  className,
  startLabel,
  defaultChecked,
  labelClassName,
  sliderClassName,
  fullWidth,
  ...props
}) => {
  const isSmallSize = size === 'small';

  return (
    <label
      className={cn(
        'switch-container inline-flex justify-between w-fit cursor-pointer text-16 text-black-30',
        { 'w-full': fullWidth },
        labelClassName,
      )}
    >
      {startLabel && startLabel}
      <input
        type="checkbox"
        className="switch-input sr-only"
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
        defaultChecked={defaultChecked}
        {...props}
      />
      <div
        className={cn(
          'switch-body relative flex p-[1px] items-center flex-shrink-0 bg-main-80 cursor-pointer transition-all duration-300 w-[64px] h-[32px] rounded-full',
          {
            'ml-[15px]': startLabel,
            'mr-[15px]': endLabel,
            'w-[48px] h-[24px]': isSmallSize,
          },

          className,
        )}
      >
        <div
          className={cn(
            'switch-thumb-large absolute w-[30px] h-[30px] bg-main-90 rounded-[50%] transition-all duration-300',
            { 'switch-thumb-small w-[22px] h-[22px]': isSmallSize },
            sliderClassName,
          )}
        />
      </div>
      {endLabel && endLabel}
    </label>
  );
};

export { Switch };
