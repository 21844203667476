import { FC, SVGAttributes, ReactSVGElement, cloneElement } from 'react';
import { cn } from 'shared/lib';

import { Color, Size } from './icon.types';

interface IconProps {
  size?: Size;
  icon: React.ReactNode;
  color?: Color;
  disabled?: boolean;
}

const iconSizeMap = {
  sm: 'w-[16px] h-[16px]',
  md: 'w-[18px] h-[18px]',
  lg: 'w-[24px] h-[24px]',
  xl: 'w-[32px] h-[32px]',
  xxl: 'w-[48px] h-[48px]',
};

const iconColorMap = {
  basic: 'fill-white',
  primary: 'fill-blue',
  secondary: 'fill-warning-40',
  success: 'fill-success',
  disabled: 'fill-grey-50',
  error: 'fill-error',
  black: 'fill-black',
};

const Icon: FC<SVGAttributes<ReactSVGElement> & IconProps> = ({
  icon,
  size = 'xl',
  color = 'basic',
  className,
  disabled,
  ...props
}) => {
  const iconSizeStyles = iconSizeMap[size];
  const iconColorStyles = disabled
    ? iconColorMap.disabled
    : iconColorMap[color];

  const Icon = cloneElement(icon as React.ReactElement, {
    className: cn(iconSizeStyles, iconColorStyles, className),
    ...props,
  });

  return Icon;
};

export { Icon };
