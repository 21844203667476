import { FC } from 'react';

import { useParams } from 'react-router';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { Button, CustomDatePicker, Input } from 'shared/ui';
import { ReactComponent as PlaceIcon } from 'shared/icons/plane_icon.svg';
import { useSendIdpConfigMutation } from 'shared/api/idp';
import { getErrorMessage, getExpirationDateValue } from 'shared/lib';

import {
  IdpFormInitialValuesType,
  idpFormInitialValues,
} from './IdpFormInitialValues';
import { IdpFormSchema } from './IdpFormSchema';

interface IdpFormProps {
  setIsIdpSent: (isIdpSent: boolean) => void;
}

const IdpForm: FC<IdpFormProps> = ({ setIsIdpSent }) => {
  const { idpConfigId } = useParams();

  const [sendIdpConfig] = useSendIdpConfigMutation();

  const handleSubmit = async (values: IdpFormInitialValuesType) => {
    try {
      await sendIdpConfig({ id: idpConfigId ?? '', ...values }).unwrap();
      setIsIdpSent(true);
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  const {
    handleSubmit: formikHandleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    values,
    isSubmitting,
  } = useFormik({
    initialValues: idpFormInitialValues,
    validationSchema: IdpFormSchema,
    onSubmit: handleSubmit,
  });

  const expirationDateValue = getExpirationDateValue(values.secretExpireAt);

  return (
    <form
      className="relative h-full w-[542px]"
      onSubmit={formikHandleSubmit}
      autoComplete="off"
    >
      <div className="flex flex-col gap-y-3 mb-4">
        <Input
          fullWidth
          label="Open ID Connect metadata URL"
          placeholder="https://oidc.domain.com/.well-known/openid-configuration"
          name="documentMetadataUrl"
          value={values.documentMetadataUrl}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.documentMetadataUrl && touched.documentMetadataUrl}
          helperText={
            (touched.documentMetadataUrl && errors.documentMetadataUrl) || ' '
          }
        />

        <Input
          fullWidth
          label="Client ID"
          placeholder="Enter the client ID"
          name="clientId"
          value={values.clientId}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.clientId && touched.clientId}
          helperText={(touched.clientId && errors.clientId) || ' '}
        />

        <Input
          fullWidth
          label="Client Secret"
          placeholder="Enter the secret"
          name="clientSecret"
          value={values.clientSecret}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.clientSecret && touched.clientSecret}
          helperText={(touched.clientSecret && errors.clientSecret) || ' '}
        />

        <CustomDatePicker
          name="secretExpireAt"
          placeholder="Select expiration date"
          label="Client secret expiration date"
          isDoublePicker={false}
          selectsRange={false}
          value={expirationDateValue}
          onChange={date => setFieldValue('secretExpireAt', date[0])}
          onBlur={handleBlur}
          error={!!errors.secretExpireAt && touched.secretExpireAt}
          helperText={(touched.secretExpireAt && errors.secretExpireAt) || ' '}
        />
      </div>

      <Button
        type="submit"
        color="primary"
        endIcon={<PlaceIcon />}
        disabled={isSubmitting}
        fullWidth
        size="lg"
      >
        Save
      </Button>
    </form>
  );
};

export { IdpForm };
