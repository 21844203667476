import { FieldSkeleton, Skeleton } from 'shared/ui';

const EditAuthAndUsersModalSkeleton = () => {
  return (
    <div className="w-full">
      <h3 className="mb-[32px] text-20 font-semibold text-black">
        Authentication Method
      </h3>

      <div className="flex flex-col gap-y-[16px]">
        <div className="flex gap-x-[8px]">
          <Skeleton className="rounded-full w-[24px] h-[24px]" />
          <Skeleton className="w-[171px] h-[24px]" />
        </div>
        <div className="flex gap-x-[8px]">
          <Skeleton className="rounded-full w-[24px] h-[24px]" />
          <div className="flex flex-col gap-y-[4px]">
            <Skeleton className="w-[165px] h-[24px]" />
            <Skeleton className="w-[257px] h-5" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-y-[24px] mt-[24px]">
        <FieldSkeleton withHelperText />
      </div>

      <div className="flex flex-col">
        <h3 className="my-[32px] text-20 font-semibold text-black">
          Limit of users
        </h3>
        <div className="flex flex-col gap-y-[16px]">
          <div className="flex gap-x-[8px]">
            <Skeleton className="rounded-full w-[24px] h-[24px]" />
            <Skeleton className="w-[171px] h-[24px]" />
          </div>
          <div className="flex gap-x-[8px]">
            <Skeleton className="rounded-full w-[24px] h-[24px]" />
            <Skeleton className="w-[171px] h-[24px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { EditAuthAndUsersModalSkeleton };
