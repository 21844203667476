import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../baseQuey';

import { SendIdpConfigParams } from './models';

export const idpApi = createApi({
  baseQuery,
  reducerPath: 'idpApi',
  endpoints: builder => ({
    sendIdpConfig: builder.mutation<void, SendIdpConfigParams>({
      query: ({ id, ...body }) => ({
        url: `/api/idp-config/${id}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useSendIdpConfigMutation } = idpApi;
