interface AmountOfUsersInitialValuesType {
  limitOfUsers: number;
}

const amountOfUsersInitialValues: AmountOfUsersInitialValuesType = {
  limitOfUsers: -1,
};

export { amountOfUsersInitialValues };
export type { AmountOfUsersInitialValuesType };
