import { useFormikContext } from 'formik';

import { Input } from 'shared/ui';

import { AmountOfUsersFormBlock } from '../AmountOfUsersFormBlock/AmountOfUsersFormBlock';

import { UsersInitialValuesType } from './usersInitialValues';

const UsersStepForm = () => {
  const { handleChange, handleBlur, values, errors, touched } =
    useFormikContext<UsersInitialValuesType>();

  return (
    <div className="w-full p-[24px]">
      <h3 className="mb-[32px] text-20 font-semibold text-black">
        Administrator user
      </h3>

      <div className="flex flex-col gap-y-[12px]">
        <Input
          name="superUser.name"
          fullWidth
          label="First name"
          value={values.superUser.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.superUser?.name && touched.superUser?.name}
          helperText={
            (touched.superUser?.name && errors.superUser?.name) || ' '
          }
          autoFocus
        />
        <Input
          name="superUser.surname"
          fullWidth
          label="Second name"
          value={values.superUser.surname}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.superUser?.surname && touched.superUser?.surname}
          helperText={
            (touched.superUser?.surname && errors.superUser?.surname) || ' '
          }
        />
        <Input
          name="superUser.email"
          fullWidth
          label="Email"
          value={values.superUser.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!errors.superUser?.email && touched.superUser?.email}
          helperText="An invitation will be sent to the super-user."
        />
      </div>

      <AmountOfUsersFormBlock />
    </div>
  );
};

export { UsersStepForm };
