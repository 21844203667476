import { FC } from 'react';

import { Filter, FilterDataType } from 'features';

import { ResellerCustomerListFilterValues } from './resellerCustomerList.types';
import { ResellerCustomerListSearchBar } from './ui';

const filterData: FilterDataType[] = [
  {
    name: 'statuses',
    title: 'Status',
    type: 'checkbox',
    options: [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '2' },
      { label: 'Waiting customer', value: '3' },
      { label: 'Ready for activation', value: '4' },
    ],
  },
  {
    name: 'demo',
    type: 'radio',
    title: 'Demo',
    options: [
      { label: 'All Customers', value: undefined },
      { label: 'Demo Customers', value: 'true' },
      { label: 'Regular Customers', value: 'false' },
    ],
  },
];

interface ResellerCustomerListActionsProps {
  resellerId: string;
  filterValues: ResellerCustomerListFilterValues;
  setFilterValues: (filterStatuses: ResellerCustomerListFilterValues) => void;
  setPageNumber: (page: number) => void;
}

const ResellerCustomerListActions: FC<ResellerCustomerListActionsProps> = ({
  resellerId,
  filterValues,
  setFilterValues,
  setPageNumber,
}) => {
  const handleFilterChange = (values: ResellerCustomerListFilterValues) => {
    setPageNumber(1);
    setFilterValues(values);
  };

  return (
    <div className="flex items-center gap-x-[12px]">
      <ResellerCustomerListSearchBar resellerId={resellerId} />
      <Filter<ResellerCustomerListFilterValues>
        data={filterData}
        selectedOptions={filterValues}
        onChange={handleFilterChange}
      />
    </div>
  );
};

export { ResellerCustomerListActions };
