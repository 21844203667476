import { FC, ReactElement } from 'react';
import { useMsal } from '@azure/msal-react';

import { getUserRole } from 'shared/lib';
import { Outlet, Navigate } from 'react-router';
import { UserRole } from 'shared/ts';
import { routesMap } from 'shared/constants';

interface PrivateRouteProps {
  userRoles: UserRole[];
  children?: ReactElement;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ userRoles, children }) => {
  const { accounts } = useMsal();

  const currentAccount = accounts[0];

  const currentUserRole = getUserRole(currentAccount);

  if (!userRoles.includes(currentUserRole)) {
    const isKeesingAdministrator = currentUserRole === 'keesingAdministrator';

    return (
      <Navigate
        to={
          isKeesingAdministrator
            ? routesMap.customers.path
            : routesMap.home.path
        }
        replace
      />
    );
  }

  return children || <Outlet />;
};

export { PrivateRoute };
