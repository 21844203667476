import { FC } from 'react';

import { cn } from 'shared/lib';

interface CheckboxHelperTextProps {
  helperText: string;
  className?: string;
}

const CheckboxHelperText: FC<CheckboxHelperTextProps> = ({
  helperText,
  className,
}) => {
  return (
    <span
      className={cn(
        'block text-12 font-normal font-inter text-black-50',
        className,
      )}
    >
      {helperText}
    </span>
  );
};

export { CheckboxHelperText };
