import { FC } from 'react';
import { useFormik } from 'formik';
import { RoleItem, useGetRolesQuery } from 'shared/api/roles';
import { Button, Icon, Input, Modal, Radio } from 'shared/ui';
import { ReactComponent as CheckIcon } from 'shared/icons/check_icon.svg';
import { ReactComponent as CloseIcon } from 'shared/icons/close_icon.svg';
import { UserType } from 'shared/ts';
import { getLoggedUserRole } from 'shared/lib/getLoggedUserRole';
import { editUserValidationSchema } from './EditUserValidationSchema';

interface EditUserModalProps {
  isOpen: boolean;
  user: UserType;
  onConfirm: (user: UserType) => void;
  onClose: () => void;
  organizationId: string;
}

const EditUserModal: FC<EditUserModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  user,
  organizationId,
}) => {
  const { data: roles, isLoading } = useGetRolesQuery({ id: organizationId });
  const loggedUserRole = getLoggedUserRole(roles);
  const { values, handleSubmit, setFieldValue } = useFormik<UserType>({
    initialValues: user,
    validationSchema: editUserValidationSchema,
    enableReinitialize: true,
    onSubmit: values => onConfirm(values),
  });

  const handleChangeRole = (role: RoleItem) => {
    setFieldValue('role', role);
    setFieldValue('roleId', role.id);
  };

  return (
    <Modal isOpen={isOpen} toggleModal={onClose}>
      <form className="w-[472px]" onSubmit={handleSubmit} autoComplete="off">
        <div className="flex items-center justify-between">
          <p className="font-semibold text-22">Edit user</p>

          <button type="button" onClick={onClose}>
            <Icon icon={<CloseIcon />} size="lg" className=" fill-black" />
          </button>
        </div>

        <div className="flex flex-col my-8 mb-3 gap-y-3">
          <Input name="email" label="Email" value={values.email} disabled />
          <Input
            name="givenName"
            label="First name"
            value={values.givenName}
            disabled
          />
          <Input
            name="surname"
            label="Surname"
            value={values.surname}
            disabled
          />
        </div>
        {!isLoading && (
          <div className="mb-8">
            <h3 className="mb-6 font-semibold text-black text-20">Roles</h3>
            {roles?.map(role => (
              <Radio
                key={role.id}
                name="roleId"
                endLabel={role.name}
                helperText={role.description}
                value={role.id}
                checked={values.role.id === role.id}
                onChange={() => handleChangeRole(role)}
                disabled={(loggedUserRole?.sequence ?? -1) > role.sequence}
                labelClassName="font-semibold"
                helperTextClassName="text-14 leading-[24px] text-black-40"
              />
            ))}
          </div>
        )}
        <Button
          type="submit"
          color="primary"
          endIcon={<CheckIcon />}
          fullWidth
          size="lg"
        >
          Save
        </Button>
      </form>
    </Modal>
  );
};

export { EditUserModal };
