import { FC } from 'react';

import { Skeleton } from 'shared/ui';
import { cn } from 'shared/lib';

interface FieldSkeletonProps {
  withHelperText: boolean;
}

const FieldSkeleton: FC<FieldSkeletonProps> = ({ withHelperText }) => {
  return (
    <div
      className={cn('flex w-full flex-col gap-y-[8px]', {
        'mb-6': withHelperText,
      })}
    >
      <Skeleton className="w-[104px] h-[22px]" />
      <Skeleton className="w-full h-[56px] rounded-[10px]" />
    </div>
  );
};

export { FieldSkeleton };
