import { useParams } from 'react-router';

import { CrumbSkeleton } from 'features';

import { useGetResellerSummaryQuery } from 'shared/api/subscriptions';

const ResellerBreadcrumb = () => {
  const params = useParams();
  const resellerId = params.resellerId ?? '';

  const { data: reseller, isLoading } = useGetResellerSummaryQuery({
    id: resellerId,
  });

  if (isLoading) {
    return <CrumbSkeleton />;
  }

  return <span>{reseller?.name}</span>;
};

export { ResellerBreadcrumb };
