import { ReactNode, useState, FC, Children, ReactElement } from 'react';
import { Formik, Form, FormikConfig, FormikValues } from 'formik';

import { ReactComponent as CheckIcon } from 'shared/icons/check_icon.svg';
import { ReactComponent as ChevronRightIcon } from 'shared/icons/chevron_right_icon.svg';
import { ReactComponent as ChevronLeftIcon } from 'shared/icons/chevron_left_icon.svg';

import { Button } from 'shared/ui';
import { cn } from 'shared/lib';

import { StepperItem } from './ui';

interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

const FormikStep: FC<FormikStepProps> = ({ children, label }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

interface FormikStepperProps extends FormikConfig<FormikValues> {
  children: ReactNode;
  lastStepNextButtonText?: string;
  className: string;
  isSubmitting?: boolean;
}

const FormikStepper: FC<FormikStepperProps> = ({
  children,
  className,
  lastStepNextButtonText,
  isSubmitting: isSubmittingProp,
  ...props
}) => {
  const childrenArray = Children.toArray(
    children,
  ) as ReactElement<FormikStepProps>[];

  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  const isLastStep = step === childrenArray.length - 1;
  const isFirstStep = step === 0;

  const handleBackClicked = () => {
    if (step < 0) {
      return;
    }

    setStep(currentStep => currentStep - 1);
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep) {
          await props.onSubmit(values, helpers);
        } else {
          setStep(currentStep => currentStep + 1);

          helpers.setTouched({});
        }
      }}
    >
      {() => (
        <Form
          autoComplete="off"
          className={cn(
            'flex bg-white max-h-full flex-col border-[1px] border-grey-80 rounded-[15px] max-w-[760px]',
            className,
          )}
        >
          <div className="flex p-[24px] justify-center w-full items-center">
            {childrenArray.map((child, index) => (
              <StepperItem
                key={child.props.label}
                step={step}
                index={index}
                label={child.props.label}
                initialArray={childrenArray}
              />
            ))}
          </div>

          <div className="max-h-full">{currentChild}</div>

          <div className="navigation p-[24px] self-end flex gap-x-[12px]">
            {!isFirstStep && (
              <Button
                disabled={isSubmittingProp}
                color="secondary"
                iconSize="lg"
                startIcon={<ChevronLeftIcon />}
                onClick={handleBackClicked}
              >
                Back
              </Button>
            )}

            <Button
              disabled={isSubmittingProp}
              endIcon={isLastStep ? <CheckIcon /> : <ChevronRightIcon />}
              iconSize="lg"
              color="primary"
              type="submit"
            >
              {isLastStep ? lastStepNextButtonText ?? 'Submit' : 'Next'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { FormikStepper, FormikStep };
