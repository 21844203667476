import { loginRequest } from 'authConfig';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';

const Signout = () => {
  const { instance, accounts } = useMsal();
  const [signingOut, setSigningOut] = useState(false);

  const signOutMsal = async () => {
    await instance.initialize();
    const currentAccount = accounts[0] ?? {};
    instance.logout({
      ...loginRequest,
      account: currentAccount,
      postLogoutRedirectUri: '/',
    });
  };

  useEffect(() => {
    if (signingOut === false) {
      signOutMsal();

      setSigningOut(true);
    }
  }, [signOutMsal]);

  return <p>Signing out...</p>;
};

export default Signout;
