import { ReactNode } from 'react';

import { UserRole } from 'shared/ts';
import { ReactComponent as HomeIcon } from 'shared/icons/home_icon.svg';
import { ReactComponent as PersonIcon } from 'shared/icons/person_icon.svg';
import { ReactComponent as BriefcasesIcon } from 'shared/icons/briefcases_icon.svg';

type TRouteItem = {
  path: string;
  title: string;
  userRoles: UserRole[];
  icon?: ReactNode;
};

type TRoutesMap = {
  home: TRouteItem;
  customers: TRouteItem;
  addCustomer: TRouteItem;
  resellers: TRouteItem;
  addReseller: TRouteItem;
  public: TRouteItem;
  error: TRouteItem;
  idp: TRouteItem;
};

const routesMap: TRoutesMap = {
  home: {
    path: '/',
    title: 'Home',
    icon: <HomeIcon />,
    userRoles: ['adminUser', 'reseller'],
  },
  customers: {
    path: '/customers',
    title: 'Customers',
    icon: <PersonIcon />,
    userRoles: ['keesingAdministrator', 'reseller'],
  },
  addCustomer: {
    path: '/customers/newCustomer',
    title: 'New Customer',
    userRoles: [],
  },
  resellers: {
    path: '/resellers',
    title: 'Resellers',
    icon: <BriefcasesIcon />,
    userRoles: ['keesingAdministrator'],
  },
  addReseller: {
    path: '/resellers/newReseller',
    title: 'New Reseller',
    userRoles: [],
  },
  public: {
    path: '/public',
    title: 'Public',
    userRoles: [],
  },
  error: {
    path: '/public/error',
    title: 'Error',
    userRoles: [],
  },
  idp: {
    path: '/public/idp-configuration',
    title: 'Idp configuration',
    userRoles: [],
  },
};

export { routesMap };
