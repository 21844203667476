import { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import {
  Tabs,
  InfoBlock,
  Alert,
  PageLayout,
  InvitationsTableControls,
  InvitationsTableControlsValue,
  UsersTableControls,
  UsersTableControlsValue,
  RequestsTableControls,
  RequestsTableControlsValue,
  SubscriptionStatusInfoBlock,
} from 'features';
import {
  SubscriptionInvitations,
  SubscriptionUsers,
  SubscriptionRequests,
} from 'widgets';

import { useGetResellerSummaryQuery } from 'shared/api/subscriptions';
import { formatStringToDate, getErrorMessage } from 'shared/lib';
import { Statuses } from 'shared/ts';

import { ResellerActions } from './ResellerActions';
import { ResellerInfoBlock } from './ui';

const Reseller = () => {
  const params = useParams();
  const resellerId = params.resellerId ?? '';

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [usersControlsValue, setUsersControlsValue] =
    useState<UsersTableControlsValue>({
      search: '',
      filters: { status: [] },
    });

  const [invitationsControlsValue, setInvitationsControlsValue] =
    useState<InvitationsTableControlsValue>({
      search: '',
    });

  const [requestsControlsValue, setRequestsControlsValue] =
    useState<RequestsTableControlsValue>({
      search: '',
    });

  const {
    data: reseller,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetResellerSummaryQuery(
    {
      id: resellerId,
    },
    { refetchOnMountOrArgChange: true },
  );

  const formattedCreatedAt = formatStringToDate(reseller?.createdAt);
  const formattedExpiriesAt = formatStringToDate(reseller?.expiresAt);
  const isLoadingData = isLoading || isFetching || isError;

  const resellerTabContent = [
    {
      title: 'Users',
      component: (
        <SubscriptionUsers id={resellerId} controlsValue={usersControlsValue} />
      ),
      amount: reseller?.totalOfUsers,
      controls: (
        <UsersTableControls
          controlValues={usersControlsValue}
          onChange={setUsersControlsValue}
          isLoading={isLoadingData}
        />
      ),
    },
    {
      title: 'Invitations',
      component: (
        <SubscriptionInvitations
          id={resellerId}
          controlsValue={invitationsControlsValue}
        />
      ),
      amount: reseller?.totalOfUserInvitations,
      controls: (
        <InvitationsTableControls
          controlsValue={invitationsControlsValue}
          onChange={setInvitationsControlsValue}
          isLoading={isLoadingData}
        />
      ),
    },
    {
      title: 'Access Request',
      component: (
        <SubscriptionRequests
          id={resellerId}
          controlsValue={requestsControlsValue}
        />
      ),
      controls: (
        <RequestsTableControls
          controlValues={requestsControlsValue}
          onChange={setRequestsControlsValue}
          isLoading={isLoadingData}
        />
      ),
      amount: reseller?.totalOfUserAccessRequests,
    },
  ];

  const customerInfo = [
    { title: 'Contract number', value: reseller?.contractNumber },
    { title: 'Industry', value: reseller?.industry },
    { title: 'Phone', value: reseller?.phone },
    {
      title: 'Created at',
      value: `${formattedCreatedAt}`,
    },
    {
      title: 'Expiries At',
      value: `${formattedExpiriesAt}`,
    },
    {
      title: 'Limit of users',
      value:
        reseller?.limitOfUsers === -1
          ? 'Unlimited'
          : `Up to ${reseller?.limitOfUsers}`,
    },
  ];

  const isShowTabContent =
    reseller?.status === Statuses.ACTIVE ||
    reseller?.status === Statuses.INACTIVE;

  useEffect(() => {
    if (isError) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage, { autoClose: 5000 });
    }
  }, [isError]);

  return (
    <PageLayout
      className="pt-[24px]"
      isHeaderLoading={isLoadingData}
      isError={isError}
      headerActions={
        <ResellerActions
          reseller={reseller}
          resellerId={resellerId}
          isLoading={isLoadingData}
        />
      }
      isChildRoute
    >
      <div className="flex items-start w-full gap-x-[24px]">
        <InfoBlock
          infoItems={customerInfo}
          isLoading={isLoadingData}
          subscriptionType="reseller"
          subscriptionId={resellerId}
          subscriptionNotes={reseller?.note}
        />
        <ResellerInfoBlock
          isLoading={isLoadingData}
          customersTotal={reseller?.totalOfCustomers}
          customersPerMonth={reseller?.totalOfCustomersAddedInLast30Days}
        />
        {reseller?.reachedUserLimit && (
          <Alert
            message="This reseller has reached the maximum number of users subscribed."
            className="mt-[24px]"
          />
        )}
      </div>

      {isShowTabContent ? (
        <>
          <div className="flex h-[48px] items-center justify-between mt-[46px] mb-[24px]">
            <Tabs
              items={resellerTabContent}
              isLoading={isLoadingData}
              currentTabIndex={currentTabIndex}
              setCurrentTabIndex={setCurrentTabIndex}
            />

            {resellerTabContent[currentTabIndex].controls}
          </div>

          {resellerTabContent[currentTabIndex].component}
        </>
      ) : (
        <SubscriptionStatusInfoBlock
          id={resellerId}
          name={reseller?.name}
          isLoading={isLoadingData}
          status={reseller?.status}
        />
      )}
    </PageLayout>
  );
};

export { Reseller };
