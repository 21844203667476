import { useState, FC, useRef } from 'react';

import Scrollbars from 'react-custom-scrollbars-2';

import { cn, range } from 'shared/lib';
import { Dropdown, Scrollbar } from 'shared/ui';
import { ReactComponent as MoreHorisontalIcon } from 'shared/icons/more_horisontal_icon.svg';

interface PageDropdownProps {
  totalPages: number;
  onSelect: (pageNumber: number) => void;
  currentPage: number;
}

const PageDropdown: FC<PageDropdownProps> = ({
  totalPages,
  onSelect,
  currentPage,
}) => {
  const [open, setOpen] = useState(false);

  const scrollbarRef = useRef<Scrollbars>(null);
  const currentPageRef = useRef<HTMLDivElement>(null);

  const pages = range(1, totalPages);

  const handleDropdownOpen = (isOpen: boolean) => {
    setOpen(isOpen);

    const scrollbarRefCurrent = scrollbarRef.current;
    const currentPageRefCurrent = currentPageRef.current;

    if (scrollbarRefCurrent && currentPageRefCurrent) {
      const scrollY = currentPageRefCurrent.offsetTop;
      scrollbarRefCurrent.scrollTop(scrollY);
    }
  };

  const handlePageSelect = (pageNumber: number) => {
    setOpen(false);
    setTimeout(() => onSelect(pageNumber), 200);
  };

  return (
    <Dropdown
      isOpen={open}
      positionY="top"
      positionX="center"
      alwaysMountedMenu
      buttonProps={{
        color: undefined,
        className: cn('p-[10px] rounded-full border-[1px] border-white hover', {
          'border-main': open,
        }),
        iconSize: 'lg',
      }}
      menuClassName="p-0"
      onOpen={handleDropdownOpen}
      buttonContent={<MoreHorisontalIcon />}
    >
      <div className="w-[152px] py-[12px]">
        <p className="pl-[24px] py-[8px] text-13 text-black-40 text-left mb-[4px]">
          Go to page
        </p>
        <Scrollbar
          height="348px"
          ref={scrollbarRef}
          trackVerticalStyle={{ top: 2, width: 4 }}
          viewClassName="px-[12px] flex flex-col gap-y-[4px] h-full"
        >
          {pages.map(pageNumber => {
            const isCurrentPage = pageNumber === currentPage;

            return (
              <div
                key={pageNumber}
                onClick={() => handlePageSelect(pageNumber)}
                ref={isCurrentPage ? currentPageRef : undefined}
                className={cn(
                  'py-[8px] px-[12px] text-16 cursor-pointer font-semibold text-main-50 hover:bg-main-99 rounded-[5px]',
                  {
                    'text-main bg-main-99': isCurrentPage,
                  },
                )}
              >
                <span>{pageNumber}</span>
              </div>
            );
          })}
        </Scrollbar>
      </div>
    </Dropdown>
  );
};

export { PageDropdown };
