import { useState } from 'react';

import { PageLayout } from 'features';
import { useNavigate } from 'react-router';

import { Chip, Table, TableHeaderType } from 'shared/ui';
import { SortOrder, SortParamsType } from 'shared/ts';
import { formatStringToDate } from 'shared/lib';
import { useGetCustomersQuery, CustomersItem } from 'shared/api/subscriptions';

import { CustomersActions } from './CustomersActions';
import { CustomersFilterValues } from './customers.types';

const PAGE_SIZE = 15;

const headers: TableHeaderType[] = [
  {
    title: 'Organization',
    field: 'name',
    render: (item: CustomersItem) => (
      <div className="flex flex-col gap-y-[8px]">
        <p className="text-12 text-black-40">
          Contract number: {item.contractNumber}
        </p>
        <p className="max-w-[470px] truncate">{item.name}</p>
      </div>
    ),
  },
  {
    title: 'Phone',
    field: 'phone',
    canSort: false,
  },
  {
    title: 'Industry',
    field: 'industry',
  },
  {
    title: 'Created at',
    field: 'createdAt',
    render: (item: CustomersItem) => formatStringToDate(item.createdAt),
  },
  {
    title: 'Expires at',
    field: 'expiresAt',
    render: (item: CustomersItem) => formatStringToDate(item.expiresAt),
  },
  {
    title: 'Status',
    field: 'status',
    className: 'w-[167px]',
    render: (item: CustomersItem) => (
      <Chip status={item.status} isDemo={item.demo} isExpired={item.expired} />
    ),
  },
];

const defaultSortParams = {
  sortBy: 'createdAt',
  sortOrder: SortOrder.DESC,
};

const defaultStatuses = ['1', '2', '3', '4'];

const Customers = () => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [sortParams, setSortParams] =
    useState<SortParamsType>(defaultSortParams);
  const [filterValues, setFilterValues] = useState<CustomersFilterValues>({
    statuses: [],
  });

  const { demo, statuses, expired } = filterValues;

  const { data, isLoading, isFetching } = useGetCustomersQuery({
    ...sortParams,
    type: 1,
    pageNumber,
    pageSize: PAGE_SIZE,
    demo,
    expired,
    statuses: statuses.length ? statuses : defaultStatuses,
  });

  const customers = data?.data;
  const totalCustomersRecord = data?.totalRecords ?? 0;
  const currentPage = data?.currentPage ?? pageNumber;

  const isLoaderVisible = isFetching || isLoading;

  const handleSort = (sortParams: SortParamsType) => {
    setSortParams(sortParams);
  };

  return (
    <PageLayout
      headerActions={
        <CustomersActions
          filterValues={filterValues}
          setFilterValues={setFilterValues}
          setPageNumber={setPageNumber}
        />
      }
      className="pb-[24px]"
    >
      <Table
        headers={headers}
        data={customers}
        isLoading={isLoaderVisible}
        onSort={handleSort}
        defaultSortParams={defaultSortParams}
        pagination={{
          currentPage,
          pageSize: PAGE_SIZE,
          total: totalCustomersRecord,
          onChange: setPageNumber,
        }}
        onRowAction={id => navigate(`${id}`)}
      />
    </PageLayout>
  );
};

export { Customers };
