import { FC, useMemo, useState } from 'react';
import { ConfirmModal, DateCell } from 'features';
import { Chip, Table, TableHeaderType, TablePaginationType } from 'shared/ui';
import { formatStringToDate } from 'shared/lib';
import { ReactComponent as LockIcon } from 'shared/icons/lock_icon.svg';
import { ReactComponent as UnlockIcon } from 'shared/icons/unlock_icon.svg';
import {
  ConfirmModalOptionsType,
  DeleteUserParams,
  EditUserParams,
  SortParamsType,
  Statuses,
  UserType,
  initialConfirmModalOptions,
} from 'shared/ts';

import { EditUserModal } from 'features/EditUserModal/EditUserModal';
import { UsersTableActions } from './ui';
import { EditUserModalOptionsType } from './usersTable.types';

interface UsersTableProps {
  users?: UserType[];
  onSort?: (sortParams: SortParamsType) => void;
  onDeleteUser: (
    deleteUserParams: Omit<DeleteUserParams, 'subscriptionId'>,
    user: UserType,
  ) => void;
  onEditUser: (
    editUserParams: Omit<EditUserParams, 'subscriptionId'>,
    user: UserType,
  ) => void;
  defaultSortParams?: SortParamsType;
  isLoading: boolean;
  pagination?: TablePaginationType;
  organizationId: string;
}

const UsersTable: FC<UsersTableProps> = ({
  users,
  onSort,
  isLoading,
  onEditUser,
  pagination,
  onDeleteUser,
  defaultSortParams,
  organizationId,
}) => {
  const [confirmModalOptions, setConfirmModalOptions] =
    useState<ConfirmModalOptionsType>(initialConfirmModalOptions);
  const [editUserModalOptions, setEditUserModalOptions] =
    useState<EditUserModalOptionsType>({
      selectedUser: undefined,
      isOpen: false,
    });
  const headers: TableHeaderType[] = useMemo(
    () => [
      {
        title: 'User',
        field: 'givenName',
        render: (user: UserType) => (
          <div className="flex flex-col gap-y-[8px]">
            <p className="text-12 text-black-40">{user.email}</p>
            <p className="max-w-[470px] truncate">
              {user.givenName} {user.surname}
            </p>
          </div>
        ),
      },
      {
        title: 'Role',
        field: 'role',
        render: (user: UserType) => user.role.name,
      },
      {
        title: 'Created',
        field: 'createdAt',
        render: (user: UserType) => (
          <p className="font-semibold text-black">
            {formatStringToDate(user.createdAt)}
          </p>
        ),
      },
      {
        title: 'Last activity',
        field: 'lastSignIn',
        render: (user: UserType) => <DateCell date={user.lastSignIn} />,
      },
      {
        title: 'Status',
        field: 'active',
        className: 'pr-0',
        render: (user: UserType) => {
          const status = user.active ? Statuses.ACTIVE : Statuses.INACTIVE;

          return <Chip status={status} />;
        },
      },
    ],
    [],
  );

  const handleUserDelete = (user: UserType) => {
    setConfirmModalOptions({
      isOpen: true,
      title: `Delete the user ${user.givenName} ${user.surname}`,
      subtitle: 'Are you sure you want to delete this user ?',
      confirmButtonText: 'Delete the user',
      onConfirm: () => onDeleteUser({ userId: user.id }, user),
    });
  };

  const handleUserInactivate = (user: UserType) => {
    setConfirmModalOptions({
      isOpen: true,
      title: `Inactivate the user ${user.givenName} ${user.surname}`,
      subtitle: 'Are you sure you want to inactivate this user?',
      confirmButtonText: 'Inactivate',
      confirmIcon: <LockIcon />,
      onConfirm: () => onEditUser({ userId: user.id, active: false }, user),
    });
  };

  const handleUserActivate = (user: UserType) => {
    setConfirmModalOptions({
      isOpen: true,
      title: `Activate the user ${user.givenName} ${user.surname}`,
      subtitle: 'Are you sure you want to activate this user?',
      confirmButtonText: 'Activate',
      confirmIcon: <UnlockIcon />,
      confirmButtonProps: { color: 'primary' },
      onConfirm: () => onEditUser({ userId: user.id, active: true }, user),
    });
  };

  const closeEditUserModal = () => {
    setEditUserModalOptions({
      ...editUserModalOptions,
      isOpen: false,
    });
  };

  const handleEditUserModalOpen = (user: UserType) => {
    setEditUserModalOptions({
      isOpen: true,
      selectedUser: user,
    });
  };

  const handleEditUserModalConfirm = (user: UserType) => {
    closeEditUserModal();

    if (!editUserModalOptions.selectedUser) {
      return;
    }

    onEditUser({ userId: user.id, roleId: user.role.id }, user);
  };

  const handleConfirmModalClose = () => {
    if (confirmModalOptions.onClose) {
      confirmModalOptions.onClose();
    }
    setConfirmModalOptions(prevModalOptions => ({
      ...prevModalOptions,
      isOpen: false,
    }));
  };

  const handleConfirmModalConfirm = () => {
    if (confirmModalOptions.onConfirm) {
      confirmModalOptions.onConfirm();
    }

    setConfirmModalOptions(prevModalOptions => ({
      ...prevModalOptions,
      isOpen: false,
    }));
  };

  return (
    <>
      <Table
        headers={headers}
        data={users}
        isLoading={isLoading}
        emptyStateTitle="There are no users"
        emptyStateSubtitle="Here we are going to show the list of users."
        renderActions={(user: UserType) => (
          <UsersTableActions
            user={user}
            onEditClick={handleEditUserModalOpen}
            onActivateClick={handleUserActivate}
            onDeleteClick={handleUserDelete}
            onInactivateClick={handleUserInactivate}
          />
        )}
        onSort={onSort}
        defaultSortParams={defaultSortParams}
        pagination={pagination}
      />
      <ConfirmModal
        {...confirmModalOptions}
        onClose={handleConfirmModalClose}
        onConfirm={handleConfirmModalConfirm}
      />
      {editUserModalOptions.selectedUser && (
        <EditUserModal
          organizationId={organizationId}
          isOpen={editUserModalOptions.isOpen}
          user={editUserModalOptions.selectedUser}
          onConfirm={handleEditUserModalConfirm}
          onClose={closeEditUserModal}
        />
      )}
    </>
  );
};

export { UsersTable };
