import { FC } from 'react';
import { formatDistanceStrict } from 'date-fns';
import { formatStringToDate } from 'shared/lib';

interface DateCellProps {
  date: string | undefined;
  expired?: boolean;
}

const DateCell: FC<DateCellProps> = ({ date, expired }) => {
  const formattedLastSignIn = formatStringToDate(date, 'HH:mm, MMM dd (EEE)');
  const [dateTime, day] = formattedLastSignIn.split(' (');
  const formatDateDistance = (d: string) =>
    formatDistanceStrict(new Date(d), Date.now(), {
      addSuffix: true,
    });

  if (date == null)
    return (
      <div className="flex flex-col gap-y-[8px]">{formattedLastSignIn}</div>
    );

  let dateDistance = null;
  if (expired !== undefined) {
    dateDistance = expired ? (
      <p className="text-12 text-warning-40">
        Expired {formatDateDistance(date)}
      </p>
    ) : (
      <p className="text-12 text-black-40">
        Expires {formatDateDistance(date)}
      </p>
    );
  } else {
    dateDistance = (
      <p className="text-12 text-black-40">{formatDateDistance(date)}</p>
    );
  }

  return (
    <div className="flex flex-col gap-y-[8px]">
      {dateDistance}
      <p className="font-semibold text-black">
        {dateTime} <span className="text-grey-40">{`(${day}`}</span>
      </p>
    </div>
  );
};

export { DateCell };
