import { FC, useState } from 'react';
import { cn } from 'shared/lib';
import { Icon } from 'shared/ui';
import { ReactComponent as InfoIcon } from 'shared/icons/info_icon.svg';

interface InfoProps {
  className?: string;
  message?: string;
}

const Alert: FC<InfoProps> = ({ className, message }) => {
  return (
    <div
      className={cn(
        'flex flex-col w-full p-6 bg-amber-50 border-[1px] border-amber-200 rounded-[15px]',
        className,
      )}
    >
      <div className="flex items-center w-full">
        <Icon icon={<InfoIcon />} size="lg" className="fill-amber-700" />
        <p className="ml-2 font-semibold text-amber-700 text-15">{message}</p>
      </div>
    </div>
  );
};

export { Alert };
