import {
  ChangeEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  ComponentPropsWithRef,
  MouseEvent,
  ReactNode,
} from 'react';

import { cn } from 'shared/lib';
import { FieldWrapper, Icon } from 'shared/ui';
import { ReactComponent as CloseIcon } from 'shared/icons/close_icon.svg';

interface TagInputProps
  extends Omit<ComponentPropsWithRef<'input'>, 'onChange'> {
  label?: string;
  value: string[];
  wrapperClassName?: string;
  className?: string;
  error?: boolean;
  onChange: (event: string[]) => void;
  helperText?: ReactNode;
}

const TagInput: FC<TagInputProps> = forwardRef<HTMLInputElement, TagInputProps>(
  (
    {
      value: currentTags,
      label,
      wrapperClassName,
      className,
      error,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,
      disabled,
      helperText,
      ...props
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const addTag = (value: string) => {
      if (currentTags.includes(value) || !value) return;

      const updatedTagsValue = [...currentTags, value];

      onChange(updatedTagsValue);
      setInputValue('');
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);

      if (onFocus) {
        onFocus(event);
      }
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);

      addTag(inputValue.trim());
      if (onBlur) {
        onBlur(event);
      }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    };

    const handleWrapperMouseDown = (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }

      if (!isFocused) {
        inputRef.current?.focus();
      }

      const inputClicked = event.target === inputRef.current;

      if (!inputClicked) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (onKeyDown) {
        onKeyDown(event);
      }

      const { value } = event.target as HTMLInputElement;
      const trimmedValue = value.trim();

      if (event.key === 'Backspace' && !value && currentTags.length) {
        const tagsCopy = [...currentTags];
        const removedTag = tagsCopy.pop();

        setInputValue(removedTag || inputValue);
        onChange(tagsCopy);
        return;
      }

      if (event.key !== 'Enter') return;

      addTag(trimmedValue);
    };

    const handleRemoveTag = (tag: string) => {
      const updatedTagsValue = currentTags.filter(
        currentTag => currentTag !== tag,
      );

      onChange(updatedTagsValue);
    };

    useImperativeHandle(ref, () => inputRef.current!, []);

    return (
      <div
        onKeyDown={event => {
          // Prevent the form submission when Enter key is pressed
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        <FieldWrapper
          childrenContainerClassName={cn(
            'flex-wrap gap-[8px] px-[12px] py-[11px]',
            className,
          )}
          isFocused={isFocused}
          onWrapperMouseDown={handleWrapperMouseDown}
          label={label}
          wrapperClassName={wrapperClassName}
          helperText={helperText}
          error={error}
          disabled={disabled}
          inputValue={inputValue}
        >
          {currentTags.map(tag => (
            <div
              key={tag}
              className="flex text-main-keesing max-w-full items-center gap-[8px] bg-white rounded-full px-[8px] py-[4px]"
            >
              <span className="block text-ellipsis overflow-hidden">{tag}</span>
              <Icon
                icon={<CloseIcon />}
                onClick={() => handleRemoveTag(tag)}
                size="lg"
                color="primary"
                className="flex-shrink-0 cursor-pointer"
              />
            </div>
          ))}

          <input
            type="text"
            id={`id-${label}`}
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            className="flex-grow bg-transparent outline-none pl-[4px]"
            {...props}
          />
        </FieldWrapper>
      </div>
    );
  },
);

TagInput.displayName = 'TagInput';
export { TagInput };
