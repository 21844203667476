import { useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { cn } from 'shared/lib';
import { Divider, Icon } from 'shared/ui';
import { ReactComponent as Logout } from 'shared/icons/logout_icon.svg';
import { loginRequest } from 'authConfig';

import { SidebarHeader, SidebarMenu, SidebarUserInfo } from './ui';

import './style.css';

const Sidebar = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    JSON.parse(localStorage.getItem('isSidebarCollapsed') ?? 'false'),
  );

  const { instance, accounts } = useMsal();

  const currentAccount = accounts[0] ?? {};

  const handleSidebarCollapse = () => {
    const sidebarValue = !sidebarCollapsed;

    setSidebarCollapsed(sidebarValue);
    localStorage.setItem('isSidebarCollapsed', `${sidebarValue}`);
  };

  const handleLogOutClicked = () => {
    instance.logout({
      ...loginRequest,
      account: currentAccount,
    });
  };

  return (
    <aside
      className={cn(
        'z-10 sticky top-0 w-full max-w-[240px] h-screen bg-main-keesing transition-all duration-300 ease-in-out overflow-hidden',
        { 'max-w-[80px]': sidebarCollapsed },
      )}
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 pt-[40px] pb-[16px] px-[12px] flex flex-col h-full">
        <SidebarHeader
          user={currentAccount}
          burgerMenuStyles="sidebar-button rounded-[4px]"
          isCollapsed={sidebarCollapsed}
          onCollapse={handleSidebarCollapse}
        />

        <Divider className="absolute left-0 top-[96px] bg-main-60" />

        <SidebarMenu
          linkClasses="sidebar-text sidebar-button"
          user={currentAccount}
          isCollapsed={sidebarCollapsed}
        />

        <div className="mt-auto">
          <SidebarUserInfo
            user={currentAccount}
            isCollapsed={sidebarCollapsed}
          />
          <button
            type="button"
            className="mt-[8px] sidebar-text sidebar-button"
            onClick={handleLogOutClicked}
          >
            <Icon icon={<Logout />} className="flex-shrink-0" size="lg" />
            {!sidebarCollapsed && <span>Logout</span>}
          </button>
        </div>
      </div>
    </aside>
  );
};

export { Sidebar };
