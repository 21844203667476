import { addYears, endOfYear, getYear } from 'date-fns';
import { range } from 'shared/lib';

const currentDate = new Date();

const maxDate = endOfYear(addYears(currentDate, 15));

const currentYear = getYear(currentDate);

const years = range(currentYear, currentYear + 15);

export { currentDate, currentYear, maxDate, years };
