import { differenceInMonths, format, formatDistanceStrict } from 'date-fns';

const getExpirationDateValue = (date: Date | null) => {
  if (!date) {
    return '';
  }

  const distanceFromNow = formatDistanceStrict(date, Date.now());

  const formattedExpirationDate = format(date, 'dd MMMM yyyy');

  const expirationDateValue =
    differenceInMonths(date, Date.now()) > 1
      ? `${formattedExpirationDate} (${distanceFromNow})`
      : formattedExpirationDate;

  return expirationDateValue;
};

export { getExpirationDateValue };
