import { FC } from 'react';

import { cn } from 'shared/lib';
import { ReactComponent as SpinnerIcon } from 'shared/icons/spinner_icon.svg';
import { Icon } from '../Icon/Icon';

interface LoaderProps {
  withText?: boolean;
  className?: string;
}

const Loader: FC<LoaderProps> = ({ withText, className }) => {
  return (
    <div
      className={cn(
        'absolute z-[1] w-full h-full flex justify-center items-center',
        className,
      )}
    >
      <div className="flex flex-col items-center">
        <Icon
          icon={<SpinnerIcon />}
          className="block w-[56px] h-[56px] animate-spin"
        />
        {withText && (
          <span className="block text-black-40 mt-[16px] text-14 font-semibold">
            Loading...
          </span>
        )}
      </div>
    </div>
  );
};

export { Loader };
