import { createApi } from '@reduxjs/toolkit/query/react';

import {
  AccessReqActionParams,
  AccessRequestsResponse,
  DeleteInvitationParams,
  DeleteUserParams,
  EditInvitationParams,
  EditUserParams,
  InvitationsResponse,
  InviteUserType,
  UsersParams,
  UsersResponse,
} from 'shared/ts';

import { baseQuery } from '../baseQuey';

import { OrganizationBasicParams, OrganizationSummaryResponse } from './models';

export const myOrganizationApi = createApi({
  baseQuery,
  reducerPath: 'myOrganizationApi',
  tagTypes: [
    'organization',
    'organizationUsers',
    'organizationInvitations',
    'organizationAccessReq',
  ],
  endpoints: builder => ({
    getOrganizationSummary: builder.query<OrganizationSummaryResponse, void>({
      query: () => ({
        url: '/api/me/summary',
        method: 'GET',
      }),
      providesTags: ['organization'],
    }),
    getOrganizationUsers: builder.query<
      UsersResponse,
      Omit<UsersParams, 'subscriptionId'>
    >({
      query: params => ({
        url: `/api/me/users`,
        method: 'GET',
        params,
      }),
      providesTags: ['organizationUsers'],
    }),
    editOrganizationUser: builder.mutation<
      void,
      Omit<EditUserParams, 'subscriptionId'>
    >({
      query: ({ userId, ...body }) => ({
        url: `/api/me/users/${userId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['organizationUsers'],
    }),
    deleteOrganizationUser: builder.mutation<
      void,
      Omit<DeleteUserParams, 'subscriptionId'>
    >({
      query: ({ userId }) => ({
        url: `/api/me/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['organizationUsers', 'organization'],
    }),
    getOrganizationInvitations: builder.query<
      InvitationsResponse,
      OrganizationBasicParams
    >({
      query: params => ({
        url: `/api/me/invitations`,
        method: 'GET',
        params,
      }),
      providesTags: ['organizationInvitations'],
    }),
    inviteOrganizationUser: builder.mutation<void, InviteUserType>({
      query: body => ({
        url: `/api/me/user-invitations`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['organizationInvitations', 'organization'],
    }),
    editOrganizationInvitation: builder.mutation<
      void,
      Omit<EditInvitationParams, 'subscriptionId'>
    >({
      query: ({ userInvitationId, ...body }) => ({
        url: `/api/me/user-invitations/${userInvitationId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['organizationInvitations'],
    }),
    deleteOrganizationInvitation: builder.mutation<
      void,
      Omit<DeleteInvitationParams, 'subscriptionId'>
    >({
      query: ({ userInvitationId, ...body }) => ({
        url: `/api/me/user-invitations/${userInvitationId}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['organizationInvitations', 'organization'],
    }),
    getOrganizationAccessReq: builder.query<
      AccessRequestsResponse,
      OrganizationBasicParams
    >({
      query: params => ({
        url: `/api/me/user-access-requests`,
        method: 'GET',
        params,
      }),
      providesTags: ['organizationAccessReq'],
    }),
    approveOrganizationAccessReq: builder.mutation<
      void,
      Omit<AccessReqActionParams, 'subscriptionId'>
    >({
      query: ({ userId }) => ({
        url: `/api/me/user-access-requests/${userId}/approve`,
        method: 'PUT',
      }),
      invalidatesTags: [
        'organization',
        'organizationUsers',
        'organizationAccessReq',
      ],
    }),
    denyOrganizationAccessReq: builder.mutation<
      void,
      Omit<AccessReqActionParams, 'subscriptionId'>
    >({
      query: ({ userId }) => ({
        url: `/api/me/user-access-requests/${userId}/deny`,
        method: 'PUT',
      }),
      invalidatesTags: [
        'organization',
        'organizationUsers',
        'organizationAccessReq',
      ],
    }),
  }),
});

export const {
  useGetOrganizationSummaryQuery,
  useGetOrganizationUsersQuery,
  useDeleteOrganizationUserMutation,
  useEditOrganizationUserMutation,
  useDeleteOrganizationInvitationMutation,
  useEditOrganizationInvitationMutation,
  useGetOrganizationInvitationsQuery,
  useInviteOrganizationUserMutation,
  useApproveOrganizationAccessReqMutation,
  useDenyOrganizationAccessReqMutation,
  useGetOrganizationAccessReqQuery,
} = myOrganizationApi;
