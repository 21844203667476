import { FC, ComponentProps } from 'react';
import { cn } from 'shared/lib';

import { ReactComponent as CheckboxInnerIcon } from './ui/checkbox-inner-icon.svg';
import { ReactComponent as CheckboxOuterIcon } from './ui/checkbox-outer-icon.svg';
import { CheckboxHelperText } from './ui/CheckboxHelperText';

import './style.css';

interface CheckboxProps extends ComponentProps<'input'> {
  startLabel?: string;
  endLabel?: string;
  labelClassName?: string;
  fullWidth?: boolean;
  helperTextClassName?: string;
  checked?: boolean;
  helperText?: string;
}

const Checkbox: FC<CheckboxProps> = ({
  startLabel,
  endLabel,
  labelClassName,
  fullWidth,
  helperText,
  helperTextClassName,
  disabled,
  ...props
}) => {
  return (
    <label
      className={cn(
        'checkbox-container cursor-pointer w-fit text-16',
        { 'w-full': fullWidth, 'cursor-default': disabled },
        labelClassName,
      )}
    >
      {startLabel && (
        <p>
          {startLabel}
          {helperText && (
            <CheckboxHelperText
              helperText={helperText}
              className={helperTextClassName}
            />
          )}
        </p>
      )}
      <input type="checkbox" disabled={disabled} {...props} />
      <span
        className={cn('relative', {
          'ml-[15px]': startLabel,
          'mr-[15px]': endLabel,
          'self-start': helperText,
        })}
      >
        <CheckboxOuterIcon className="checkbox-outer" />
        <CheckboxInnerIcon className="checkbox-inner absolute top-0 checkmark-checked" />
      </span>

      {endLabel && (
        <p>
          {endLabel}
          {helperText && (
            <CheckboxHelperText
              helperText={helperText}
              className={helperTextClassName}
            />
          )}
        </p>
      )}
    </label>
  );
};

export { Checkbox };
