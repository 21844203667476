import { FC } from 'react';

import { Button, Icon, Modal } from 'shared/ui';
import { ConfirmModalOptionsType } from 'shared/ts';
import { ReactComponent as CloseIcon } from 'shared/icons/close_icon.svg';
import { ReactComponent as UserRemoveIcon } from 'shared/icons/user_remove_icon.svg';

const ConfirmModal: FC<ConfirmModalOptionsType> = ({
  isOpen,
  title,
  subtitle,
  confirmButtonText,
  closeButtonText = 'Close',
  confirmIcon = <UserRemoveIcon />,
  onClose,
  onConfirm,
  confirmButtonProps,
}) => {
  return (
    <Modal isOpen={isOpen} toggleModal={onClose}>
      <div className="relative min-w-[472px] w-full">
        <button
          type="button"
          className="absolute ml-auto right-0"
          onClick={onClose}
        >
          <Icon icon={<CloseIcon />} size="lg" className=" fill-black" />
        </button>

        <div className="mb-[32px]">
          <div className="flex max-w-[450px] flex-col w-full justify-center items-center">
            <p className="text-center text-22 font-semibold">{title}</p>
            <p className="text-center text-16 leading-[21px] mt-[8px]">
              {subtitle}
            </p>
          </div>
        </div>

        <div className="flex justify-center w-full gap-x-[16px]">
          <Button
            color="tonal"
            fullWidth
            size="lg"
            endIcon={<CloseIcon />}
            className="font-semibold "
            onClick={onClose}
          >
            {closeButtonText}
          </Button>
          <Button
            color="destructive"
            fullWidth
            size="lg"
            endIcon={confirmIcon}
            className="font-semibold"
            onClick={onConfirm}
            {...confirmButtonProps}
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { ConfirmModal };
