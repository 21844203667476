import { FC } from 'react';
import { Skeleton } from 'shared/ui';

interface InfoBlockItemsSkeletonProps {
  infoLength: number;
}

const InfoBlockItemsSkeleton: FC<InfoBlockItemsSkeletonProps> = ({
  infoLength,
}) => {
  return (
    <div className="w-full flex gap-x-[8px]">
      {Array.from({ length: Math.ceil(infoLength / 2) }).map((_, index) => (
        <Skeleton key={index} className="w-full h-[102px] rounded-[10px]" />
      ))}
    </div>
  );
};

export { InfoBlockItemsSkeleton };
