import { format } from 'date-fns';

const formatStringToDate = (
  str: string | undefined,
  formatArg = 'dd MMM. yyyy',
) => {
  if (!str) {
    return '-';
  }

  return format(new Date(str), formatArg);
};

export { formatStringToDate };
