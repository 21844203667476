import { useParams } from 'react-router';

import { CrumbSkeleton } from 'features';

import { useGetCustomerSummaryQuery } from 'shared/api/subscriptions';

const CustomerBreadcrumb = () => {
  const params = useParams();
  const customerId = params.customerId ?? '';

  const { data: customer, isLoading } = useGetCustomerSummaryQuery({
    id: customerId,
  });

  if (isLoading) {
    return <CrumbSkeleton />;
  }

  return <span>{customer?.name}</span>;
};

export { CustomerBreadcrumb };
