import { ReactNode, FC } from 'react';
import { cn } from 'shared/lib';

interface TabProps {
  index: number;
  title: string;
  amount?: ReactNode;
  currentTabIndex: number;
  onClick: () => void;
}

const Tab: FC<TabProps> = ({
  index,
  title,
  amount,
  currentTabIndex,
  onClick,
}) => {
  const isActive = currentTabIndex === index;

  return (
    <div
      className={cn(
        'group relative h-fit cursor-pointer pb-[11px] hover:after:bg-main-70 after:duration-200',
        "after:content-[''] after:bottom-[0px] after:block after:w-full after:absolute after:h-[1px] after:bg-main-80",
        {
          'after:h-[4px] after:bg-main after:rounded-t-[10px] hover:after:bg-main':
            isActive,
        },
      )}
      onClick={onClick}
    >
      <div className="flex items-center px-[16px] gap-x-[4px]">
        <p
          className={cn(
            'w-full text-16 font-medium text-main-60 group-hover:text-main-50 duration-200',
            {
              'text-main group-hover:text-main': isActive,
            },
          )}
        >
          {title}
        </p>

        <span
          className={cn(
            'flex-shrink-0 min-w-[20px] group-hover:bg-main-50 text-center text-12 bg-main-60 duration-200 rounded-full px-[4px] text-white',
            { 'bg-main group-hover:bg-main': isActive },
          )}
        >
          {amount}
        </span>
      </div>
    </div>
  );
};

export { Tab };
