import { FC, useState, useMemo } from 'react';

import { ConfirmModal, DateCell, InviteUserModal } from 'features';
import { Table, TableHeaderType, TablePaginationType } from 'shared/ui';
import {
  ConfirmModalOptionsType,
  DeleteInvitationParams,
  EditInvitationParams,
  InvitationType,
  InviteUserType,
  SortParamsType,
  UserType,
  initialConfirmModalOptions,
} from 'shared/ts';

import { InvitationsTableActions } from './ui';

import { EditInvitationModalOptionsType } from './invitationsTable.types';

interface InvitationsTableProps {
  organizationId: string;
  invitations?: InvitationType[];
  onSort?: (sortParams: SortParamsType) => void;
  onDeleteInvitation: (
    deleteInvitationParams: Omit<DeleteInvitationParams, 'subscriptionId'>,
  ) => void;
  onEditInvitation: (
    editInvitationParams: Omit<EditInvitationParams, 'subscriptionId'>,
  ) => void;
  defaultSortParams?: SortParamsType;
  isLoading: boolean;
  pagination?: TablePaginationType;
}

const InvitationsTable: FC<InvitationsTableProps> = ({
  organizationId,
  invitations,
  isLoading,
  onSort,
  onDeleteInvitation,
  onEditInvitation,
  pagination,
  defaultSortParams,
}) => {
  const [confirmModalOptions, setConfirmModalOptions] =
    useState<ConfirmModalOptionsType>(initialConfirmModalOptions);
  const [editInvitationModalOptions, setEditInvitationModalOptions] =
    useState<EditInvitationModalOptionsType>({
      selectedInvitation: undefined,
      isOpen: false,
    });

  const headers: TableHeaderType[] = useMemo(
    () => [
      {
        title: 'User',
        field: 'givenName',
        render: (invitation: InvitationType) => (
          <div className="flex flex-col gap-y-[8px]">
            <p className="text-12 text-black-40">{invitation.email}</p>
            <p className="max-w-[470px] truncate">
              {invitation.givenName} {invitation.surname}
            </p>
          </div>
        ),
      },
      {
        title: 'Role',
        field: 'role',
        render: (user: UserType) => user.role.name,
      },
      {
        title: 'Invitation date',
        field: 'createdAt',
        render: (invitation: InvitationType) => (
          <DateCell date={invitation.createdAt} />
        ),
      },
      {
        title: 'Expiry date',
        field: 'expiresAt',
        render: (invitation: InvitationType) => (
          <DateCell date={invitation.expiresAt} expired={invitation.expired} />
        ),
      },
      {
        title: 'Sent by',
        field: 'createdBy',
        canSort: false,
      },
    ],
    [],
  );

  const closeEditInvitationModal = () => {
    setEditInvitationModalOptions({
      ...editInvitationModalOptions,
      isOpen: false,
    });
  };

  const handleEditInvitationModalConfirm = (invitation: InviteUserType) => {
    closeEditInvitationModal();

    if (!editInvitationModalOptions.selectedInvitation) {
      return;
    }

    onEditInvitation({
      ...invitation,
      userInvitationId: editInvitationModalOptions.selectedInvitation.id,
    });
  };

  const handleEditInvitationModalOpen = (invitation: InvitationType) => {
    setEditInvitationModalOptions({
      isOpen: true,
      selectedInvitation: invitation,
    });
  };

  const handleDeleteUserModalOpen = (invitation: InvitationType) => {
    setConfirmModalOptions({
      isOpen: true,
      title: `Delete the invitation ${invitation.givenName} ${invitation.surname}`,
      subtitle: 'Are you sure you want to delete this invitation ?',
      confirmButtonText: 'Delete Invitation',
      confirmButtonProps: { className: 'w-fit flex-shrink-0' },
      onConfirm: () => {
        onDeleteInvitation({
          userInvitationId: invitation.id,
        });
      },
    });
  };

  const handleConfirmModalClose = () => {
    if (confirmModalOptions.onClose) {
      confirmModalOptions.onClose();
    }

    setConfirmModalOptions(prevModalOptions => ({
      ...prevModalOptions,
      isOpen: false,
    }));
  };

  const handleConfirmModalConfirm = () => {
    if (confirmModalOptions.onConfirm) {
      confirmModalOptions.onConfirm();
    }

    setConfirmModalOptions(prevModalOptions => ({
      ...prevModalOptions,
      isOpen: false,
    }));
  };

  return (
    <>
      <Table
        headers={headers}
        data={invitations}
        isLoading={isLoading}
        renderActions={(invitation: InvitationType) => (
          <InvitationsTableActions
            invitation={invitation}
            onEditClick={handleEditInvitationModalOpen}
            onDeleteClick={handleDeleteUserModalOpen}
          />
        )}
        emptyStateTitle="There are no invitations"
        emptyStateSubtitle="This is where we will show you the invitations that you have sent out to users to join."
        onSort={onSort}
        defaultSortParams={defaultSortParams}
        pagination={pagination}
      />
      <ConfirmModal
        {...confirmModalOptions}
        onClose={handleConfirmModalClose}
        onConfirm={handleConfirmModalConfirm}
      />
      <InviteUserModal
        organizationId={organizationId}
        isOpen={editInvitationModalOptions.isOpen}
        invitation={editInvitationModalOptions?.selectedInvitation}
        onConfirm={handleEditInvitationModalConfirm}
        onClose={closeEditInvitationModal}
      />
    </>
  );
};

export { InvitationsTable };
