import { FC } from 'react';
import { useNavigate } from 'react-router';
import { Filter, FilterDataType } from 'features';
import { IconButton, CustomTooltip } from 'shared/ui';
import { routesMap } from 'shared/constants';
import { ReactComponent as PlusIcon } from 'shared/icons/plus_icon.svg';
import { ResellersFilterValues } from './resellers.types';
import { ResellersSearchBar } from './ui';

const filterData: FilterDataType[] = [
  {
    name: 'statuses',
    title: 'Status',
    type: 'checkbox',
    options: [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '2' },
      { label: 'Waiting reseller', value: '3' },
      { label: 'Ready for activation', value: '4' },
    ],
  },
  {
    name: 'demo',
    type: 'radio',
    title: 'Demo',
    options: [
      { label: 'All resellers', value: undefined },
      { label: 'Demo resellers', value: 'true' },
      { label: 'Regular resellers', value: 'false' },
    ],
  },
  {
    name: 'expired',
    type: 'radio',
    title: 'Expiration',
    options: [
      { label: 'All resellers', value: undefined },
      { label: 'Expired resellers', value: 'true' },
      { label: 'Not expired resellers', value: 'false' },
    ],
  },
];

interface ResellersActionsProps {
  filterValues: ResellersFilterValues;
  setFilterValues: (filterStatuses: ResellersFilterValues) => void;
  setPageNumber: (page: number) => void;
}

const ResellersActions: FC<ResellersActionsProps> = ({
  filterValues,
  setFilterValues,
  setPageNumber,
}) => {
  const navigate = useNavigate();

  const handleFilterChange = (values: ResellersFilterValues) => {
    setPageNumber(1);
    setFilterValues(values);
  };

  return (
    <div className="flex items-center gap-x-[12px]">
      <ResellersSearchBar />
      <Filter<ResellersFilterValues>
        data={filterData}
        selectedOptions={filterValues}
        onChange={handleFilterChange}
      />
      <IconButton
        color="primary"
        icon={<PlusIcon />}
        onClick={() => navigate(routesMap.addReseller.path)}
        data-tooltip-id="addReseller"
      />
      <CustomTooltip
        id="addReseller"
        content="Add reseller"
        place="bottom-start"
      />
    </div>
  );
};

export { ResellersActions };
