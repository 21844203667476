import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Button, Icon, Skeleton } from 'shared/ui';
import { ReactComponent as UserIcon } from 'shared/icons/user_icon.svg';
import { ReactComponent as ChevronRightIcon } from 'shared/icons/chevron_right_icon.svg';

interface ResellerInfoBlockProps {
  isLoading: boolean;
  customersTotal?: number;
  customersPerMonth?: number;
}

const ResellerInfoBlock: FC<ResellerInfoBlockProps> = ({
  isLoading,
  customersTotal,
  customersPerMonth,
}) => {
  if (isLoading) {
    return (
      <div className="flex flex-[1_0_30%] gap-x-4 items-center p-6 bg-white border-[1px] border-grey-80 rounded-[15px]">
        <Skeleton className="w-full h-[102px] rounded-[10px]" />
      </div>
    );
  }

  return (
    <Link
      to="customerList"
      className="flex flex-[1_0_30%] gap-x-4 items-center min-h-[151px] p-6 bg-white border-[1px] border-grey-80 rounded-[15px]"
    >
      <div className="flex p-[12px] items-center h-[102px] rounded-[15px] bg-main-99">
        <Icon icon={<UserIcon />} size="xxl" color="black" />
      </div>
      <div className="flex flex-col w-full gap-y-[12px]">
        <div className="flex flex-col gap-y-[4px]">
          <p className="text-12 text-black-40">Reseller Total</p>
          <p className="text-15">
            <span className="text-main">{customersTotal}</span> Customers
          </p>
        </div>
        <div className="flex flex-col gap-y-[4px]">
          <p className="text-12 text-black-40">Added Per 30 days</p>
          <p className="text-15">
            <span className="text-main">{customersPerMonth}</span> Customers
          </p>
        </div>
      </div>
      <Button className="[&>svg]:fill-main bg-main-99 rounded-full">
        <Icon icon={<ChevronRightIcon />} size="lg" />
      </Button>
    </Link>
  );
};

export { ResellerInfoBlock };
