import { useState } from 'react';

import { PublicLayout } from 'widgets';

import { IdpForm, IdpInfoBlock, IdpSuccessBlock } from './ui';

const IdpConfigurationPage = () => {
  const [isIdpSent, setIsIdpSent] = useState(false);

  return (
    <PublicLayout mainClassName="pb-[26px]">
      <div className="flex gap-x-6 items-center bg-white p-6 rounded-[20px]">
        {isIdpSent ? (
          <IdpSuccessBlock />
        ) : (
          <>
            <IdpInfoBlock />

            <IdpForm setIsIdpSent={setIsIdpSent} />
          </>
        )}
      </div>
    </PublicLayout>
  );
};

export { IdpConfigurationPage };
