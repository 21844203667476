import { forwardRef, FocusEvent } from 'react';

import { cn } from 'shared/lib';
import { ReactComponent as CalendarIcon } from 'shared/icons/calendar_icon.svg';
import { ReactComponent as CloseIcon } from 'shared/icons/close_icon.svg';

import { Button, Icon } from 'shared/ui';

import { DateOnChangeType } from '../customDatePicker.types';

interface CustomDatePickerInputProps {
  onDateChange: (date: DateOnChangeType) => void | null;
  name?: string;
  isOpen: boolean;
  value?: string;
  onClick?: () => void;
  placeholder?: string;
  className?: string;
  error?: boolean;
  onBlur?: (event: FocusEvent) => void;
}

const CustomDatePickerInput = forwardRef<
  HTMLButtonElement,
  CustomDatePickerInputProps
>(
  (
    {
      onDateChange,
      name,
      isOpen,
      value,
      onClick,
      placeholder,
      className,
      error,
      onBlur,
    },
    ref,
  ) => {
    return (
      <button
        type="button"
        name={name}
        className={cn(
          'cursor-pointer h-[56px] flex items-center focus-visible:border-main outline-none transition duration-300 justify-between text-main-keesing rounded-[10px] text-16 w-full gap-x-[16px]  p-[16px] bg-main-99 border-[1px] border-main-80',
          {
            'text-black-50': !value,
            'border-error-80 bg-error-99': error,
          },
          className,
        )}
        onClick={onClick}
        onBlur={onBlur}
        ref={ref}
      >
        <span>{value || placeholder}</span>
        <div className="flex items-center gap-7">
          {value && (
            <Button
              className="[&>svg]:fill-main-keesing [&>svg]:mr-2 font-normal p-0 transition-none"
              endIcon={<CloseIcon />}
              onClick={event => {
                event.stopPropagation();
                onDateChange([null, null]);
              }}
            >
              Clear
            </Button>
          )}
          <div
            className={cn(
              'flex justify-center w-[40px] duration-300 h-[40px] items-center border-[1px] rounded-[10px] border-main-80 bg-white p-[8px]',
              {
                'bg-main-80 border-main-60': isOpen,
                'bg-error-99': error && !isOpen,
              },
            )}
          >
            <Icon icon={<CalendarIcon />} className="fill-main-20" size="lg" />
          </div>
        </div>
      </button>
    );
  },
);

CustomDatePickerInput.displayName = 'CustomDatePickerInput';
export { CustomDatePickerInput };
