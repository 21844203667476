import { FC } from 'react';

import { toast } from 'react-toastify';
import { FormikProvider, useFormik } from 'formik';

import {
  InformationStepForm,
  informationInitialValues,
  informationStepSchema,
} from 'features/Forms';

import {
  SubscriptionResponse,
  useEditSubscriptionMutation,
} from 'shared/api/subscriptions';
import { getErrorMessage } from 'shared/lib';
import { ReactComponent as RefreshIcon } from 'shared/icons/refresh_icon.svg';
import { ReactComponent as CloseIcon } from 'shared/icons/close_icon.svg';
import { ReactComponent as CheckIcon } from 'shared/icons/check_icon.svg';
import { Button, Icon, Modal, Scrollbar } from 'shared/ui';

import { EditSubscriptionModalSkeleton } from './ui';

interface EditSubscriptionModalProps {
  isOpen: boolean;
  subscription?: SubscriptionResponse;
  subscriptionId: string;
  onClose: () => void;
  onConfirm: () => void;
  isReseller: boolean;
}

const EditSubscriptionModal: FC<EditSubscriptionModalProps> = ({
  isOpen,
  subscriptionId,
  subscription,
  onClose,
  onConfirm,
  isReseller,
}) => {
  const subscriptionTypeTextCapitalize = isReseller ? 'Reseller' : 'Customer';

  const customerValidationSchema = informationStepSchema({ isReseller });

  const [editSubscription, result] = useEditSubscriptionMutation();

  const { isLoading } = result;

  const preparedInitialValue = subscription
    ? {
        accountNumber: subscription.accountNumber,
        contractNumber: subscription.contractNumber,
        industry: subscription.industry,
        name: subscription.name,
        phone: subscription.phone,
        expiresAt: subscription.expiresAt
          ? new Date(subscription.expiresAt)
          : null,
      }
    : informationInitialValues;

  const handleSubmit = async (values: typeof informationInitialValues) => {
    try {
      await editSubscription({
        subscriptionId,
        customer: values,
      }).unwrap();

      toast.success(
        `${subscriptionTypeTextCapitalize} has been successfully updated`,
      );
      onConfirm();
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage);
    }
  };

  const formik = useFormik<typeof informationInitialValues>({
    validationSchema: customerValidationSchema,
    initialValues: preparedInitialValue,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={onClose}
      customClassName="h-[90vh] max-h-[816px] overflow-y-hidden"
      contentClassName="p-0"
    >
      <FormikProvider value={formik}>
        <form
          className="relative h-full w-[614px]"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <div className="flex p-[24px] pb-[16px] justify-between items-center border-b-[1px] border-grey-80">
            <p className="text-22 leading-[32px] font-semibold">
              Edit {subscriptionTypeTextCapitalize}
            </p>

            <button type="button" onClick={onClose}>
              <Icon icon={<CloseIcon />} size="lg" className=" fill-black" />
            </button>
          </div>

          <div className="overflow-hidden h-full">
            <Scrollbar
              height="100%"
              maxHeight="100%"
              viewClassName="pb-[194px] px-[24px] pt-[24px]"
              trackVerticalStyle={{ right: '3px' }}
              trackVerticalClassName="pb-[180px]"
            >
              {subscription ? (
                <InformationStepForm isModalView isReseller={isReseller} />
              ) : (
                <EditSubscriptionModalSkeleton />
              )}
            </Scrollbar>
          </div>

          <div className="sticky border-t-[1px] border-grey-80  p-[24px] bottom-0  bg-white flex items-center gap-x-[16px] z-[0]">
            <Button
              color="tonal"
              fullWidth
              size="lg"
              disabled={isLoading}
              startIcon={<RefreshIcon />}
              onClick={() => formik.resetForm()}
            >
              Reset changes
            </Button>
            <Button
              disabled={isLoading}
              startIcon={<CheckIcon />}
              type="submit"
              color="primary"
              fullWidth
              size="lg"
            >
              Save
            </Button>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  );
};

export { EditSubscriptionModal };
