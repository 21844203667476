import { Helmet } from 'react-helmet';

const Scripts = () =>
  process.env.REACT_APP_LUCKY_ORANGE_SITE_ID ? (
    <Helmet>
      <script
        async
        defer
        src={`https://tools.luckyorange.com/core/lo.js?site-id=${process.env.REACT_APP_LUCKY_ORANGE_SITE_ID}`}
      />
    </Helmet>
  ) : null;

export { Scripts };
