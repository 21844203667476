import {
  AmountOfUsersInitialValuesType,
  amountOfUsersInitialValues,
} from '../AmountOfUsersFormBlock/amountOfUsersInitialValues';

interface UsersInitialValuesType extends AmountOfUsersInitialValuesType {
  superUser: {
    name: string;
    surname: string;
    email: string;
  };
}

const usersInitialValues: UsersInitialValuesType = {
  superUser: {
    name: '',
    surname: '',
    email: '',
  },
  ...amountOfUsersInitialValues,
};

export { usersInitialValues };
export type { UsersInitialValuesType };
