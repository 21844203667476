import { useState, FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { SearchBar } from 'features';
import { useGetResellersQuery, ResellersItem } from 'shared/api/subscriptions';
import { SortOrder } from 'shared/ts';
import { Button, Icon } from 'shared/ui';
import { ReactComponent as ChevronRightIcon } from 'shared/icons/chevron_right_icon.svg';

interface ResellersSearchBarProps {
  className?: string;
}

const ResellersSearchBar: FC<ResellersSearchBarProps> = ({ className }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isLoading, isFetching } = useGetResellersQuery(
    {
      sortBy: 'createdAt',
      sortOrder: SortOrder.DESC,
      pageNumber: 1,
      pageSize: 100,
      search: searchQuery,
      statuses: ['1', '2'],
    },
    { skip: !searchQuery },
  );

  const { data: customers } = data ?? {};

  const isLoadingData = isLoading || isFetching;

  const renderOption = useCallback(
    ({ id, industry, phone, name, contractNumber }: ResellersItem) => (
      <Link
        className="flex py-[6px] gap-x-[4px] w-full items-center justify-center"
        to={`/resellers/${id}`}
      >
        <div className="flex justify-center w-full flex-col gap-y-[4px]">
          <div className="flex justify-between text-12 leading-[16px] text-black-40">
            <p className="max-w-[250px] truncate">{industry}</p>
            <p>{phone}</p>
          </div>
          <div className="flex justify-between text-black text-16">
            <p className="max-w-[250px] truncate font-semibold">{name}</p>
            <p className="max-w-[180px] truncate">{contractNumber}</p>
          </div>
        </div>
        <div className="ml-auto">
          <Button className="[&>svg]:fill-main bg-main-99 rounded-full border-[1px] border-main-99">
            <Icon icon={<ChevronRightIcon />} size="lg" />
          </Button>
        </div>
      </Link>
    ),
    [],
  );

  return (
    <SearchBar
      data={customers}
      renderOption={renderOption}
      isLoading={isLoadingData}
      query={searchQuery}
      onQueryChange={setSearchQuery}
      className={className}
    />
  );
};

export { ResellersSearchBar };
