import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import {
  Tabs,
  InfoBlock,
  Alert,
  PageLayout,
  InvitationsTableControls,
  InvitationsTableControlsValue,
  UsersTableControls,
  UsersTableControlsValue,
  RequestsTableControls,
  RequestsTableControlsValue,
} from 'features';

import { useGetOrganizationSummaryQuery } from 'shared/api/myOrganization';
import { useMsal } from '@azure/msal-react';
import {
  formatStringToDate,
  getErrorMessage,
  getOrganizationId,
} from 'shared/lib';
import { MyOrganizationActions } from './MyOrganizationActions';
import {
  MyOrganizationInvitations,
  MyOrganizationRequests,
  MyOrganizationUsers,
} from './ui';

const MyOrganization = () => {
  const { accounts } = useMsal();
  const currentAccount = accounts[0];
  const organizationId = getOrganizationId(currentAccount);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [usersControlsValue, setUsersControlsValue] =
    useState<UsersTableControlsValue>({
      search: '',
      filters: { status: [] },
    });

  const [invitationsControlsValue, setInvitationsControlsValue] =
    useState<InvitationsTableControlsValue>({
      search: '',
    });

  const [requestsControlsValue, setRequestsControlsValue] =
    useState<RequestsTableControlsValue>({
      search: '',
    });

  const {
    data: organization,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetOrganizationSummaryQuery();

  const formattedCreatedAt = formatStringToDate(organization?.createdAt);
  const formattedExpiriesAt = formatStringToDate(organization?.expiresAt);
  const isLoadingData = isLoading || isFetching || isError;

  const customerTabContent = [
    {
      title: 'Users',
      component: <MyOrganizationUsers controlsValue={usersControlsValue} />,
      amount: organization?.totalOfUsers,
      controls: (
        <UsersTableControls
          controlValues={usersControlsValue}
          onChange={setUsersControlsValue}
          isLoading={isLoadingData}
        />
      ),
    },
    {
      title: 'Invitations',
      component: (
        <MyOrganizationInvitations controlsValue={invitationsControlsValue} />
      ),
      amount: organization?.totalOfUserInvitations,
      controls: (
        <InvitationsTableControls
          controlsValue={invitationsControlsValue}
          onChange={setInvitationsControlsValue}
          isLoading={isLoadingData}
        />
      ),
    },
    {
      title: 'Access Request',
      component: (
        <MyOrganizationRequests controlsValue={requestsControlsValue} />
      ),
      controls: (
        <RequestsTableControls
          controlValues={requestsControlsValue}
          onChange={setRequestsControlsValue}
          isLoading={isLoadingData}
        />
      ),
      amount: organization?.totalOfUserAccessRequests,
    },
  ];

  const documentCheckerOptionsAmount =
    organization?.products.find(product => product.name === 'DocumentChecker')
      ?.features.length ?? 0;

  const authentiscanOptionsAmount =
    organization?.products.find(product => product.name === 'AuthentiScan')
      ?.features.length ?? 0;

  const customerInfo = [
    { title: 'Contract number', value: organization?.contractNumber },
    { title: 'Industry', value: organization?.industry },
    { title: 'Phone', value: organization?.phone },
    {
      title: `From ${formattedCreatedAt}`,
      value: `To ${formattedExpiriesAt}`,
    },
    {
      title: 'Document checker',
      value: `${documentCheckerOptionsAmount} option (s) included`,
    },
    {
      title: 'Authentiscan',
      value: `${authentiscanOptionsAmount} option (s) included`,
    },
    {
      title: 'Limit of users',
      value:
        organization?.limitOfUsers === -1
          ? 'Unlimited'
          : `Up to ${organization?.limitOfUsers}`,
    },
  ];

  useEffect(() => {
    if (isError) {
      const errorMessage = getErrorMessage(error);

      toast.error(errorMessage, { autoClose: 5000 });
    }
  }, [isError]);

  return (
    <PageLayout
      className="pt-[24px]"
      isHeaderLoading={isLoadingData}
      isError={isError}
      headerActions={
        <MyOrganizationActions
          organizationId={organizationId}
          organization={organization}
          isLoading={isLoadingData}
        />
      }
    >
      <InfoBlock infoItems={customerInfo} isLoading={isLoadingData} />
      {organization?.reachedUserLimit && (
        <Alert
          message="Your organization has reached the maximum number of users subscribed."
          className="mt-[24px]"
        />
      )}
      <div className="flex h-[48px] items-center justify-between mt-[46px] mb-[24px]">
        <Tabs
          items={customerTabContent}
          isLoading={isLoadingData}
          currentTabIndex={currentTabIndex}
          setCurrentTabIndex={setCurrentTabIndex}
        />

        {customerTabContent[currentTabIndex].controls}
      </div>

      {customerTabContent[currentTabIndex].component}
    </PageLayout>
  );
};

export { MyOrganization };
