import { ReactNode, CSSProperties, forwardRef } from 'react';

import ReactCustomScrollbar, {
  ScrollbarProps as ReactCustomScrollbarProps,
} from 'react-custom-scrollbars-2';

import { cn } from 'shared/lib';

interface ScrollbarProps extends ReactCustomScrollbarProps {
  children: ReactNode;
  height?: string | number;
  maxHeight?: string | number;
  className?: string;
  trackVerticalStyle?: CSSProperties;
  trackVerticalClassName?: string;
  thumbVerticalClassName?: string;
  viewClassName?: string;
}

const Scrollbar = forwardRef<ReactCustomScrollbar, ScrollbarProps>(
  (
    {
      children,
      height = 'fit-content',
      maxHeight = '75vh',
      className,
      trackVerticalStyle,
      trackVerticalClassName,
      thumbVerticalClassName,
      viewClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <ReactCustomScrollbar
        ref={ref}
        style={{ maxHeight, height }}
        className={cn('!overflow-x-hidden', className)}
        renderView={props => (
          <div
            {...props}
            className={cn(
              '!overflow-x-hidden overflow-y-scroll h-full',
              viewClassName,
            )}
          />
        )}
        hideTracksWhenNotNeeded
        renderTrackVertical={({ style, ...props }) => {
          const finalStyle = {
            ...style,
            right: 2,
            bottom: 2,
            top: 2,
            ...trackVerticalStyle,
          };
          return (
            <div
              style={finalStyle}
              className={trackVerticalClassName}
              {...props}
            />
          );
        }}
        renderThumbVertical={props => (
          <div
            {...props}
            className={cn('bg-main-80 rounded-full', thumbVerticalClassName)}
          />
        )}
        autoHide
        {...props}
      >
        {children}
      </ReactCustomScrollbar>
    );
  },
);

Scrollbar.displayName = 'Scrollbar';
export { Scrollbar };
