import { Dispatch, FC, SetStateAction } from 'react';

import { SearchBar } from 'features';

import { InvitationsTableControlsValue } from '../invitationsTable.types';

import { InvitationsTableControlsSkeleton } from './InvitationsTableControlsSkeleton';

interface InvitationsTableControlsProps {
  controlsValue: InvitationsTableControlsValue;
  onChange: Dispatch<SetStateAction<InvitationsTableControlsValue>>;
  isLoading?: boolean;
}

const InvitationsTableControls: FC<InvitationsTableControlsProps> = ({
  controlsValue,
  onChange,
  isLoading,
}) => {
  const { search } = controlsValue;

  if (isLoading) {
    return <InvitationsTableControlsSkeleton />;
  }

  return (
    <SearchBar
      data={undefined}
      query={search}
      onQueryChange={query => onChange({ search: query })}
    />
  );
};

export { InvitationsTableControls };
