import { useMsal } from '@azure/msal-react';
import { RoleItem, RolesResponse } from 'shared/api/roles/models';

const getLoggedUserRole = (
  roles: RolesResponse | undefined,
): RoleItem | undefined => {
  const { accounts } = useMsal();
  const currentAccount = accounts[0] ?? {};
  const { extension_user_role } = currentAccount?.idTokenClaims ?? {};
  const roleId = (extension_user_role as string | undefined) ?? '';
  return roles?.find(r => r.id === roleId);
};

export { getLoggedUserRole };
