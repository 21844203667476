const Statuses = {
  ACTIVE: 1,
  INACTIVE: 2,
  WAITING_CUSTOMER: 3,
  READY_FOR_ACTIVATION: 4,
} as const;

type StatusType = (typeof Statuses)[keyof typeof Statuses];

export { Statuses };
export type { StatusType };
