import * as Yup from 'yup';

const amountOfUsersFormBlockSchema = Yup.object().shape({
  limitOfUsers: Yup.number().test(
    'validLimitOfUsers',
    'Please enter valid value',
    value => !!value && value !== 0 && value >= -1,
  ),
});

export { amountOfUsersFormBlockSchema };
