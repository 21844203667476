import { FC } from 'react';
import { useFormikContext } from 'formik';

import { cn } from 'shared/lib';
import { ProductItem } from 'shared/api/products';
import { Checkbox, Divider, Icon, Switch } from 'shared/ui';
import { ReactComponent as DocumentIcon } from 'shared/icons/document_icon.svg';
import { ReactComponent as UserIcon } from 'shared/icons/user_icon.svg';

import {
  ServicesFormFeaturesType,
  ServicesInitialValuesType,
} from '../servicesInitialValues';

const productDataMap = {
  DocumentChecker: {
    icon: <DocumentIcon />,
    title: 'Document Checker',
    description: 'Description about this service',
  },

  AuthentiScan: {
    icon: <UserIcon />,
    title: 'Authentiscan',
    description: 'Description about this service',
  },
};

interface FeaturesBlockProps extends ProductItem {}

const FeaturesBlock: FC<FeaturesBlockProps> = ({
  id: productId,
  features,
  name: productName,
}) => {
  const { values, setFieldValue } =
    useFormikContext<ServicesInitialValuesType>();

  const { icon, title, description } = productDataMap[productName];

  const isProductEnabled = values.products.includes(productId);

  const handleProductAdd = () => {
    if (isProductEnabled) {
      return setFieldValue(
        'products',
        values.products.filter(product => product !== productId),
      );
    }

    return setFieldValue('products', [...values.products, productId]);
  };

  const handleFeaturesAdd = (
    featureId: string,
    currentFeature?: ServicesFormFeaturesType,
  ) => {
    if (currentFeature) {
      return setFieldValue(
        'features',
        values.features.filter(
          feature => feature.featureId !== currentFeature.featureId,
        ),
      );
    }

    return setFieldValue('features', [
      ...values.features,
      { productId, featureId },
    ]);
  };

  return (
    <div className="border-[1px] border-main-80 bg-main-99 rounded-[15px]">
      <button
        type="button"
        name="products"
        className="flex px-[16px] w-full text-start items-center py-[12px] justify-between"
        onClick={handleProductAdd}
      >
        <div className="flex gap-x-[8px] items-center">
          <div className="bg-white rounded-[15px] p-[12px]">
            <Icon
              icon={icon}
              size="lg"
              className={cn('fill-black', {
                'fill-main': isProductEnabled,
              })}
            />
          </div>
          <div className="flex flex-col gap-y-[4px]">
            <p className="text-20 font-semibold">{title}</p>
            <span className="font-inter text-12 leading-[16px] text-black-40">
              {description}
            </span>
          </div>
        </div>
        <Switch
          labelClassName="pointer-events-none"
          checked={isProductEnabled}
          readOnly
        />
      </button>
      <Divider className="bg-main-80" />

      {features.map(feature => {
        const currentFeature = values.features.find(
          currentFeature => currentFeature.featureId === feature.id,
        );

        return (
          <div key={feature.id} className="px-[24px] py-[12px]">
            <Checkbox
              key={feature.id}
              name="features"
              checked={!!currentFeature}
              onChange={() => handleFeaturesAdd(feature.id, currentFeature)}
              disabled={!isProductEnabled}
              labelClassName="w-full justify-stretch"
              endLabel={feature.description}
            />
          </div>
        );
      })}
    </div>
  );
};

export { FeaturesBlock };
