import { FC, ReactNode } from 'react';

import { Tab, TabsSkeleton } from './ui';

type TabItem = {
  title: string;
  component: ReactNode;
  amount?: ReactNode;
} & { [key: string]: ReactNode };

interface TabsProps {
  items: TabItem[];
  currentTabIndex: number;
  isLoading?: boolean;
  setCurrentTabIndex: (index: number) => void;
}

const Tabs: FC<TabsProps> = ({
  items,
  isLoading,
  currentTabIndex,
  setCurrentTabIndex,
}) => {
  if (isLoading) {
    return <TabsSkeleton itemsLength={items.length} />;
  }

  return (
    <div className="flex gap-x-[16px]">
      {items.map((item, index) => (
        <Tab
          key={item.title}
          index={index}
          title={item.title}
          amount={item.amount}
          currentTabIndex={currentTabIndex}
          onClick={() => setCurrentTabIndex(index)}
        />
      ))}
    </div>
  );
};

export { Tabs };
