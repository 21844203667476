import { FC } from 'react';
import { useFormikContext } from 'formik';

import { Select, Switch } from 'shared/ui';
import { useGetProductsQuery } from 'shared/api/products';
import { cn } from 'shared/lib';

import { ServicesInitialValuesType } from './servicesInitialValues';

import { FeaturesBlock, FeaturesBlockSkeleton } from './ui';

const options = [
  { label: 'Norway', value: 'norway' },
  { label: 'Western Europe', value: 'westernEurope' },
  { label: 'Other Regions', value: 'otherRegions' },
];

interface ServicesStepFormProps {
  isModalView?: boolean;
  isReseller?: boolean;
}

const ServicesStepForm: FC<ServicesStepFormProps> = ({
  isModalView,
  isReseller,
}) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext<ServicesInitialValuesType>();

  const { data: products, isLoading } = useGetProductsQuery();

  const helperTextForDataRegion =
    isModalView ||
    (touched.dataStorageRegion && errors.dataStorageRegion) ||
    ' ';

  return (
    <div className={cn('w-full p-[24px]', { 'p-0': isModalView })}>
      <div
        className={cn('flex flex-col', {
          'flex-row-reverse w-full justify-between items-end gap-x-[16px]':
            isModalView,
        })}
      >
        <div className="flex justify-between mb-[32px] items-center">
          {!isModalView && (
            <div className="flex flex-col gap-y-[8px]">
              <h3 className=" text-20 font-semibold text-black">
                Select the services
              </h3>
              <p className="text-14  leading-[20px] text-black-40">
                Select the services that will be available to this
                {isReseller ? ' reseller' : ' customer'}
              </p>
            </div>
          )}

          <button
            type="button"
            onClick={() => setFieldValue('demo', !values.demo)}
            className={cn(
              'relative flex max-h-[56px] border-[1px] border-main-80 bg-main-99 rounded-[10px] gap-x-[8px] px-[24px] py-[16px]',
              { 'mb-1': isModalView },
            )}
          >
            <Switch
              startLabel="Demo"
              name="demo"
              labelClassName="pointer-events-none"
              checked={values.demo}
              readOnly
            />
          </button>
        </div>

        <div className="flex w-full mb-[32px] items-top justify-between gap-x-[24px]">
          <Select
            options={options}
            wrapperClassName="w-full"
            name="dataStorageRegion"
            label="Choose your organisation region"
            placeholder="Choose your organisation region"
            value={values.dataStorageRegion}
            onChange={value => setFieldValue('dataStorageRegion', value)}
            onBlur={() => setFieldTouched('dataStorageRegion')}
            error={!!errors.dataStorageRegion && touched.dataStorageRegion}
            helperText={helperTextForDataRegion}
          />
        </div>
      </div>

      <div className="flex flex-col gap-y-[24px]">
        {isLoading && <FeaturesBlockSkeleton />}
        {products?.map(product => (
          <FeaturesBlock
            key={product.id}
            id={product.id}
            name={product.name}
            features={product.features}
          />
        ))}
      </div>
    </div>
  );
};

export { ServicesStepForm };
