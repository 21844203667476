import { FC } from 'react';

import { Link } from 'react-router-dom';
import { AccountInfo } from '@azure/msal-common';

import { cn, getUserRole } from 'shared/lib';
import logoSmall from 'shared/icons/logo_keesing_small.svg';
import { ReactComponent as BurgerMenuIcon } from 'shared/icons/burger_icon.svg';
import { Icon } from 'shared/ui';
import { routesMap } from 'shared/constants';

interface SidebarHeaderProps {
  burgerMenuStyles: string;
  user: AccountInfo;
  isCollapsed: boolean;
  onCollapse: () => void;
}

const SidebarHeader: FC<SidebarHeaderProps> = ({
  burgerMenuStyles,
  user,
  isCollapsed,
  onCollapse,
}) => {
  const userRole = getUserRole(user);

  const homeLink =
    userRole === 'keesingAdministrator'
      ? routesMap.customers.path
      : routesMap.home.path;

  return (
    <div className="flex items-center pb-[24px] pl-[8px] gap-x-[16px]">
      <button type="button" onClick={onCollapse}>
        <Icon
          icon={<BurgerMenuIcon />}
          size="xl"
          className={burgerMenuStyles}
        />
      </button>
      <Link
        to={homeLink}
        className={cn('flex-shrink-0', { hidden: isCollapsed })}
      >
        <img alt="logo" width="108" height="24" src={logoSmall} />
      </Link>
    </div>
  );
};

export { SidebarHeader };
