interface AuthInitialValuesType {
  authenticationMethod: 1 | 2;
  identityProvider: number | undefined;
  approvedDomains: string[];
  requiresNoInvitationForApprovedDomains: boolean;
  technicalContactEmail: string;
}

const authInitialValues: AuthInitialValuesType = {
  authenticationMethod: 1,
  identityProvider: undefined,
  approvedDomains: [],
  requiresNoInvitationForApprovedDomains: false,
  technicalContactEmail: '',
};

export { authInitialValues };
export type { AuthInitialValuesType };
