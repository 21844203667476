import { FC, useState } from 'react';

import { ReactComponent as ChevronDownIcon } from 'shared/icons/chevron_down_icon.svg';
import { ReactComponent as DoubleChevronIcon } from 'shared/icons/double_chevron.svg';

import { Icon } from 'shared/ui';
import { cn } from 'shared/lib';
import { SortOrder, SortParamsType } from 'shared/ts';

import { TableHeaderType } from '../table.types';

interface TableHeaderProps {
  headers: TableHeaderType[];
  onSort?: (sortParams: SortParamsType) => void;
  defaultSortParams?: SortParamsType;
  isActionShow: boolean;
}

const TableHeader: FC<TableHeaderProps> = ({
  headers,
  onSort,
  isActionShow,
  defaultSortParams = {},
}) => {
  const [currentSortParams, setCurrentSortPrams] = useState<SortParamsType>({});

  const handleSortClicked = (field: string, isSortedHeader: boolean) => {
    if (!onSort) {
      return;
    }

    if (isSortedHeader && currentSortParams.sortOrder === SortOrder.ASC) {
      setCurrentSortPrams({});
      onSort(defaultSortParams);
      return;
    }

    const sortParams = {
      sortBy: field,
      sortOrder: isSortedHeader ? SortOrder.ASC : SortOrder.DESC,
    };

    setCurrentSortPrams(sortParams);
    onSort(sortParams);
  };

  return (
    <tr>
      {headers.map((header, index) => {
        // If `header.canSort` is undefined, we treat it as true.
        // Sorting is only disabled when `header.canSort` is explicitly set to false.
        const canSort = header.canSort !== false;

        const isSortedHeader = header.field === currentSortParams.sortBy;

        return (
          <th
            key={index}
            className={cn(
              'text-black font-normal text-14 px-[16px] py-[14px] first:rounded-l last:rounded-r',
              header.className,
            )}
          >
            <div
              className={cn('flex items-center gap-x-[8px]', {
                'cursor-pointer select-none': canSort,
              })}
              onClick={() =>
                canSort && handleSortClicked(header.field, isSortedHeader)
              }
            >
              {header.title}

              {!isSortedHeader && canSort && (
                <Icon icon={<DoubleChevronIcon />} className="w-5 h-5" />
              )}

              {isSortedHeader && canSort && (
                <Icon
                  icon={<ChevronDownIcon />}
                  className={cn('fill-black w-5 h-5', {
                    'rotate-180':
                      isSortedHeader &&
                      currentSortParams.sortOrder === SortOrder.ASC,
                  })}
                />
              )}
            </div>
          </th>
        );
      })}

      {isActionShow && <th> </th>}
    </tr>
  );
};

export { TableHeader };
