import { FC } from 'react';
import { useFormik } from 'formik';
import { RoleItem, useGetRolesQuery } from 'shared/api/roles';
import { Button, Icon, Input, Modal, Radio } from 'shared/ui';
import { ReactComponent as PlaneIcon } from 'shared/icons/plane_icon.svg';
import { ReactComponent as CloseIcon } from 'shared/icons/close_icon.svg';
import { InvitationType, InviteUserType } from 'shared/ts';
import { getLoggedUserRole } from 'shared/lib/getLoggedUserRole';
import { getInviteUserInitialValues } from './getInviteUserInitialValues';
import { inviteUserValidationSchema } from './inviteUserValidationSchema';

interface InviteUserModalProps {
  isOpen: boolean;
  invitation?: InvitationType;
  onConfirm: (invitation: InviteUserType) => void;
  onClose: () => void;
  organizationId: string;
}

const InviteUserModal: FC<InviteUserModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  invitation,
  organizationId,
}) => {
  const { data: roles, isLoading } = useGetRolesQuery({ id: organizationId });
  const loggedUserRole = getLoggedUserRole(roles);
  const inviteUserInitialValues = getInviteUserInitialValues(roles);
  const isEditView = !!invitation;
  const preparedInitialValue = isEditView
    ? {
        email: invitation.email,
        givenName: invitation.givenName,
        surname: invitation.surname,
        expired: invitation.expired,
        role: invitation.role,
        roleId: invitation.role.id,
      }
    : inviteUserInitialValues;

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  } = useFormik<InviteUserType>({
    initialValues: preparedInitialValue,
    validationSchema: inviteUserValidationSchema,
    enableReinitialize: true,
    onSubmit: values => onConfirm(values),
  });

  const handleChangeRole = (role: RoleItem) => {
    setFieldValue('role', role);
    setFieldValue('roleId', role.id);
  };

  const title = isEditView ? 'Edit user invitation' : 'Invite new user';

  return (
    <Modal isOpen={isOpen} toggleModal={onClose}>
      <form className="w-[472px]" onSubmit={handleSubmit} autoComplete="off">
        <div className="flex items-center justify-between">
          <p className="font-semibold text-22">{title}</p>

          <button type="button" onClick={onClose}>
            <Icon icon={<CloseIcon />} size="lg" className=" fill-black" />
          </button>
        </div>

        <div className="flex flex-col my-8 mb-3 gap-y-3">
          <Input
            name="email"
            label="Email"
            placeholder="Email"
            helperText="The user will need to use this email to log in"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.email && !!touched.email}
          />
          <Input
            name="givenName"
            label="First name"
            placeholder="First name"
            value={values.givenName}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.givenName && !!touched.givenName}
            helperText={(touched.givenName && errors.givenName) || ' '}
          />
          <Input
            name="surname"
            label="Surname"
            placeholder="Surname"
            value={values.surname}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.surname && !!touched.surname}
            helperText={(touched.surname && errors.surname) || ' '}
          />
        </div>
        {!isLoading && (
          <div className="mb-8">
            <h3 className="mb-6 font-semibold text-black text-20">Roles</h3>
            {roles?.map(role => (
              <Radio
                key={role.id}
                name="roleId"
                endLabel={role.name}
                helperText={role.description}
                value={role.id}
                checked={values.role.id === role.id}
                onChange={() => handleChangeRole(role)}
                disabled={(loggedUserRole?.sequence ?? -1) > role.sequence}
                labelClassName="font-semibold"
                helperTextClassName="text-14 leading-[24px] text-black-40"
              />
            ))}
          </div>
        )}
        <Button
          type="submit"
          color="primary"
          endIcon={<PlaneIcon />}
          fullWidth
          size="lg"
        >
          Send invitation
        </Button>
      </form>
    </Modal>
  );
};

export { InviteUserModal };
