import { Skeleton } from 'shared/ui';

const EditServicesModalSkeleton = () => {
  return (
    <div className="w-full">
      <div className="flex justify-between items-end mb-[32px] gap-x-[16px]">
        <div className="flex w-[400px] flex-col gap-y-[8px]">
          <Skeleton className="w-[104px] h-[22px]" />
          <Skeleton className="w-full h-[56px] rounded-[10px]" />
        </div>
        <Skeleton className="w-[152px] h-[56px] rounded-[10px]" />
      </div>

      <div className="flex flex-col gap-y-[24px]">
        <Skeleton className="h-[276px] w-full rounded-[10px]" />
        <Skeleton className="h-[276px] w-full rounded-[10px]" />
      </div>
    </div>
  );
};

export { EditServicesModalSkeleton };
