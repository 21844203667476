import { regExp } from 'shared/constants';
import * as Yup from 'yup';

const inviteUserValidationSchema = Yup.object().shape({
  givenName: Yup.string().required('First name is required'),
  surname: Yup.string().required('Second name is required'),
  email: Yup.string()
    .matches(regExp.email, 'Enter a valid email')
    .required('Email is required'),
});

export { inviteUserValidationSchema };
