import {
  useState,
  FC,
  ComponentPropsWithRef,
  ChangeEvent,
  useCallback,
  FocusEvent,
  ReactNode,
} from 'react';

import debounce from 'lodash.debounce';

import { cn } from 'shared/lib';
import { Input } from 'shared/ui';
import { UnknownDataWithRequiredId } from 'shared/ts';
import { ReactComponent as SearchIcon } from 'shared/icons/search_icon.svg';

import { SearchBarOptions } from './ui';

interface SearchBarProps
  extends Omit<ComponentPropsWithRef<typeof Input>, 'value' | 'onChange'> {
  data: UnknownDataWithRequiredId[] | undefined;
  isLoading?: boolean;
  renderOption?: (item: any) => ReactNode;
  query: string;
  onQueryChange: (query: string) => void;
}

const SearchBar: FC<SearchBarProps> = ({
  data,
  query,
  onBlur,
  onFocus,
  className,
  isLoading,
  onQueryChange,
  renderOption,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(query);

  const [isFocused, setIsFocused] = useState(false);

  const isSearchDropdownVisible =
    inputValue && inputValue === query && isFocused && !isLoading;

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if (query === value) {
        return;
      }

      onQueryChange(value);
    }, 300),
    [query],
  );

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);

    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    debouncedSearch(event.target.value);
  };

  return (
    <div className="relative">
      <Input
        icon={<SearchIcon />}
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleSearchQueryChange}
        autoComplete="off"
        iconClassName={cn('shrink-0 fill-black-30 group-hover:fill-black-30', {
          'fill-main-keesing group-hover:fill-main-keesing':
            isFocused || inputValue,
        })}
        className={cn('w-[344px] p-[12px] h-[48px]', className)}
        placeholder="Search"
        {...props}
      />
      {isSearchDropdownVisible && renderOption && (
        <SearchBarOptions data={data} renderOption={renderOption} />
      )}
    </div>
  );
};

export { SearchBar };
