import { ReactNode, FC } from 'react';
import { CalendarContainer } from 'react-datepicker';

import { cn } from 'shared/lib';

interface CustomDatePickerContainerProps {
  className?: string;
  isOpen: boolean;
  children?: ReactNode;
  isDoublePicker: boolean;
  selectsRange: boolean;
}

const CustomDatePickerContainer: FC<CustomDatePickerContainerProps> = ({
  className,
  isOpen,
  children,
  isDoublePicker,
  selectsRange,
}) => {
  return (
    <div
      className={cn(
        'relative min-w-[368px] p-[24px] bg-white shadow-activeElement rounded-[10px]',
        {
          'datePickerContainer-separator min-w-[712px] h-[456px]':
            isDoublePicker,
          'h-[548px]': isDoublePicker && selectsRange,
        },
      )}
    >
      {isOpen && (
        <CalendarContainer className={cn(className, '!border-none')}>
          {children}
        </CalendarContainer>
      )}
    </div>
  );
};

export { CustomDatePickerContainer };
