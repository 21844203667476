import { FC } from 'react';

import { AccountInfo } from '@azure/msal-common';

import { Avatar } from 'shared/ui';
import { cn, getUserRole } from 'shared/lib';

import { camelCaseToWords, getInitials } from '../lib';

interface SidebarUserInfoProps {
  user: AccountInfo;
  isCollapsed: boolean;
}

const SidebarUserInfo: FC<SidebarUserInfoProps> = ({ user, isCollapsed }) => {
  const { idTokenClaims } = user ?? {};

  const userRole = getUserRole(user) ?? '';

  const { family_name, given_name } = idTokenClaims ?? {};

  const fullName = `${given_name} ${family_name}`;

  const userRoleCapitalized = camelCaseToWords(userRole);

  const initials = getInitials(fullName);

  return (
    <div
      className={cn(
        'w-[216px] flex px-[16px] py-[8px] items-center gap-x-[8px] transition-all duration-300',
        {
          'w-[56px] px-[6px]': isCollapsed,
        },
      )}
    >
      <div className="w-[44px] h-[44px] flex-shrink-0">
        <Avatar>{initials}</Avatar>
      </div>
      {!isCollapsed && (
        <div className="flex flex-col gap-x-[4px] max-w-[80%]">
          <p className="font-inter text-12 leading-[18px] text-grey-50 truncate">
            {userRoleCapitalized}
          </p>
          <p className="text-white text-14 leading-[20px] truncate">
            {fullName}
          </p>
        </div>
      )}
    </div>
  );
};

export { SidebarUserInfo };
