import { FC } from 'react';

import { Provider as ReduxProvider } from 'react-redux';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { Toast } from 'shared/ui';
import { Scripts } from 'scripts';
import { AppRouter } from './routes/AppRouter';
import { store } from './store';

import './styles/common/global.css';
import './styles/fonts/inter.css';
import './styles/fonts/poppins.css';

interface AppProps {
  msalInstance: PublicClientApplication;
}

const App: FC<AppProps> = ({ msalInstance }) => {
  return (
    <>
      <Scripts />
      <ReduxProvider store={store}>
        <MsalProvider instance={msalInstance}>
          <AppRouter />
          <Toast />
        </MsalProvider>
      </ReduxProvider>
    </>
  );
};

export { App };
