import { ComponentProps, ReactNode } from 'react';

import { Button } from 'shared/ui';

type ConfirmModalOptionsType = {
  isOpen: boolean;
  title: string;
  subtitle: string;
  confirmButtonText: string;
  closeButtonText?: string;
  confirmIcon?: ReactNode;
  onClose?: () => void;
  onConfirm?: () => void;
  confirmButtonProps?: Omit<ComponentProps<typeof Button>, 'children'>;
};

const initialConfirmModalOptions: ConfirmModalOptionsType = {
  isOpen: false,
  title: '',
  onClose: undefined,
  onConfirm: undefined,
  confirmButtonText: '',
  subtitle: '',
};

export { initialConfirmModalOptions };
export type { ConfirmModalOptionsType };
