import * as Yup from 'yup';

const IdpFormSchema = Yup.object().shape({
  documentMetadataUrl: Yup.string().required(
    'Open ID Connect metadata URL is required',
  ),
  clientId: Yup.string().required('Client ID Connect metadata URL is required'),
  clientSecret: Yup.string().required('Client Secret is required'),
  secretExpireAt: Yup.date().required('Expiration date is required'),
});

export { IdpFormSchema };
