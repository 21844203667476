import { useState, useEffect, FC, useRef, ReactNode } from 'react';

import { cn } from 'shared/lib';
import { Icon } from 'shared/ui';
import { ReactComponent as ChevronDownIcon } from 'shared/icons/chevron_down_icon.svg';
import { useClickOutside } from 'shared/hooks';

import { SelectMenu } from './ui';

import { TOption } from './select.types';

interface SelectProps {
  name?: string;
  label?: string;
  value?: string | number;
  isOpen?: boolean;
  options: TOption[];
  placeholder?: string;
  onChange: (value: string | number) => void;
  error?: boolean;
  wrapperClassName?: string;
  fullWidth?: boolean;
  helperText?: ReactNode;
  onBlur?: () => void;
  disabled?: boolean;
}

const Select: FC<SelectProps> = ({
  name,
  label,
  value,
  options,
  placeholder,
  wrapperClassName,
  fullWidth,
  isOpen: isOpenProp = false,
  onChange: onChangeProps,
  error,
  helperText,
  onBlur,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenProp);
  const [selectedOptionValue, setSelectedOptionValue] = useState<
    TOption['value'] | undefined
  >(value);
  const [highlightedOptionIndex, setHighlightedOptionIndex] =
    useState<number>(-1);
  const selectRef = useRef<HTMLButtonElement | null>(null);
  const highlightedOptionRef = useRef<HTMLLIElement | null>(null);

  const selectedOption = options.find(
    option => option.value === selectedOptionValue,
  );

  const selectTitle =
    selectedOption?.label ?? placeholder ?? 'No option selected';

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setHighlightedOptionIndex(-1);
      selectRef.current?.focus();
    }

    if (isOpen) {
      selectRef.current?.focus();
    }
  };

  const handleOptionChange = (option: TOption) => {
    setSelectedOptionValue(option.value);
    toggleDropdown();
    onChangeProps(option.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        setHighlightedOptionIndex(prevIndex =>
          prevIndex > 0 ? prevIndex - 1 : options.length - 1,
        );
        break;
      case 'ArrowDown':
        event.preventDefault();
        setHighlightedOptionIndex(prevIndex =>
          prevIndex < options.length - 1 ? prevIndex + 1 : 0,
        );
        break;
      case 'Enter':
        if (highlightedOptionIndex !== -1) {
          handleOptionChange(options[highlightedOptionIndex]);
        }
        toggleDropdown();
        break;
      case 'Escape':
        setIsOpen(false);
        setHighlightedOptionIndex(-1);
        selectRef.current?.focus();
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    if (onBlur && !isOpen) {
      onBlur();
    }
  };

  useEffect(() => {
    if (highlightedOptionRef.current) {
      highlightedOptionRef.current.focus();
    }
  }, [highlightedOptionIndex]);

  useEffect(() => {
    if (value !== selectedOptionValue) {
      setSelectedOptionValue(value);
    }
  }, [value]);

  useClickOutside(selectRef, () => setIsOpen(false));

  return (
    <div
      className={cn('w-fit h-fit', { 'w-full': fullWidth }, wrapperClassName)}
    >
      <button
        type="button"
        name={name}
        className="block relative text-left group focus:outline-none w-full"
        ref={selectRef}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        disabled={disabled}
      >
        {label && (
          <label className="w-fit block mb-[8px] text-14 text-black-30">
            {label}
          </label>
        )}
        <div
          onClick={() => !disabled && toggleDropdown()}
          className={cn(
            'flex max-h-[56px] cursor-pointer items-center justify-between rounded-[10px]',
            'bg-main-99 border-[1px] border-main-80 p-[8px] pl-[16px] min-h-[56px]',
            {
              'group-focus:border-main-80 shadow-medium': isOpen,
              'group-focus:border-error-80 border-error-80 bg-error-99': error,
              'group-focus:border-gray-200 cursor-default border-gray-200 bg-stone-200 bg-opacity-30 text-zinc-400':
                disabled,
            },
          )}
        >
          <p
            className={cn('select-none text-16 text-black-40', {
              'text-[#9CA3AF]': !selectedOptionValue,
            })}
          >
            {selectTitle}
          </p>
          <div className="flex justify-center items-center rounded-[10px] bg-white p-[8px]">
            <Icon
              icon={<ChevronDownIcon />}
              className={cn('fill-main-50 group-focus:fill-main', {
                'fill-main rotate-180': isOpen,
                'fill-zinc-400 ': disabled,
              })}
              size="lg"
            />
          </div>
        </div>
        <input
          type="hidden"
          name={name}
          value={selectedOption?.value || ''}
          readOnly
        />

        {isOpen && (
          <SelectMenu
            onChange={handleOptionChange}
            highlightedOptionIndex={highlightedOptionIndex}
            options={options}
            selectedOption={selectedOption}
            error={error}
          />
        )}
      </button>
      {helperText && (
        <span
          className={cn('block text-12 mt-1 whitespace-pre-wrap duration-300', {
            'text-error': error,
            'text-zinc-400': disabled,
          })}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export { Select };
