import { FC } from 'react';

import { AccountInfo } from '@azure/msal-common';

import { getUserRole } from 'shared/lib';
import { routesMap } from 'shared/constants';

import { SidebarLink } from './SidebarLink';

interface SidebarMenuProps {
  user: AccountInfo;
  isCollapsed: boolean;
  linkClasses: string;
}

const SidebarMenu: FC<SidebarMenuProps> = ({
  linkClasses,
  isCollapsed,
  user,
}) => {
  const userRole = getUserRole(user);

  const routesMapValues = Object.values(routesMap);

  const filteredRoutes = routesMapValues.filter(route =>
    route.userRoles.includes(userRole),
  );

  return (
    <nav className="mt-[40px]">
      <ul className="flex flex-col gap-y-[8px]">
        {filteredRoutes.map(route => (
          <li key={route.path}>
            <SidebarLink
              to={route.path}
              icon={route.icon}
              isCollapsed={isCollapsed}
              className={linkClasses}
            >
              {route.title}
            </SidebarLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export { SidebarMenu };
