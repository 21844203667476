import { useState, ReactNode, FC, useRef, FocusEvent } from 'react';

import Datepicker, { registerLocale } from 'react-datepicker';
import { addMonths, lastDayOfMonth, startOfMonth, format } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';

import { useMount } from 'shared/hooks';
import { cn } from 'shared/lib';

import { currentDate, maxDate, years } from './lib';
import {
  CustomDatePickerContainer,
  CustomDatePickerDay,
  CustomDatePickerHeader,
  CustomDatePickerInput,
} from './ui';
import { DateOnChangeType, DateRangeType } from './customDatePicker.types';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

registerLocale('enGB', enGB);

const ICON_SIZE_AND_GAP = 65;

interface CustomDatePickerProps {
  label?: ReactNode;
  name?: string;
  placeholder?: string;
  onChange?: (value: DateRangeType) => void;
  onBlur?: (event: FocusEvent) => void;
  isDoublePicker?: boolean;
  selectsRange?: boolean;
  value?: string;
  dateFormat?: string;
  error?: boolean;
  helperText?: ReactNode;
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  label,
  name,
  placeholder,
  value,
  error,
  onChange: onChangeProps,
  onBlur: onBlurProps,
  dateFormat = 'PP',
  isDoublePicker = true,
  selectsRange = true,
  helperText,
}) => {
  // Ref for the date picker, using <any> type due to a lack of proper types in react-datepicker
  const datePickerRef = useRef<any>(null);

  const [dateRange, setDateRange] = useState<DateRangeType>([null, null]);
  const [startDate, endDate] = dateRange;
  const [open, setOpen] = useState(false);
  const mounted = useMount(open);

  // State for the first opened month in datePicker, used for `day` styling calculations when the selectsRange prop is true
  const [firstOpenedMonth, setFirstOpenedMonth] = useState(
    startDate ?? new Date(),
  );

  const firstDayInTheNextMonth = startOfMonth(addMonths(firstOpenedMonth, 1));
  const lastDayInTheCurrentMonth = lastDayOfMonth(firstOpenedMonth);

  const handleMonthChange = (date: Date) => {
    if (!isDoublePicker) {
      return null;
    }

    const formattedStartDate = startDate && format(startDate, 'MM.yyyy');
    const formattedEndDate = endDate && format(endDate, 'MM.yyyy');

    const isInitialMonthSelectedIn =
      datePickerRef.current?.state.monthSelectedIn === 0;

    const isSelectedRangeInSameMonth = formattedStartDate === formattedEndDate;

    if ((endDate && !isSelectedRangeInSameMonth) || !isInitialMonthSelectedIn) {
      return setFirstOpenedMonth(addMonths(date, -1));
    }

    return setFirstOpenedMonth(date);
  };

  const handleCalendarOpen = () => {
    if (datePickerRef.current?.state?.monthSelectedIn) {
      datePickerRef.current.state.monthSelectedIn = 0;
    }

    if (startDate && isDoublePicker) {
      return setFirstOpenedMonth(startDate);
    }

    return null;
  };

  const handleYearChange = (date: Date) => {
    if (!isDoublePicker) {
      return;
    }

    setFirstOpenedMonth(addMonths(date, -1));
  };

  const handleInputClicked = () => {
    // By default, react-datepicker only opens the calendar when input is clicked. In our case, we also need to handle closing it.
    // On the first click, react-datepicker adds the outsideClickIgnoreClass. However, on the second click, it doesn't clear this class.
    // To solve this problem, we manually toggle the state to ensure proper handling.
    datePickerRef.current?.setOpen(!open);

    setOpen(!open);
  };

  const changeDate = (date: DateRangeType) => {
    setDateRange(date);

    if (!selectsRange) {
      setOpen(false);
    }

    if (onChangeProps) {
      onChangeProps(date);
    }

    if (!isDoublePicker) {
      return null;
    }

    return null;
  };

  const handleDateChange = (date: DateOnChangeType) => {
    if (Array.isArray(date)) {
      return changeDate(date);
    }

    return changeDate([date, null]);
  };

  const handleOutsideClick = () => {
    setOpen(false);
  };

  return (
    <div className="relative" onMouseDown={event => event.stopPropagation()}>
      {label && (
        <span className="block w-fit mb-2 text-14 text-black-30 cursor-default">
          {label}
        </span>
      )}
      <Datepicker
        open
        ref={datePickerRef}
        wrapperClassName="w-full relative"
        name={name}
        selected={startDate}
        selectsRange={selectsRange}
        value={value}
        onInputClick={handleInputClicked}
        startDate={startDate}
        onBlur={onBlurProps}
        onMonthChange={handleMonthChange}
        endDate={endDate}
        onCalendarOpen={handleCalendarOpen}
        onYearChange={handleYearChange}
        onChange={handleDateChange}
        customInput={
          <CustomDatePickerInput
            isOpen={open}
            error={error}
            onDateChange={handleDateChange}
          />
        }
        calendarContainer={params => (
          <CustomDatePickerContainer
            isOpen={mounted}
            selectsRange={selectsRange}
            isDoublePicker={isDoublePicker}
            {...params}
          />
        )}
        onClickOutside={handleOutsideClick}
        minDate={currentDate}
        maxDate={maxDate}
        popperClassName={cn(
          'absolute pointer-events-auto invisible !top-[6px] h-fit !z-[99999] opacity-[0] duration-300',
          {
            'visible opacity-100 !top-0': open,
          },
        )}
        popperPlacement="top"
        portalId="react-datepicker"
        popperModifiers={[
          { name: 'flip', enabled: false },
          {
            name: 'offset',
            options: {
              offset: values => {
                const distance = isDoublePicker ? 130 : 180;
                const skidding =
                  values.reference.width / 2 -
                  values.popper.width / 2 -
                  ICON_SIZE_AND_GAP;

                return [skidding, distance];
              },
            },
          },
        ]}
        monthsShown={isDoublePicker ? 2 : 1}
        locale={enGB}
        dateFormat={dateFormat}
        formatWeekDay={nameOfDay => nameOfDay.slice(0, 1)}
        placeholderText={placeholder}
        renderDayContents={(dayOfMonth, date) => (
          <CustomDatePickerDay
            dayOfMonth={dayOfMonth}
            date={date}
            isDoublePicker={isDoublePicker}
            firstDayInTheNextMonth={firstDayInTheNextMonth}
            lastDayInTheCurrentMonth={lastDayInTheCurrentMonth}
          />
        )}
        renderCustomHeader={params => (
          <CustomDatePickerHeader
            isDoublePicker={isDoublePicker}
            selectsRange={selectsRange}
            startDate={startDate}
            endDate={endDate}
            yearDropdownOptions={years}
            {...params}
          />
        )}
      />
      {helperText && (
        <span
          className={cn(
            'block text-12 text-black-50 mt-[4px] whitespace-pre-wrap',
            {
              'text-error': error,
            },
          )}
        >
          {helperText}
        </span>
      )}
    </div>
  );
};

export { CustomDatePicker };
