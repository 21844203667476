import { Skeleton } from 'shared/ui';

const UsersTableControlsSkeleton = () => {
  return (
    <div className="flex gap-x-[12px]">
      <Skeleton className="w-[344px] h-[48px] rounded-[10px]" />
      <Skeleton className="w-[95px] h-[48px] rounded-[10px]" />
    </div>
  );
};

export { UsersTableControlsSkeleton };
