import { FC } from 'react';

import { AccessReqActionParams, AccessRequestType } from 'shared/ts';

import { Button } from 'shared/ui';

interface RequestsTableActionsCellProps {
  request: AccessRequestType;
  onAccessReqApprove: (
    accessReqActionParams: Omit<AccessReqActionParams, 'subscriptionId'>,
    request: AccessRequestType,
  ) => void;
  onAccessReqDeny: (
    accessReqActionParams: Omit<AccessReqActionParams, 'subscriptionId'>,
    request: AccessRequestType,
  ) => void;
}

const RequestsTableActionsCell: FC<RequestsTableActionsCellProps> = ({
  request,
  onAccessReqDeny,
  onAccessReqApprove,
}) => {
  const handleApproveAccessReq = () => {
    onAccessReqApprove(
      {
        userId: request.userId,
      },
      request,
    );
  };

  const handleDenyAccessReq = () => {
    onAccessReqDeny(
      {
        userId: request.userId,
      },
      request,
    );
  };

  return (
    <div className="flex gap-x-2">
      <Button
        color="secondary"
        className="font-inter text-14 leading-[24px] font-semibold py-2"
        onClick={handleDenyAccessReq}
      >
        Reject
      </Button>
      <Button
        color="primary"
        className="font-inter text-14 font-semibold py-2"
        onClick={handleApproveAccessReq}
      >
        Approve
      </Button>
    </div>
  );
};

export { RequestsTableActionsCell };
