import { FC, ReactNode } from 'react';

import { cn } from 'shared/lib';

import { Header } from './ui';

interface PageLayoutProps {
  isHeaderLoading?: boolean;
  headerActions?: ReactNode;
  isChildRoute?: boolean;
  className?: string;
  children: ReactNode;
  isError?: boolean;
}

const PageLayout: FC<PageLayoutProps> = ({
  isHeaderLoading,
  headerActions,
  isChildRoute,
  className,
  children,
  isError,
}) => {
  return (
    <div className="flex flex-col w-full h-full">
      <Header
        isError={isError}
        headerActions={headerActions}
        isChildRoute={isChildRoute}
        isHeaderLoading={isHeaderLoading}
      />

      <main
        className={cn('w-full pt-[40px] h-full pb-[32px] px-[24px]', className)}
      >
        {children}
      </main>
    </div>
  );
};

export { PageLayout };
