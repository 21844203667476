import { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { AuthError } from '@azure/msal-common';
import { Navigate, useLocation } from 'react-router';
import { PublicLayout } from 'widgets';
import { Button } from 'shared/ui';
import { ReactComponent as MailIcon } from 'shared/icons/mail_icon.svg';
import { formatErrorMessage } from './lib';

interface ErrorState {
  state: {
    error?: AuthError;
    customErrorMessage?: string;
  };
}

const ErrorPage = () => {
  const { state } = useLocation() as ErrorState;

  // Reset router state on page reload to prompt user login again
  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  if (!state?.error && !state?.customErrorMessage) {
    return <Navigate to="/" replace />;
  }

  const formattedErrorMessage = formatErrorMessage(
    state.error?.errorMessage ?? '',
  );

  const authenticated = useIsAuthenticated();

  return (
    <PublicLayout>
      <div className="flex items-center flex-col min-w-[520px] bg-white p-6 rounded-[20px]">
        <p className="font-inter text-black-30 text-16">
          {state?.customErrorMessage || formattedErrorMessage}
        </p>

        <a
          className="block w-full mt-8"
          href={process.env.REACT_APP_CONTACT_URL}
        >
          <Button
            color="primary"
            className="font-normal"
            endIcon={<MailIcon />}
            size="lg"
            fullWidth
            title="Contact Keesing"
          >
            Contact Keesing
          </Button>
        </a>
        {authenticated ? (
          <a className="block w-full mt-3" href="/signout">
            <Button
              color="secondary"
              className="font-normal"
              size="lg"
              fullWidth
              title="Sign out"
            >
              Sign out
            </Button>
          </a>
        ) : (
          <a className="block w-full mt-3" href="/">
            <Button
              color="secondary"
              className="font-normal"
              size="lg"
              fullWidth
              title="Sign in"
            >
              Sign in
            </Button>
          </a>
        )}
      </div>
    </PublicLayout>
  );
};

export { ErrorPage };
