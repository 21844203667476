import { FC, ReactNode } from 'react';

import { Sidebar } from 'features';

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="flex h-full w-full">
      <Sidebar />
      {children}
    </div>
  );
};

export { MainLayout };
